import axios, { CancelToken } from 'axios'
import {
  findingModalAttributes,
  threatIntelLanguage,
} from '../../constants/Report/ActionPlan'
import { isIpAddress } from 'utils/TextHelpers'
import {
  criteriumToExcludeHostInfo,
  criteriumToExcludeIssueManagement,
  criteriumToExcludeEventLog,
} from '../../constants/Report/FindingSidebar'

export const FETCHING_LANGUAGE_INFO = 'FETCHING_LANGUAGE_INFO'
export const TOGGLE_SHOW_FINDING_SIDEBAR = 'TOGGLE_SHOW_FINDING_SIDEBAR'
export const FETCH_QUERY_LANGUAGE_SUCCESS = 'FETCH_QUERY_LANGUAGE_SUCCESS'
export const FETCH_LANGUAGE_SUCCESS = 'FETCH_LANGUAGE_SUCCESS'
export const FETCH_SOFTWARE_PATCHING_DATA_SUCCESS =
  'FETCH_SOFTWARE_PATCHING_DATA_SUCCESS'
export const FETCH_THREAT_INTELL_ISSUES_SUCCESS =
  'FETCH_THREAT_INTELL_ISSUES_SUCCESS'
export const FETCHING_THREAT_INTELL_ISSUES = 'FETCHING_THREAT_INTELL_ISSUES'
export const SHOW_FINDINGS_SIDEBAR = 'SHOW_FINDINGS_SIDEBAR'
export const FETCH_HOST_PROFILE_INFO_SUCCESS = 'FETCH_HOST_PROFILE_INFO_SUCCESS'
export const FETCHING_HOST_PROFILE = 'FETCHING_HOST_PROFILE'
export const FETCH_FINDINGS_ERROR = 'FETCH_FINDINGS_ERROR'
// export const FETCHING_FINDINGS_BY_HOST = 'FETCHING_FINDINGS_BY_HOST'
// export const FETCH_FINDINGS_BY_HOST_SUCCESS = 'FETCH_FINDINGS_BY_HOST_SUCCESS'
// export const FETCH_FINDINGS_BY_HOST_FAILURE = 'FETCH_FINDINGS_BY_HOST_FAILURE'
export const RESET_SIDEBAR_FINDING_DATA = 'RESET_SIDEBAR_FINDING_DATA'
export const FETCH_HOST_INFO_SUCCESS = 'FETCH_HOST_INFO_SUCCESS'
export const FETCH_HOST_INFO_FAILURE = 'FETCH_HOST_INFO_FAILURE'
export const FETCHING_HOST_INFO = 'FETCHING_HOST_INFO'
export const FETCHING_SOFTWARE_PATCHING_DATA = 'FETCHING_SOFTWARE_PATCHING_DATA'
export const ADD_FINDING_TO_ACTION_PLAN_SUCCESS = 'ADD_FINDING_TO_ACTION_PLAN'
export const FETCH_ISSUE_MGMT_OPTIONS_SUCCESS =
  'FETCH_ISSUE_MGMT_OPTIONS_SUCCESS'
export const UPDATE_SIDEBAR_CURRENT_FINDING = 'UPDATE_SIDEBAR_CURRENT_FINDING'
export const FETCHING_EVENT_LOG = 'FETCHING_EVENT_LOG'
export const FETCH_EVENT_LOG_SUCCESS = 'FETCH_EVENT_LOG_SUCCESS'
export const FETCH_EVENT_LOG_ERROR = 'FETCH_EVENT_LOG_ERROR'
export const FETCHING_FETCH_FINDING_NEW_INFO_CALL =
  'FETCHING_FETCH_FINDING_NEW_INFO_CALL'
export const FETCH_FINDING_NEW_INFO_CALL_SUCCESS =
  'FETCH_FINDING_NEW_INFO_CALL_SUCCESS'
export const FETCH_FINDING_NEW_INFO_CALL_ERROR =
  'FETCH_FINDING_NEW_INFO_CALL_ERROR'
export const FETCH_HOSTS_ERROR = 'FETCH_HOSTS_ERROR'

// Constants
let cancel = () => false
let cancelFindingsByHost = () => false
let cancelSoftwarePatchingData = () => false
let cancelThreatIntelIssues = () => false
let cancelHostProfileInfo = () => false
let cancelQueryWithLanguage = () => false
let cancelQueryWithoutLanguage = () => false

export function fetchingEventLog() {
  return {
    type: FETCHING_EVENT_LOG,
  }
}
export function fetchEventLogSuccess(response) {
  return {
    type: FETCH_EVENT_LOG_SUCCESS,
    data: response.data.data,
  }
}
export function fetchEventLogError() {
  return {
    type: FETCH_EVENT_LOG_ERROR,
  }
}

export function fetchingFindingNewInfoCall() {
  return {
    type: FETCHING_FETCH_FINDING_NEW_INFO_CALL,
  }
}
export function fetchFindingNewInfoCallSuccess(response) {
  return {
    type: FETCH_FINDING_NEW_INFO_CALL_SUCCESS,
    data: response.data.data,
  }
}
export function fetchFindingNewInfoCallError() {
  return {
    type: FETCH_FINDING_NEW_INFO_CALL_ERROR,
  }
}

// Action Creators
export function fetchFindingLanguageInfo(
  query = '',
  securityCriteria,
  domain,
  language = 'english',
) {
  return async (dispatch) => {
    await dispatch(fetchingLanguageInfo())
    try {
      const showVulnerabilityInfo =
        findingModalAttributes[securityCriteria].show_vulnerability_info
      if (domain === 'threat_intell') {
        dispatch(
          fetchLanguageSuccess(
            threatIntelLanguage,
            showVulnerabilityInfo,
            query,
            securityCriteria,
          ),
        )
      } else {
        const urlCriteria =
          securityCriteria === 'domain_hijacking_protection'
            ? 'dns_hijacking_protection'
            : securityCriteria
        if (showVulnerabilityInfo) {
          await cancelQueryWithLanguage()
          const response = await axios.get(
            `/v0/cpe/query_with_language?q=${query}&security_criteria=${urlCriteria}&language=${language}`,
            {
              cancelToken: new CancelToken(function executor(c) {
                // An executor function receives a cancel function as a parameter
                cancelQueryWithLanguage = c
              }),
            },
          )
          dispatch(
            fetchLanguageSuccess(
              response.data,
              showVulnerabilityInfo,
              query,
              securityCriteria,
            ),
          )
        } else {
          await cancelQueryWithoutLanguage()
          const urlParam =
            urlCriteria === 'web_threat_intel_alert_external'
              ? 'threat_intel_alert_external'
              : urlCriteria
          let response
          const isTLS = query.split(' ')[0] === 'TLS'
          if (isTLS) {
            response = await axios.get(
              `/v0/cpe/raw_language?security_criteria=web_encryption_protocol_1.2&language=${language}`,
              {
                cancelToken: new CancelToken(function executor(c) {
                  // An executor function receives a cancel function as a parameter
                  cancelQueryWithoutLanguage = c
                }),
              },
            )
            // The response key issue_long_intro contains a lot of newline markup and URL's that are easier to handle on the JSX side
            response.data.issue_long_intro =
              'The system does not support TLS 1.2. Beginning early 2020, major browser manufacturers are mandating that encrypted HTTP browser communications use the TLS 1.2 protocol and are ending support for TLS 1.0 and 1.1. As such, browsers will no longer communicate with web servers that use HTTPS and do not use TLS 1.2 protocol. Support for protocol versions older than TLS 1.2 is being ceased due to pervasive security flaws. References for each of the major browser manufacturers are provided below.'
          } else {
            response = await axios.get(
              `/v0/cpe/raw_language?security_criteria=${urlParam}&language=${language}`,
              {
                cancelToken: new CancelToken(function executor(c) {
                  // An executor function receives a cancel function as a parameter
                  cancelQueryWithoutLanguage = c
                }),
              },
            )
          }

          dispatch(
            fetchLanguageSuccess(
              response.data,
              showVulnerabilityInfo,
              query,
              securityCriteria,
            ),
          )
        }
      }
    } catch (error) {
      dispatch(fetchError())
    }
  }
}

// ***
// This action creator and accompanying endpoints is used by the MyCyberRisk application. If you intend to change the functionality please let the MyCyberRisk team know -- ideally before any code is committed!
// ***

// ***
// This is in serious need of refactoring
// We could make fewer api requests, which would move this boolean stuff to server.
// And/or we should move these requests to the individual components (probably need to be created) that are using the data.
// ***
export function fetchFindingSidebarInfo(finding, analysisID, toe_id, language) {
  return async (dispatch, getState) => {
    try {
      const hostOnly = getState().Findings.sideBarShowHostOnly
      const fetchHost = getState().Findings.fetchHost
      const showHostTab = !criteriumToExcludeHostInfo.includes(
        finding.security_criteria,
      )
      const shouldFetchSoftwarePatchingData =
        finding.security_criteria === 'patching_app_server' ||
        finding.security_criteria === 'patching_vuln_open_ssl' ||
        finding.security_criteria === 'patching_openssl' ||
        finding.security_criteria === 'patching_web_cms' ||
        finding.security_criteria === 'patching_web_server'
      const shouldFetchThreatIntellIssues =
        finding.security_criteria === 'threatintel_cc_server' ||
        finding.security_criteria === 'threatintel_botnet_host' ||
        finding.security_criteria === 'threatintel_hostile_host_hacking' ||
        finding.security_criteria === 'threatintel_hostile_host_scanning' ||
        finding.security_criteria === 'threatintel_phishing_site' ||
        finding.security_criteria === 'threatintel_other' ||
        finding.security_criteria === 'threatintel_spamming_host'
      const dontFetchEventLog = criteriumToExcludeEventLog.includes(
        finding.security_criteria,
      )
      const dontFetchFindingAgain = criteriumToExcludeIssueManagement.includes(
        finding.security_criteria,
      )

      if (!dontFetchEventLog && !hostOnly) {
        await dispatch(fetchEventLog(finding.finding_id))
      }

      if (shouldFetchSoftwarePatchingData) {
        dispatch(fetchSoftwarePatchingData(analysisID))
      }

      if (shouldFetchThreatIntellIssues) {
        dispatch(fetchThreatIntellIssues(finding.ip_address, finding.host_name))
      }

      if (!hostOnly) {
        dispatch(
          fetchFindingLanguageInfo(
            finding.finding_detail,
            finding.security_criteria,
            finding.security_domain,
            language,
          ),
        )
      }

      // if (finding.host_name && showHostTab) {
      //   dispatch(fetchFindingsByHost(finding.host_name, toe_id))
      // }

      if (
        finding.host_name &&
        !isIpAddress(finding.host_name) &&
        showHostTab &&
        finding.security_criteria !== 'unsafe_network_services'
      ) {
        dispatch(fetchHostProfileInfo(finding.host_name, analysisID))
      }

      if (hostOnly || fetchHost) {
        dispatch(fetchHostInfo(finding.host_name, analysisID, hostOnly))
      }

      if (!dontFetchFindingAgain && !hostOnly) {
        await dispatch(fetchFindingNewInfoCall(finding.finding_id))
      }

      // dispatch(fetchIssueMgmtOptions())
    } catch (error) {
      return false
    }
  }
}

export function fetchingLanguageInfo() {
  return { type: FETCHING_LANGUAGE_INFO }
}

export function fetchingSoftwarePatchingData() {
  return { type: FETCHING_SOFTWARE_PATCHING_DATA }
}

export function toggleShowFindingDetailsModal(finding = null) {
  return {
    type: TOGGLE_SHOW_FINDING_SIDEBAR,
    finding: finding,
  }
}

function showFindingSidebar(
  finding = null,
  hostOnly,
  selectedTopTab,
  fetchHost,
  fromActionPlan,
  selectedIssueTab,
) {
  return {
    type: SHOW_FINDINGS_SIDEBAR,
    finding,
    hostOnly,
    selectedTopTab,
    fetchHost,
    fromActionPlan,
    selectedIssueTab,
  }
}

export function showFindingSidebarAsync(
  finding = null,
  hostOnly = false,
  selectedTopTab = 'host',
  fetchHost = false,
  fromActionPlan = false,
  selectedIssueTab = 'issue-detail',
) {
  return async (dispatch) => {
    dispatch(
      showFindingSidebar(
        finding,
        hostOnly,
        selectedTopTab,
        fetchHost,
        fromActionPlan,
        selectedIssueTab,
      ),
    )
  }
}

function fetchLanguageSuccess(
  data,
  showVulnerabilityInfo,
  query,
  securityCriteria,
) {
  const type = showVulnerabilityInfo
    ? FETCH_QUERY_LANGUAGE_SUCCESS
    : FETCH_LANGUAGE_SUCCESS
  return {
    type: type,
    data: data,
    query: query,
    securityCriteria: securityCriteria,
  }
}

export function fetchThreatIntellIssues(ipAddress, hostName) {
  return async (dispatch) => {
    await cancelThreatIntelIssues()
    dispatch(fetchingThreatIntellIssues())
    try {
      const success = await axios.get(
        `/v0/cpe/query_threat_intel_by_ip_and_host_name?ip=${ipAddress}&host_name=${hostName}`,
        {
          cancelToken: new CancelToken(function executor(c) {
            // An executor function receives a cancel function as a parameter
            cancelThreatIntelIssues = c
          }),
        },
      )
      dispatch(
        fetchThreatIntellIssuesSuccess(success.data, ipAddress, hostName),
      )
    } catch (error) {
      if (error.message === undefined) {
        return false
      }
      dispatch(fetchError())
    }
  }
}

function fetchingThreatIntellIssues() {
  return {
    type: FETCHING_THREAT_INTELL_ISSUES,
  }
}

function fetchThreatIntellIssuesSuccess(response, ipAddress, hostName) {
  return {
    type: FETCH_THREAT_INTELL_ISSUES_SUCCESS,
    data: response,
    ipAddress: ipAddress,
    hostName: hostName,
  }
}

// TODO: Use for advisor
export function fetchSoftwarePatchingData(analysisId) {
  return async (dispatch) => {
    await cancelSoftwarePatchingData()
    dispatch(fetchingSoftwarePatchingData())
    try {
      const success = await axios.get(
        `/v0/analysis/security_profile/software_patching/${analysisId}`,
        {
          cancelToken: new CancelToken(function executor(c) {
            // An executor function receives a cancel function as a parameter
            cancelSoftwarePatchingData = c
          }),
        },
      )
      dispatch(fetchSoftwarePatchingDataSuccess(success))
    } catch (error) {
      if (error.message === undefined) {
        return false
      }
      dispatch(fetchError())
    }
  }
}
// TODO: Use for advisor
function fetchSoftwarePatchingDataSuccess(response) {
  return {
    type: FETCH_SOFTWARE_PATCHING_DATA_SUCCESS,
    data: response.data,
  }
}

export function fetchHostProfileInfo(query, analysis_id) {
  return async (dispatch) => {
    try {
      await cancelHostProfileInfo()
      dispatch(fetchingHostProfile())
      const success = await axios.get(
        `/v0/analysis/host_profiles/${query}/${analysis_id}`,
        {
          cancelToken: new CancelToken(function executor(c) {
            // An executor function receives a cancel function as a parameter
            cancelHostProfileInfo = c
          }),
        },
      )
      dispatch(fetchHostProfileInfoSuccess(success.data))
    } catch (error) {
      if (error.message === undefined) {
        return false
      }
      dispatch(fetchHostsError())
    }
  }
}

function fetchingHostProfile() {
  return {
    type: FETCHING_HOST_PROFILE,
  }
}

function fetchHostProfileInfoSuccess(response) {
  return {
    type: FETCH_HOST_PROFILE_INFO_SUCCESS,
    data: response,
  }
}

export function fetchError() {
  return { type: FETCH_FINDINGS_ERROR }
}

export function fetchHostsError() {
  return { type: FETCH_HOSTS_ERROR }
}

// findings by host
// export function fetchFindingsByHost(hostName, toe_id) {
//   return async (dispatch) => {
//     try {
//       await cancelFindingsByHost()
//       dispatch(fetchingFindingsByHost())
//       const success = await axios.get(
//         `/v0/action_plan/findings_by_host/${toe_id}/${hostName}`,
//         {
//           baseURL: process.env.REACT_APP_API_URL,
//           cancelToken: new CancelToken(function executor(c) {
//             // An executor function receives a cancel function as a parameter
//             cancelFindingsByHost = c
//           }),
//         },
//       )
//       dispatch(fetchFindingsByHostSuccess(success))
//     } catch (err) {
//       if (err.message === undefined) {
//         return false
//       }
//       dispatch(fetchFindingsByHostFailure())
//     }
//   }
// }

// issueMgmtOptions
export function fetchIssueMgmtOptions() {
  return async (dispatch) => {
    try {
      const success = await axios.get(
        '/v2/finding/compensating_control_false_positive_options',
      )
      dispatch(fetchIssueMgmtOptionsSuccess(success))
    } catch (err) {
      dispatch(fetchError())
    }
  }
}

export function fetchIssueMgmtOptionsSuccess(response) {
  return {
    type: FETCH_ISSUE_MGMT_OPTIONS_SUCCESS,
    response: response.data,
  }
}

// hostInfo
export function fetchHostInfo(hostName, analysisID, hostOnly) {
  return async (dispatch) => {
    try {
      if (hostName) {
        await cancel()
        dispatch(fetchingHostInfo())
        const success = await axios.get(
          `/v0/host_info/${hostName}/${analysisID}`,
          {
            cancelToken: new CancelToken(function executor(c) {
              // An executor function receives a cancel function as a parameter
              cancel = c
            }),
          },
        )
        const hostInfo = (success.data.hits.hits[0] || {})._source || {}
        if (Object.keys(hostInfo).length !== 0 && !hostOnly) {
          delete hostInfo.asset_value
        }
        dispatch(fetchHostInfoSuccess(success))
      }
    } catch (err) {
      if (err.message === undefined) {
        return false
      }
      dispatch(fetchHostInfoFailure())
    }
  }
}

export function fetchingHostInfo() {
  return { type: FETCHING_HOST_INFO }
}

export function fetchHostInfoSuccess(response) {
  return {
    type: FETCH_HOST_INFO_SUCCESS,
    response: (response.data.hits.hits[0] || {})._source || {},
  }
}

export function fetchHostInfoFailure() {
  return { type: FETCH_HOST_INFO_FAILURE }
}

export function updateSidebarCurrentFinding(finding, params) {
  return {
    type: UPDATE_SIDEBAR_CURRENT_FINDING,
    finding: finding,
    params: params,
  }
}

export function fetchEventLog(findingId) {
  return async (dispatch) => {
    try {
      dispatch(fetchingEventLog())
      const success = await axios.get(`/v2/findings/${findingId}/event_log`)
      dispatch(fetchEventLogSuccess(success))
    } catch (error) {
      console.log(error)
      dispatch(fetchEventLogError())
    }
  }
}

export function fetchFindingNewInfoCall(findingId) {
  return async (dispatch) => {
    try {
      dispatch(fetchingFindingNewInfoCall())
      const success = await axios.get(
        `/v2/findings/${findingId}?expression_attributes=is_in_action_plan,customer_due_date,customer_finding_status,is_shared`,
      )
      dispatch(fetchFindingNewInfoCallSuccess(success))
    } catch (error) {
      console.log(error)
      dispatch(fetchFindingNewInfoCallError())
    }
  }
}

// export function fetchingFindingsByHost() {
//   return { type: FETCHING_FINDINGS_BY_HOST }
// }

// export function fetchFindingsByHostSuccess(response) {
//   return {
//     type: FETCH_FINDINGS_BY_HOST_SUCCESS,
//     response: response.data,
//   }
// }

// export function fetchFindingsByHostFailure() {
//   return { type: FETCH_FINDINGS_BY_HOST_FAILURE }
// }

function resetSidebarFindings() {
  return { type: RESET_SIDEBAR_FINDING_DATA }
}

export function resetAndCloseSidebarFindings() {
  return async (dispatch) => {
    await cancel()
    await cancelFindingsByHost()
    await cancelSoftwarePatchingData()
    await cancelThreatIntelIssues()
    await cancelHostProfileInfo()
    await cancelQueryWithLanguage()
    await cancelQueryWithoutLanguage()
    // const body = document.getElementsByTagName('BODY')[0]
    // body.classList.remove('no-scroll')
    // body.style.cssText = 'overflow-y: auto'
    dispatch(resetSidebarFindings())
  }
}
