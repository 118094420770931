import React from 'react'
import PropTypes from 'prop-types'
import './MultiRatingGauge.scss'

const setDisplayValue = (value) =>
  value > 0 && value < 1 ? '1' : Math.round(value)

const PER_SEGMENT_OFFSET = 2
const STARTING_OFFSET = 24
const PERCENT_100 = 100

const MultiRatingGauge = ({ gradeA, gradeB, gradeC, gradeD, gradeF }) => {
  const sumOfGrades = gradeA + gradeB + gradeC + gradeD + gradeF
  if (sumOfGrades !== 100) {
    console.warn(
      'values should add up to 100.  The current sum is: ',
      sumOfGrades,
    )
  }
  const reducer = (accumulator, currentValue) =>
    currentValue !== 0 ? accumulator + 1 : accumulator
  const nonZeroRatingCount = [gradeA, gradeB, gradeC, gradeD, gradeF].reduce(
    reducer,
    0,
  )

  const availableSegmentSpace = PERCENT_100 - 2 * nonZeroRatingCount

  const gradeASegmentLength = (gradeA / PERCENT_100) * availableSegmentSpace
  const gradeBSegmentLength = (gradeB / PERCENT_100) * availableSegmentSpace
  const gradeCSegmentLength = (gradeC / PERCENT_100) * availableSegmentSpace
  const gradeDSegmentLength = (gradeD / PERCENT_100) * availableSegmentSpace
  const gradeFSegmentLength = (gradeF / PERCENT_100) * availableSegmentSpace

  const gradeASegmentOffset = gradeA === 0 ? 0 : PER_SEGMENT_OFFSET
  const gradeBSegmentOffset = gradeB === 0 ? 0 : PER_SEGMENT_OFFSET
  const gradeCSegmentOffset = gradeC === 0 ? 0 : PER_SEGMENT_OFFSET
  const gradeDSegmentOffset = gradeD === 0 ? 0 : PER_SEGMENT_OFFSET

  const gradeADashArray = `${gradeASegmentLength} ${
    PERCENT_100 - gradeASegmentLength
  }`
  const gradeADashOffset = STARTING_OFFSET

  const gradeBDashArray = `${gradeBSegmentLength} ${
    PERCENT_100 - gradeBSegmentLength
  }`
  const gradeBDashOffset =
    gradeADashOffset - gradeASegmentLength - gradeASegmentOffset

  const gradeCDashArray = `${gradeCSegmentLength} ${
    PERCENT_100 - gradeCSegmentLength
  }`
  const gradeCDashOffset =
    gradeBDashOffset - gradeBSegmentLength - gradeBSegmentOffset

  const gradeDDashArray = `${gradeDSegmentLength} ${
    PERCENT_100 - gradeDSegmentLength
  }`
  const gradeDDashOffset =
    gradeCDashOffset - gradeCSegmentLength - gradeCSegmentOffset

  const gradeFDashArray = `${gradeFSegmentLength} ${
    PERCENT_100 - gradeFSegmentLength
  }`
  const gradeFDashOffset =
    gradeDDashOffset - gradeDSegmentLength - gradeDSegmentOffset

  // New to svg pie charts? Check out this URL for the architectural source of this component
  // https://medium.com/@heyoka/scratch-made-svg-donut-pie-charts-in-html5-2c587e935d72

  return (
    <div className="v3-multi-rating-gauge">
      <svg width="100%" height="100%" viewBox="0 0 34 34">
        <circle
          fill="transparent"
          stroke="rgba(181, 205, 227, 0.25)"
          strokeWidth="1"
          className="base-transparent === 0 ? 0 : 1ring"
          cx="17"
          cy="17"
          r="15.91549430918954"
        />
        {/* A */}
        <circle
          name="gradeA"
          strokeDasharray={gradeADashArray}
          strokeDashoffset={gradeADashOffset}
          className="active-ring"
          strokeWidth={gradeA === 0 ? 0 : 1}
          cx="17"
          cy="17"
          r="15.91549430918954"
          fill="transparent"
          stroke="#27AE60"
        />
        {/* B */}
        <circle
          name="gradeB"
          strokeDasharray={gradeBDashArray}
          className="active-ring"
          strokeDashoffset={gradeBDashOffset}
          strokeWidth={gradeB === 0 ? 0 : 1}
          cx="17"
          cy="17"
          r="15.91549430918954"
          fill="transparent"
          stroke="#2F80ED"
        />
        {/* C */}
        <circle
          name="gradeC"
          strokeDasharray={gradeCDashArray}
          className="active-ring"
          strokeDashoffset={gradeCDashOffset}
          strokeWidth={gradeC === 0 ? 0 : 1}
          cx="17"
          cy="17"
          r="15.91549430918954"
          fill="transparent"
          stroke="#F2C94C"
        />
        {/* D */}
        <circle
          name="gradeD"
          strokeDasharray={gradeDDashArray}
          className="active-ring"
          strokeDashoffset={gradeDDashOffset}
          strokeWidth={gradeD === 0 ? 0 : 1}
          cx="17"
          cy="17"
          r="15.91549430918954"
          fill="transparent"
          stroke="#FB9B0B"
        />
        {/* F */}
        <circle
          name="gradeF"
          strokeDasharray={gradeFDashArray}
          className="active-ring"
          strokeDashoffset={gradeFDashOffset}
          strokeWidth={gradeF === 0 ? 0 : 1}
          cx="17"
          cy="17"
          r="15.91549430918954"
          fill="transparent"
          stroke="#B30015"
        />
        <g className="gauge-text">
          <text
            fontFamily="Open Sans,sans-serif"
            fontSize="7px"
            fontWeight="600"
            fill="#0E2435"
            className="rating-value"
            dx="0"
            dy="0"
            textAnchor="middle"
          >
            <tspan dx="18" dy="16">
              {setDisplayValue(
                Math.min(100, Math.round(gradeA) + Math.round(gradeB)),
              )}
              %
            </tspan>
          </text>
          <text
            fontFamily="Open Sans,sans-serif"
            fontSize="3px"
            fontWeight="400"
            text-transform="capitalize"
            fill="#0E2435"
            className="rating-type"
            dx="0"
            dy="0"
            textAnchor="middle"
          >
            <tspan fontWeight="400" dx="17" dy="23">
              Positive Indicators
            </tspan>
          </text>
        </g>
      </svg>
    </div>
  )
}
MultiRatingGauge.defaultProps = {
  gradeA: 0,
  gradeB: 0,
  gradeC: 0,
  gradeD: 0,
  gradeF: 0,
}

MultiRatingGauge.propTypes = {
  gradeA: PropTypes.number.isRequired,
  gradeB: PropTypes.number.isRequired,
  gradeC: PropTypes.number.isRequired,
  gradeD: PropTypes.number.isRequired,
  gradeF: PropTypes.number.isRequired,
}
export default MultiRatingGauge
