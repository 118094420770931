import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import TableCellData from './TableCellData'
import { ChevronUpSVG } from '../../../../assets/svg'
import './ViewDetailsCell.scss'

const ViewDetailsCell = ({ expanded, children }) => (
  <TableCellData
    align="right"
    className="v3-view-details-td"
    data-value={children}
  >
    <div className="view-details-content-container">
      <div className="view-details-content">
        {children && <div className="view-details-text">{children}</div>}
        <ChevronUpSVG
          className={classnames('details-chevron-svg', { expanded })}
        />
      </div>
    </div>
  </TableCellData>
)

ViewDetailsCell.propTypes = {
  children: PropTypes.string,
  expanded: PropTypes.bool.isRequired,
}

export default ViewDetailsCell
