import React, { useState, useReducer, FormEvent, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { axiosBase as axios } from 'utils/functions'
// import isURL from 'validator/lib/isURL'
import isEmail from 'validator/lib/isEmail'
import isLength from 'validator/lib/isLength'
import isStrongPassword from 'validator/lib/isStrongPassword'
import { displayToast } from 'actions/Dashboard'
import {
  Container,
  Header,
  TextInput,
  Button,
  Tooltip,
  InfoTooltip,
  Select,
} from 'components/common'
import PublicPageContainer from '../PublicPageContainer'
import './SignUp.scss'
import { useAppDispatch } from 'hooks'
import { useTranslation, Trans } from 'react-i18next'
import { stringLimit } from 'utils/functions'
import security_assessments from 'assets/images/security_assessments.svg'
import impact_analysis from 'assets/images/impact_analysis.svg'
import infrastructure_assessments from 'assets/images/infrastructure_assessments.svg'

interface OnboardFormStateType {
  firstName: string
  lastName: string
  email: string
  password: string
  passwordConfirm: string
  accessCode: string
  customerRegion: number | null
  errors: {
    firstName?: string
    lastName?: string
    email?: string
    password?: string
    passwordConfirm?: string
    accessCode?: string
    customerRegion?: string
  }
}

interface CustomerRegion {
  id: number
  country: string
}

type ActionTypeTypes = 'SET_INPUT' | 'SET_ERRORS'
type ActionInputTypes =
  | 'email'
  | 'firstName'
  | 'lastName'
  | 'accessCode'
  | 'password'
  | 'passwordConfirm'
  | 'customerRegion'

const initialState = {
  firstName: '',
  lastName: '',
  email: '',
  accessCode: '',
  password: '',
  passwordConfirm: '',
  customerRegion: null,
  errors: {},
}

const reducer = (
  state: OnboardFormStateType,
  action: {
    type: ActionTypeTypes
    input: ActionInputTypes
    value?: string | number
    msg?: string
  },
) => {
  switch (action.type) {
    case 'SET_INPUT': {
      return {
        ...state,
        [action.input]: String(action.value),
        errors: { ...state.errors, [action.input]: undefined },
      }
    }
    case 'SET_ERRORS':
      return {
        ...state,
        errors: { ...state.errors, [action.input]: action.msg },
      }
    default:
      throw new Error()
  }
}

const SignUp = () => {
  const [state, localDispatch] = useReducer(reducer, initialState)
  const [submitting, setSubmitting] = useState(false)
  const [agree, setAgree] = useState(false)
  const [customerRegions, setCustomerRegions] = useState<CustomerRegion[]>([])
  const history = useHistory()
  const dispatch = useAppDispatch()
  const { t, i18n } = useTranslation('pub', {
    keyPrefix: 'PublicRoutes.signUp',
  })

  useEffect(() => {
    const getCustomerRegions = async () => {
      const { data }: { data: CustomerRegion[] } = await axios.get(
        '/mcr/customer-regions',
        {
          baseURL: process.env.REACT_APP_AUTH_URL,
        },
      )
      setCustomerRegions(data)
    }
    try {
      getCustomerRegions()
    } catch (e) {}
  }, [])

  const setErrors = ({
    input,
    msg,
  }: {
    input: ActionInputTypes
    msg: string
  }) => localDispatch({ type: 'SET_ERRORS', input, msg })

  const isValid = () => {
    let isValid = true
    if (!isEmail(state.email)) {
      setErrors({ input: 'email', msg: t('invalidEmail') })
      isValid = false
    }
    if (!isLength(state.email, { min: 1, max: 160 })) {
      setErrors({ input: 'email', msg: t('emailRequired') })
      isValid = false
    }
    if (!isLength(state.firstName, { min: 1, max: 50 })) {
      setErrors({ input: 'firstName', msg: t('firstNameRequired') })
      isValid = false
    }
    if (!isLength(state.lastName, { min: 1, max: 50 })) {
      setErrors({ input: 'lastName', msg: t('lastNameRequired') })
      isValid = false
    }
    if (!isLength(state.accessCode, { min: 1 })) {
      setErrors({ input: 'accessCode', msg: t('accessCodeRequired') })
      isValid = false
    }
    if (
      !isStrongPassword(state.password, {
        minLength: 8,
        minLowercase: 1,
        minUppercase: 1,
        minNumbers: 1,
        minSymbols: 1,
      })
    ) {
      setErrors({ input: 'password', msg: t('invalidPassword') })
      isValid = false
    }
    if (!isLength(state.password, { max: 72 })) {
      setErrors({ input: 'password', msg: t('invalidPassword') })
      isValid = false
    }
    if (state.password !== state.passwordConfirm) {
      setErrors({ input: 'passwordConfirm', msg: t('invalidPasswordConfirm') })
      isValid = false
    }

    if (!state.customerRegion) {
      setErrors({ input: 'customerRegion', msg: t('customerRegionRequired') })
    }

    return isValid
  }

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault()
    setSubmitting(true)
    if (!isValid()) return setSubmitting(false)

    const { errors, ...customer_data } = state
    try {
      await axios.post(
        '/mcr/v2/onboard-customer',
        {
          ...customer_data,
          language: i18n.language,
        },
        { baseURL: process.env.REACT_APP_AUTH_URL },
      )
      history.push('/portal')
    } catch (e: any) {
      dispatch(
        displayToast({
          infoType: 'danger',
          title: t('sorry'),
          // persistent: true,
          dangerous: `${e?.response?.data ? `${e.response.data}<br/>` : ''}${t(
            'errorMessage',
            {
              emailLink:
                '<a href="mailto:support@riskrecon.com">support@riskrecon.com</a>',
            },
          )}`,
        }),
      )
      setSubmitting(false)

      console.log(e)
    }
  }

  return (
    <PublicPageContainer>
      <div className="sign-up-container">
        <div className="sign-up-grid">
          <div className="mcr-benefits">
            <Header as="h2">{t('benefits')}</Header>
            <div className="benefits-subtitle">{t('benefitsDesc')}</div>
            <div className="benefit-item-container">
              <img src={security_assessments} alt="security_assessments" />
              <div className="benefit-item-info">
                <Header as="h4">{t('securityAssessments')}</Header>
                <div className="benefit-item-description">
                  {t('securityAssessmentsDesc')}
                </div>
              </div>
            </div>
            <div className="benefit-item-container">
              <img
                src={infrastructure_assessments}
                alt="infrastructure_assessments"
              />
              <div className="benefit-item-info">
                <Header as="h4">{t('infrastructureAssessments')}</Header>
                <div className="benefit-item-description">
                  {t('infrastructureAssessmentsDesc')}
                </div>
              </div>
            </div>
            <div className="benefit-item-container">
              <img src={impact_analysis} alt="impact_analysis" />
              <div className="benefit-item-info">
                <Header as="h4">{t('impactAnalysis')}</Header>
                <div className="benefit-item-description">
                  {t('impactAnalysisDesc')}
                </div>
              </div>
            </div>
          </div>
          <Container id="gather-company-info-container">
            <Header as="h3">{t('getStarted')}</Header>
            <div className="onboard-message">{t('allRequired')}</div>
            <div className="user-onboard-form-container">
              <form className="user-onboard-form" onSubmit={handleSubmit}>
                <TextInput
                  id="first-name"
                  label={t('firstName')}
                  placeholder="First"
                  value={state.firstName}
                  onChange={({
                    target: { value },
                  }: React.ChangeEvent<HTMLInputElement>) =>
                    localDispatch({
                      type: 'SET_INPUT',
                      value,
                      input: 'firstName',
                    })
                  }
                  fullBorder
                  error={state.errors.firstName}
                />
                <TextInput
                  id="last-name"
                  label={t('lastName')}
                  placeholder="Last"
                  value={state.lastName}
                  onChange={({
                    target: { value },
                  }: React.ChangeEvent<HTMLInputElement>) =>
                    localDispatch({
                      type: 'SET_INPUT',
                      value,
                      input: 'lastName',
                    })
                  }
                  fullBorder
                  error={state.errors.lastName}
                />
                <TextInput
                  id="email"
                  label={t('email')}
                  placeholder="example@email.com"
                  value={state.email}
                  onChange={({
                    target: { value },
                  }: React.ChangeEvent<HTMLInputElement>) =>
                    localDispatch({ type: 'SET_INPUT', value, input: 'email' })
                  }
                  fullBorder
                  error={state.errors.email}
                />
                <TextInput
                  id="password"
                  type="password"
                  label={t('password')}
                  placeholder="**********"
                  autocomplete="new-password"
                  value={state.password}
                  onChange={({
                    target: { value },
                  }: React.ChangeEvent<HTMLInputElement>) =>
                    localDispatch({
                      type: 'SET_INPUT',
                      value,
                      input: 'password',
                    })
                  }
                  fullBorder
                  error={state.errors.password}
                />
                <TextInput
                  id="passwordConfirm"
                  type="password"
                  label={t('passwordConfirm')}
                  placeholder="**********"
                  autocomplete="new-password"
                  value={state.passwordConfirm}
                  onChange={({
                    target: { value },
                  }: React.ChangeEvent<HTMLInputElement>) =>
                    localDispatch({
                      type: 'SET_INPUT',
                      value,
                      input: 'passwordConfirm',
                    })
                  }
                  fullBorder
                  error={state.errors.passwordConfirm}
                />
                {/* 
// @ts-ignore */}
                <Select
                  id="customerRegion"
                  label={t('country')}
                  isSearchable={true}
                  selected={customerRegions.find(
                    ({ id }) => id === state.customerRegion,
                  )}
                  options={customerRegions.map((cr) => ({
                    value: cr.id,
                    label: cr.country,
                  }))}
                  onChange={({ value }: { label: string; value: number }) =>
                    localDispatch({
                      type: 'SET_INPUT',
                      value,
                      input: 'customerRegion',
                    })
                  }
                  error={state.errors.customerRegion}
                />
                <TextInput
                  id="access-code"
                  label={
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      {t('accessCode')}
                      <InfoTooltip title={t('accessCodeTooltip')} />
                    </div>
                  }
                  placeholder=""
                  value={state.accessCode}
                  onChange={({
                    target: { value },
                  }: React.ChangeEvent<HTMLInputElement>) =>
                    localDispatch({
                      type: 'SET_INPUT',
                      value: stringLimit(value, 100),
                      input: 'accessCode',
                    })
                  }
                  fullBorder
                  error={state.errors.accessCode}
                />
                <div className="agreement-container">
                  <input
                    type="checkbox"
                    id="agreement"
                    name="agreement"
                    onChange={() => setAgree((state) => !state)}
                    checked={agree}
                  />
                  <label htmlFor="agreement" className="agreement-message">
                    <Trans t={t} i18nKey="confirmTerms">
                      I confirm that I have read and agree to the My Cyber
                      Risk&nbsp;
                      <a
                        href={t('termsConditionsLink')}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Terms & Conditions
                      </a>
                      &nbsp;and&nbsp;
                      <a
                        href={t('privacyNoticeLink')}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Privacy Notice
                      </a>
                      .
                    </Trans>
                  </label>
                </div>
                <div className="sign-up-button-group">
                  <Tooltip
                    title={t('termsTooltip')}
                    disabled={agree}
                    style={{ display: 'flex' }}
                  >
                    <Button
                      type="submit"
                      color="blue"
                      loading={submitting}
                      disabled={!agree}
                    >
                      {t('signUp')}
                    </Button>
                  </Tooltip>
                </div>
                <div className="account-already">
                  {t('accountAlready')}&nbsp;
                  <a href={process.env.REACT_APP_AUTH_URL}>{t('logIn')}</a>
                </div>
              </form>
            </div>
          </Container>
        </div>
      </div>
    </PublicPageContainer>
  )
}

export default SignUp
