import React from 'react'

export function truncateString(string = '', maxCharacters = 100) {
  // if null the default value is not being set - in this case, we simply return an empty string
  return string
    ? string.toString().length < maxCharacters
      ? string
      : string.toString().substring(0, maxCharacters - 3) + '...'
    : ''
}

export const removeUnderscores = (string = '') => string.replace(/_/g, ' ')

export const capitalizeDNS = (string = '') => {
  if (string === 'dns security') {
    return 'DNS Security'
  } else {
    return string
  }
}

export function setColorBySeverity(key, value, criterion) {
  if (
    value === '' ||
    value === undefined ||
    criterion === 'unencrypted_sensitive_systems'
  )
    return ''
  const parsedValue = parseFloat(value)
  switch (key) {
    case 'security_criteria_rating_numeric':
      if (parsedValue >= 7.5) {
        return 'green'
      } else if (parsedValue >= 5.0) {
        return 'blue'
      } else if (parsedValue >= 2.5) {
        return 'yellow'
      } else if (parsedValue >= 0) {
        return 'red'
      } else {
        return ''
      }
    default:
      return ''
  }
}

export function getRatingSeverityColor(r) {
  const rating = Number(r)

  if (rating === -1 || Number.isNaN(Number(rating))) return ''
  if (rating < 2.5) return 'red'
  if (rating < 5) return 'yellow'
  if (rating < 7.5) return 'blue'
  return 'green'
}

export function getRatingSeverityTextColor(rating) {
  if (rating.toUpperCase() === 'HIGH') return 'red'
  if (rating.toUpperCase() === 'MEDIUM') return 'blue'
  if (rating.toUpperCase() === 'LOW') return 'green'
  return 'green'
}

export function setClassColorByConcern(key, value = '') {
  let colorClass = ''
  const ratingClasses = ['red', 'red', 'red', 'blue', 'green', 'green', 'green']

  switch (key) {
    case 'asset_value':
      if (value.toUpperCase() === 'HIGH') {
        colorClass = 'red-text'
      } else if (value.toUpperCase() === 'MEDIUM') {
        colorClass = 'blue-text'
      } else {
        colorClass = 'green-text'
      }
      break
    case 'severity':
      if (
        value.toUpperCase() === 'CRITICAL' ||
        value.toUpperCase() === 'HIGH'
      ) {
        colorClass = 'red-text'
      } else if (value.toUpperCase() === 'MEDIUM') {
        colorClass = 'blue-text'
      } else {
        colorClass = 'green-text'
      }
      break
    case 'priority':
      if (value < 1) {
        colorClass = ratingClasses[1] + '-text'
      } else {
        colorClass = ratingClasses[value] + '-text'
      }
      break
    case 'cvss_rating':
      if (
        value.toUpperCase() === 'CRITICAL' ||
        value.toUpperCase() === 'HIGH'
      ) {
        colorClass = 'red-text'
      } else if (value.toUpperCase() === 'MEDIUM') {
        colorClass = 'yellow-text'
      } else {
        colorClass = 'blue-text'
      }
      break
    case 'cvss_score':
      if (value > 7) {
        colorClass = 'red-text'
      } else if (value > 4) {
        colorClass = 'yellow-text'
      } else {
        colorClass = 'blue-text'
      }
      break
    case 'security_criteria_rating_numeric':
      if (value > 7.5) {
        colorClass = 'green-text'
      } else if (value > 5.0) {
        colorClass = 'blue-text'
      } else if (value > 2.5) {
        colorClass = 'yellow-text'
      } else {
        colorClass = 'red-text'
      }
      break
    default:
      colorClass = ''
  }
  return colorClass
}

export function prettyCriteriaName(key) {
  const criteriaValues = {
    patching_app_server: 'Application Server Patching',
    patching_openssl: 'OpenSSL Patching',
    patching_vuln_open_ssl: 'OpenSSL Patching',
    patching_web_cms: 'CMS Patching',
    patching_web_server: 'Web Server Patching',
    web_encryption_date_expire: 'Certificate Expiration Date',
    web_encryption_date_valid: 'Certificate Valid Date',
    web_encryption_hash: 'Encryption Hash Algorithm',
    web_encryption_key_length: 'Encryption Key Length',
    web_encryption_protocol: 'Encryption Protocols',
    web_encryption_subject: 'Certificate Subject',
    threatintel_cc_server: 'Command and Control Servers',
    threatintel_botnet_host: 'Botnet Hosts',
    threatintel_hostile_host_hacking: 'Hostile-Hosts: Hacking',
    threatintel_hostile_host_scanning: 'Hostile-Hosts: Scanning',
    threatintel_phishing_site: 'Phishing Sites',
    threatintel_other: 'Other Blacklisted Hosts',
    threatintel_spamming_host: 'Spamming Hosts',
    shared_hosting: 'Cotenant IP Hosting',
    config_web_cms_authentication: 'CMS Authentication',
    unencrypted_sensitive_systems: 'High Value System Encryption',
    web_threat_intel_alert_external: 'External Threat Intelligence Alerts',
    dns_hijacking_protection: 'Domain Hijacking Protection',
    domain_hijacking_protection: 'Domain Hijacking Protection',
    email_authentication: 'Email Authentication (SPF or DKIM)',
    email_encryption_enabled: 'Email Encryption (STARTTLS)',
    patching_os: 'OS Patching',
    patching_other: 'Patching',
    threat_intel_alert_external: 'External Threat Intelligence',
    web_http_security_headers: 'HTTP Security Headers',
    malicious_code: 'Malicious Code',
    unencrypted_sensitive_communications: 'High Value System Encryption',
    iot_devices: 'IOT Devices',
    unsafe_network_services: 'Unsafe Network Services',
    data_loss_6: 'Breach Events 6 Months',
    data_loss_12: 'Breach Events 12 Months',
    data_loss_24: 'Breach Events 24 Months',
    data_loss_36: 'Breach Events 36 Months',
    data_loss_36plus: 'Breach Events >36 Months',
  }
  return criteriaValues[key] !== undefined ? criteriaValues[key] : key
}

export function prettyDomainName(key) {
  const domainValues = {
    software_patching: 'Software Patching',
    web_encryption: 'Web Encryption',
    threat_intell: 'System Reputation',
    // with epic/CP-1843 we are unable to move the shared_hosting criteria to the system hosting domain on the back end.
    // The simplest solution if the front end has to handle this is to just rename defensibility
    // to system hosting, rather than injecting a system hosting domain in the action file
    defensibility: 'System Hosting',
    web_app_security: 'Application Security',
    dns_security: 'DNS Security',
    email_security: 'Email Security',
    data_loss: 'Breach Events',
    governance: 'Governance',
    system_hosting: 'System Hosting',
    network_filtering: 'Network Filtering',
  }
  return domainValues[key] !== undefined ? domainValues[key] : key
}

export function cveScoreToRating(score) {
  const rating = parseFloat(score)
  let prettyName = ''
  if (rating < 4) {
    prettyName = 'Low'
  } else if (rating >= 4 && rating < 7) {
    prettyName = 'Medium'
  } else if (rating >= 7 && rating < 9) {
    prettyName = 'High'
  } else if (rating >= 9) {
    prettyName = 'Critical'
  }
  return prettyName
}

export function decimalToPercentage(decimal) {
  if (decimal === undefined) return undefined
  const getPercent = (decimal * 100).toFixed(0)
  if (Number.isNaN(Number(getPercent))) return false
  return getPercent + '%'
}

export function ordinalSuffixOf(i) {
  const j = i % 10
  const k = i % 100
  if (j === 1 && k !== 11) {
    return (
      <div>
        {i}
        <sup>st</sup>
      </div>
    )
  }
  if (j === 2 && k !== 12) {
    return (
      <div>
        {i}
        <sup>nd</sup>
      </div>
    )
  }
  if (j === 3 && k !== 13) {
    return (
      <div>
        {i}
        <sup>rd</sup>
      </div>
    )
  }
  return (
    <div>
      {i}
      <sup>th</sup>
    </div>
  )
}

export function parameterize(string) {
  return string
    .toString()
    .toLowerCase()
    .replace(/ /g, '-')
    .replace(/_/g, '-')
    .replace(/\./g, '-')
}

export function stripDomain(domain) {
  if (domain === undefined) {
    return ''
  }
  let cleansedDomain = domain.replace('http://', '')
  cleansedDomain = cleansedDomain.replace('https://', '')
  cleansedDomain = cleansedDomain.split('/')[0]
  return cleansedDomain
}

export function isIpAddress(ip_address) {
  return /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(
    ip_address,
  )
}

export function scoreToActionName(score) {
  const parsedValue = parseFloat(score)
  let action = ''
  if (parsedValue >= 7.5) {
    action = 'Proceed'
  } else if (parsedValue >= 5.0) {
    action = 'Limit'
  } else if (parsedValue >= 2.5) {
    action = 'Fix'
  } else {
    action = 'Stop'
  }
  return action
}

export function assetValueToNumeric(assetValue) {
  const valueCap = assetValue.toUpperCase()
  let number = 1
  switch (valueCap) {
    case 'KEY':
      number = 0
      break
    case 'HIGH':
      number = 4
      break
    case 'MEDIUM':
      number = 3
      break
    case 'LOW':
      number = 2
      break
    default:
      number = 1
      break
  }
  return number
}

export function severityToNumeric(severity) {
  const valueCap = severity.toUpperCase()
  let number = 1
  switch (valueCap) {
    case 'CRITICAL':
      number = 5
      break
    case 'HIGH':
      number = 4
      break
    case 'MEDIUM':
      number = 3
      break
    case 'LOW':
      number = 2
      break
    default:
      number = 1
      break
  }
  return number
}

export function pluralOrSingularize(count) {
  return parseInt(count) === 1 ? '' : 's'
}

export function toFixedExceptTen(value, criterion) {
  const newValue = parseFloat(value)

  if (newValue === -1 || criterion === 'unencrypted_sensitive_systems') {
    return 'N/A'
  }

  return newValue === 10 ? 10 : newValue.toFixed(1)
}

export function formatDateObjectsForGraph(objects, dateKey = 'date_updated') {
  objects.forEach((trendingDataPoint) => {
    if (typeof trendingDataPoint[dateKey] === 'string') {
      let newDateUpdated = trendingDataPoint[dateKey]
      newDateUpdated = newDateUpdated.split(' UTC')[0]
      trendingDataPoint[dateKey] = newDateUpdated.replace(' ', 'T') + 'Z'
    }
  })

  return objects
}

// Following JSON spec structure
export function formatDateObjectsV2(objects, dateKey = 'date_updated') {
  objects.forEach((trendingDataPoint) => {
    if (typeof trendingDataPoint.attributes[dateKey] === 'string') {
      let newDateUpdated = trendingDataPoint.attributes[dateKey]
      newDateUpdated = newDateUpdated.split(' UTC')[0]
      trendingDataPoint.attributes[dateKey] =
        newDateUpdated.replace(' ', 'T') + 'Z'
    }
  })

  return objects
}

export function formatNumber(num) {
  return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

// Stolen from v1
export function determineIndustryPercentileColor(score) {
  if (score < 0.33) {
    return { color: '#e53535' }
  } else if (score < 0.66) {
    return { color: '#6fa9d8' }
  } else {
    return { color: '#6bbf47' }
  }
}

export function trendValueToText(value) {
  if (value === 0) {
    return 'same'
  } else if (value > 0) {
    return 'increase'
  } else {
    return 'decrease'
  }
}

export function isActualHostName(value) {
  let isHostName
  switch (value) {
    case '-':
      isHostName = false
      break
    case isIpAddress(value):
      isHostName = false
      break
    default:
      isHostName = true
      break
  }

  return isHostName
}

export function capitalize(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export function changeNumericAssetValueToKey(asset_value) {
  return asset_value === 0 ? 'Key' : asset_value
}

export function listifyOnNewLine(string) {
  const text = String(string)
  return text.split('\n').map((txt) => <li key={txt}>{txt}</li>)
}
