import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import take from 'ramda/src/take'
import { getRatingType, toFixedExceptTen } from '../../../utils/functions.js'
import './RatingPill.scss'

interface RatingPillProps {
  rating: number
  composite: boolean
  letter?: string
}

const RatingPill = ({ rating, composite, letter }: RatingPillProps) => {
  const ratingIsNumber = rating === -1 ? false : typeof rating === 'number'
  const ratingType = getRatingType(rating)
  // in order to support letter prop as a string, there is some confusing stuff below
  // we could force a certain letter by passing a rating from the desired range instead.
  // That's kind of messy at a higher abstraction level though. ¯\_(ツ)_/¯
  return (
    <div
      className={classnames('v3-rating-pill', {
        composite,
        letter,
        ...(typeof letter === 'string' && {
          [letter]: true,
        }),
        [ratingType]: typeof letter !== 'string',
      })}
    >
      {((ratingIsNumber && composite) || letter) && (
        <span className="v3-rating-pill-letter">
          {(typeof letter === 'string' && letter) || take(2, ratingType)}
        </span>
      )}
      {!letter && (
        <span className="v3-rating-pill-rating">
          {ratingIsNumber ? toFixedExceptTen(rating) : 'Info'}
        </span>
      )}
    </div>
  )
}

RatingPill.propTypes = {
  composite: PropTypes.bool,
  letter: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  rating: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

export default RatingPill
