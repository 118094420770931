import React, { useState, useRef, useEffect } from 'react'
import { InView } from 'react-intersection-observer'
import PropTypes from 'prop-types'
import { unionBy, union } from 'lodash'
import Checkbox from '../forms/Checkbox'
import './Filter.scss'
import { AngleDown, CloseSVG, LoadingSVG, CrossedCircle } from 'assets/svg'
import { BorderlessButton, Loading, TextInput } from 'components/common'
import { useDebounce } from 'hooks'

// Parent key value for children must be the same as the as the value key of the parent
const optionsShown = 20

const Filter = ({
  id,
  options,
  className,
  defaultText,
  disabled,
  width,
  searchPlaceholder,
  selectedOptions,
  setSelectedFilters,
  handleSearch,
  emptyStatePlaceholder,
  handleClear,
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const [fetchingOptions, setFetchingOptions] = useState(false)
  const [searchInput, setSearchInput] = useState('')
  const [tempSelectedOptions, setTempSelectedOptions] =
    useState(selectedOptions)
  const [tempOptions, setTempOptions] = useState(options)
  const [pagesShown, setPagesShown] = useState(1) // fake pagination
  const [fetchingAdditionalPages, setFetchingAdditionalPages] = useState(false) // fake pagination
  const node = useRef()
  const actionsRef = useRef()
  const debouncedSearchTerm = useDebounce(searchInput, 500)

  const toShowOptions =
    tempOptions && tempOptions.length > optionsShown
      ? tempOptions.slice(0, optionsShown * pagesShown)
      : tempOptions

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick)
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick)
    }
  }, [])

  useEffect(() => {
    if (dropdownOpen) {
      document.getElementById('filter-search').focus()
      actionsRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }, [dropdownOpen])

  useEffect(() => {
    if (handleSearch && Object.keys(tempSelectedOptions).length > 0) {
      const keys = Object.keys(tempSelectedOptions)
      const editedOptions = [...options]
      editedOptions.forEach((opt) => {
        if (keys.find((val) => val === opt.value)) {
          if (opt.isParent) {
            opt.children.forEach((child) => {
              child.checked = tempSelectedOptions[opt.value].checked
            })
          }
          opt.checked = tempSelectedOptions[opt.value].checked
        }
      })
      setTempOptions(editedOptions)
    } else {
      setTempOptions(options)
    }
    setFetchingOptions(false)
  }, [handleSearch, options, tempSelectedOptions])

  useEffect(() => {
    setTempSelectedOptions(selectedOptions)
  }, [selectedOptions])

  useEffect(() => {
    let updatedTempOptions = []
    if (handleSearch) {
      handleSearch(searchInput)
      if (searchInput.trim() !== '') {
        setFetchingOptions(true)
      } else {
        if (Object.keys(tempSelectedOptions).length !== 0) {
          const toBeAddedOptions = Object.keys(tempSelectedOptions).map(
            (key) => {
              return { ...tempSelectedOptions[key], checked: true }
            },
          )
          updatedTempOptions = unionBy(toShowOptions, toBeAddedOptions, 'label')
          Object.keys(tempSelectedOptions).forEach((val) => {
            if (tempSelectedOptions[val].parent) {
              const parentIdx = updatedTempOptions.findIndex(
                (i) => i.value === tempSelectedOptions[val].parent,
              )
              updatedTempOptions[parentIdx].children.forEach((child) => {
                child.checked = true
              })
            } else {
              updatedTempOptions[
                updatedTempOptions.findIndex((i) => i.value === val)
              ].checked = true
            }
          })
        }
        setTempOptions(updatedTempOptions)
      }
    } else {
      if (searchInput.trim() !== '') {
        updatedTempOptions = toShowOptions.filter((o) =>
          o.label.toLowerCase().includes(searchInput.toLowerCase()),
        )
      } else if (Object.keys(tempSelectedOptions).length !== 0) {
        updatedTempOptions = [...toShowOptions]
        Object.keys(tempSelectedOptions).forEach((val) => {
          if (tempSelectedOptions[val].parent) {
            const parentIdx = updatedTempOptions.findIndex(
              (i) => i.value === tempSelectedOptions[val].parent,
            )
            updatedTempOptions[parentIdx].children[
              updatedTempOptions[parentIdx].children.findIndex(
                (i) => i.value === val,
              )
            ].checked = true
          } else {
            updatedTempOptions[
              updatedTempOptions.findIndex((i) => i.value === val)
            ].checked = true
          }
        })
      } else {
        updatedTempOptions = [...toShowOptions]
      }
      setTempOptions(updatedTempOptions)
    }
  }, [
    debouncedSearchTerm,
    handleSearch,
    searchInput,
    tempSelectedOptions,
    toShowOptions,
  ])

  const handleOutsideClick = (e) => {
    if (!node.current.contains(e.target)) {
      setDropdownOpen(false)
    }
  }

  const handleCheckboxChange = (option, isChild) => {
    const updatedTempOptions = [...toShowOptions]
    const updatedSelectedOptions = { ...tempSelectedOptions }
    if (updatedTempOptions?.length > 0) {
      // options are there
      if (option.isParent) {
        // grouped options parent is selected
        const idx = updatedTempOptions.findIndex(
          (opt) => opt.value === option.value,
        )
        if (idx !== undefined) {
          // selected is in currently shown options
          updatedTempOptions[idx] = {
            ...updatedTempOptions[idx],
            checked: !updatedTempOptions[idx].checked,
          }
          if (updatedTempOptions[idx].checked) {
            // check all children and add to selected Options
            updatedSelectedOptions[option.value] = updatedTempOptions[idx]
            updatedTempOptions[idx].children.forEach((child) => {
              child.checked = true
              updatedSelectedOptions[child.value] = child
            })
          } else {
            // uncheck all children and remove from selected options
            if (updatedSelectedOptions[option.value]) {
              // remove from selected options
              delete updatedSelectedOptions[option.value]
            }
            updatedTempOptions[idx].children.forEach((child) => {
              child.checked = false
              if (updatedSelectedOptions[child.value])
                delete updatedSelectedOptions[child.value]
            })
          }
        } else if (updatedSelectedOptions[option.value]) {
          // option not shown in selected options
          delete updatedSelectedOptions[option.value] // remove
        }
      } else if (isChild) {
        // grouped options child is selceted
        const parentIdx = updatedTempOptions.findIndex(
          (opt) => opt.value === option.parent,
        ) // get parent idx
        if (parentIdx !== undefined) {
          const idx = updatedTempOptions[parentIdx].children.findIndex(
            (opt) => opt.value === option.value,
          )
          if (idx !== undefined) {
            // selected is in currently shown options
            updatedTempOptions[parentIdx].children[idx] = {
              ...updatedTempOptions[parentIdx].children[idx],
              checked: !updatedTempOptions[parentIdx].children[idx].checked,
            }
            if (updatedSelectedOptions[option.value]) {
              // remove from selected options
              delete updatedSelectedOptions[option.value]
              updatedTempOptions[parentIdx].checked = false
              delete updatedSelectedOptions[updatedTempOptions[parentIdx].value]
            } else {
              // add to selected options
              updatedSelectedOptions[option.value] =
                updatedTempOptions[parentIdx].children[idx]
              const uncheckedSiblings = updatedTempOptions[
                parentIdx
              ].children.filter((child) => {
                return !child.checked
              })
              // check to see if all other children are checked and add parent
              if (uncheckedSiblings.length === 0) {
                updatedTempOptions[parentIdx].checked = true
                updatedSelectedOptions[updatedTempOptions[parentIdx].value] =
                  updatedTempOptions[parentIdx]
              }
            }
          } else if (updatedSelectedOptions[option.value]) {
            // option not shown in selected options
            delete updatedSelectedOptions[option.value] //
          }
        }
      } else {
        // not grouped options normal selection
        const idx = updatedTempOptions.findIndex(
          (opt) => opt.value === option.value,
        )
        if (idx !== undefined) {
          // selected is in currently shown options
          updatedTempOptions[idx] = {
            ...updatedTempOptions[idx],
            checked: !updatedTempOptions[idx].checked,
          }
          if (updatedSelectedOptions[option.value]) {
            // remove from selected options
            delete updatedSelectedOptions[option.value]
          } else {
            // add to selected options
            updatedSelectedOptions[option.value] = updatedTempOptions[idx]
          }
        } else if (updatedSelectedOptions[option.value]) {
          // option not shown in selected options
          delete updatedSelectedOptions[option.value] // remove
        }
      }
    } else {
      // handle only selected options
      if (updatedSelectedOptions[option.value]) {
        updatedSelectedOptions[option.value].checked =
          !updatedSelectedOptions[option.value].checked // uncheck but not remove
      }
    }
    setTempSelectedOptions(updatedSelectedOptions)
    setTempOptions(updatedTempOptions)
  }

  const Checkboxes = ({ checkboxOptions, width }) => {
    const loadMoreItems = (inView) => {
      if (!inView) {
        return
      }
      setFetchingAdditionalPages(true)
      setPagesShown(pagesShown + 1)
    }

    if (checkboxOptions.length > 0 && !fetchingOptions) {
      const display = checkboxOptions.map((record, idx) => {
        const parent = (
          <div className="filter-checkbox-container" key={`${idx}-parent`}>
            <Checkbox
              id={`${record.value}-filter-checkbox`}
              checked={record.checked}
              onChange={() => handleCheckboxChange(record)}
              disabled={record.disabled}
            />
            <label
              htmlFor={`${record.value}-filter-checkbox`}
              style={{ width: width - 70 }}
              className={`filter-checkbox-label ${
                record.checked ? 'checked' : ''
              } ${record.disabled ? 'disabled' : ''} `}
            >
              {record.label}
            </label>
          </div>
        )
        const children = record.children?.map((child, idx) => {
          return (
            <div
              className="filter-checkbox-container child"
              key={`${idx}-child`}
            >
              <Checkbox
                id={`${child.value}-filter-checkbox`}
                checked={child.checked}
                onChange={() => handleCheckboxChange(child, true)}
                disabled={child.disabled}
              />
              <label
                htmlFor={`${child.value}-filter-checkbox`}
                className={`filter-checkbox-label ${
                  child.checked ? 'checked' : ''
                } ${child.disabled ? 'disabled' : ''} `}
              >
                {child.label}
              </label>
            </div>
          )
        })

        return (
          <div key={`${record.value}-${idx}-container`}>
            {parent}
            {children}
          </div>
        )
      })

      return (
        <>
          {display}
          {tempOptions.length > optionsShown * pagesShown && (
            <InView
              id="fetching-more"
              onChange={(inView) => loadMoreItems(inView)}
            >
              {fetchingAdditionalPages && (
                <div className="loading-more">
                  <div className="filter-loading-more-container">
                    <LoadingSVG size="25" />
                    <p>Loading More Options...</p>
                  </div>
                </div>
              )}
            </InView>
          )}
        </>
      )
    }
    return <div className="empty-state">{emptyStatePlaceholder}</div>
  }

  Checkboxes.propTypes = {
    checkboxOptions: PropTypes.array,
    width: PropTypes.number,
  }

  const DisplayText = () => {
    const labels = []
    const selectedLabels = []
    if (toShowOptions?.length > 0) {
      toShowOptions.forEach((record) => {
        if (record.checked) {
          labels.push(record.label)
        }
      })
    }
    if (Object.keys(tempSelectedOptions).length > 0) {
      Object.keys(tempSelectedOptions).forEach((opt) => {
        selectedLabels.push(tempSelectedOptions[opt].label)
      })
    }

    const displayLabels = union(selectedLabels, labels)
    if (displayLabels.length > 0) {
      return <>{displayLabels.join(', ')}</>
    } else {
      return <>{defaultText}</>
    }
  }

  const handleSetSelectedFilters = () => {
    const allSetOptions = unionBy(tempOptions, options, 'label')
    setSelectedFilters(tempSelectedOptions, allSetOptions)
    setDropdownOpen(false)
    setSearchInput('')
  }

  const handleDisplayCheckboxOptions = () => {
    if (toShowOptions?.length > 0 || searchInput !== '') {
      if (toShowOptions.length > 50) {
        return toShowOptions
      }
      return toShowOptions
    } else if (Object.keys(tempSelectedOptions).length > 0) {
      return Object.keys(tempSelectedOptions).map(
        (key) => tempSelectedOptions[key],
      )
    }
    return []
  }

  const handleClearPressed = () => {
    setTempSelectedOptions({})
    const updTempOptions = [...toShowOptions]
    updTempOptions.forEach((opt) => {
      opt.checked = false
    })
    setTempOptions(updTempOptions)
    setSearchInput('')
    handleClear()
  }

  const handleKeyUp = (e) => {
    if (e.keyCode === 27) {
      setSearchInput('')
    }
  }

  return (
    <div id={id} className={`${className} filter-select-container`} ref={node}>
      <div className="filter-select-button-wrapper">
        <button
          className={`${disabled ? 'disabled' : ''} filter-select-button`}
          disabled={disabled}
          onClick={() =>
            dropdownOpen ? setDropdownOpen(false) : setDropdownOpen(true)
          }
          style={{ width: width }}
        >
          <div className="display-text" style={{ width: width - 35 }}>
            <DisplayText />
          </div>
          {disabled ? (
            <CrossedCircle className="filter-icon" fill="#435A70" />
          ) : (
            <AngleDown
              className={`filter-icon ${dropdownOpen ? '' : 'rotate'}`}
              height="8px"
              width="12px"
              fill="#435A70"
            />
          )}
        </button>
      </div>
      {dropdownOpen && (
        <div className="dropdown-container" style={{ width: width }}>
          <TextInput
            className="filter-search"
            fullBorder={true}
            style={{ width: width - 30 }}
            placeholder={searchPlaceholder}
            onChange={(e) => setSearchInput(e.target.value)}
            value={searchInput}
            id="filter-search"
            noLabel={false}
            icon={
              searchInput.length > 0 ? (
                <button
                  className="reset filter-search-icon"
                  onClick={() => setSearchInput('')}
                >
                  <CloseSVG size="20" />
                </button>
              ) : null
            }
            onKeyUp={handleKeyUp}
          />
          {/* subtract 2 to account for the border */}
          <div className="filter-checkboxes" style={{ width: width - 2 }}>
            {fetchingOptions ? (
              <Loading
                className="filter-loading"
                size="small"
                isLoading={fetchingOptions}
              />
            ) : (
              <Checkboxes
                checkboxOptions={handleDisplayCheckboxOptions()}
                width={width}
              />
            )}
          </div>
          <div className="filter-actions" ref={actionsRef}>
            <BorderlessButton
              disabled={Object.keys(tempSelectedOptions).length === 0}
              onClick={handleClearPressed}
            >
              Clear
            </BorderlessButton>
            <BorderlessButton
              color="blue"
              disabled={Object.keys(tempSelectedOptions).length === 0}
              onClick={handleSetSelectedFilters}
            >
              Apply Dimensions
            </BorderlessButton>
          </div>
        </div>
      )}
    </div>
  )
}

Filter.defaultProps = {
  width: 300,
  selectedOptions: {},
}

Filter.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      checked: PropTypes.bool,
      disabled: PropTypes.bool,
      value: PropTypes.any,
    }),
  ).isRequired,
  defaultText: PropTypes.string.isRequired,
  className: PropTypes.string,
  id: PropTypes.string,
  disabled: PropTypes.bool,
  width: PropTypes.number,
  searchPlaceholder: PropTypes.string,
  selectedOptions: PropTypes.object,
  setSelectedFilters: PropTypes.func,
  handleSearch: PropTypes.func,
  emptyStatePlaceholder: PropTypes.string,
  handleClear: PropTypes.func,
}

export default Filter
