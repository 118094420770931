import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import DatePicker from 'react-datepicker'
import CalendarTodaySVG from '../../../assets/svg/CalendarTodaySVG'
import 'react-datepicker/dist/react-datepicker.min.css'
import './DatePicker.scss'

/**
 * Styled wrapper of [React Date Picker](https://github.com/Hacker0x01/react-datepicker).
 */
const RRDatePicker = ({
  id,
  selected,
  onChange,
  placeholderText,
  className,
  ...rest
}) => {
  return (
    <div className={classnames('v3-date-picker', className)} id={id}>
      <div className={classnames('icon-wrapper', { selected })}>
        <CalendarTodaySVG />
      </div>
      <DatePicker
        selected={selected}
        onChange={onChange}
        placeholderText={placeholderText}
        {...rest}
      />
    </div>
  )
}

RRDatePicker.defaultProps = {
  placeholderText: 'Unspecified',
}

RRDatePicker.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  placeholderText: PropTypes.string.isRequired,
  selected: PropTypes.any,
}

export default RRDatePicker
