export const domainKeys = [
  'software_patching',
  'web_app_security',
  'web_encryption',
  'threat_intell',
  'data_loss',
  'defensibility',
  'governance',
  'system_hosting',
  'email_security',
  'dns_security',
  'network_filtering',
]

export const infoOnlyDomains = []

export const criteriaKeys = {
  software_patching: [
    'patching_app_server',
    'patching_openssl',
    'patching_web_cms',
    'patching_web_server',
  ],
  web_encryption: [
    'web_encryption_date_expire',
    'web_encryption_date_valid',
    'web_encryption_hash',
    'web_encryption_key_length',
    'web_encryption_protocol',
    'web_encryption_subject',
  ],
  threat_intell: [
    'threatintel_cc_server',
    'threatintel_botnet_host',
    'threatintel_hostile_host_hacking',
    'threatintel_hostile_host_scanning',
    'threatintel_phishing_site',
    'threatintel_other',
    'threatintel_spamming_host',
  ],
  defensibility: ['shared_hosting'],
  web_app_security: ['config_web_cms_authentication'],
  dns_security: ['dns_hijacking_protection'],
  email_security: ['email_authentication', 'email_encryption_enabled'],
  network_filtering: ['unsafe_network_services', 'iot_devices'],
}

export const ratingClasses = [
  'red',
  'red',
  'red',
  'yellow',
  'yellow',
  'blue',
  'blue',
  'green',
  'green',
  'green',
  'green',
]

export const securityProfileDetailedCriteriaHeaders = [
  'Security Criteria',
  'Rating',
  'Issue Count',
  'Issue Rate',
]

export const securityProfileDetailedDataKeys = [
  'display_name',
  'security_criteria_rating_numeric',
  'issue_count',
  'issue_rate_percentage',
]

export const securityProfileDetailedColumnWidths = [
  '20%',
  '40%',
  '13%',
  '13%',
  '13%',
]

export function getSecurityDetailedKeys(domain) {
  switch (domain) {
    case 'system_hosting':
      return ['language_data_list_count']
    case 'data_loss':
      return ['issue_count']
    case 'governance':
      return []
    case 'defensibility':
      return ['short_label', 'criteria_total_count', 'issue_rate_percentage']
    case 'threat_intell':
      return ['issue_count', 'issue_rate_percentage']
    case 'network_filtering':
      return ['issue_count', 'criteria_total_count', 'issue_rate_percentage']
    default:
      return ['issue_count', 'criteria_total_count', 'issue_rate_percentage']
  }
}

export function getSecurityDetailedHeaders(domain) {
  switch (domain) {
    case 'system_hosting':
      return ['Count']
    case 'data_loss':
      return ['Count']
    case 'governance':
      return []
    case 'defensibility':
      return ['Count', 'Population', 'Issue Rate']
    case 'threat_intell':
      return ['Issue Count', 'Issue Rate']
    default:
      return ['Issue Count', 'Population', 'Issue Rate']
  }
}
