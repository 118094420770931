import React from 'react'
import PropTypes from 'prop-types'
import TableCellData from './TableCellData'
import { AddCircleSVG, StrikeThroughOSVG } from '../../../../assets/svg'
import './OpenSlideoutCell.scss'
import Tooltip from '../../tooltips/Tooltip'

const OpenSlideoutCell = ({ onClick, dataTip, title, disabled, ...rest }) => {
  if (disabled) {
    return (
      <TableCellData width="50" className="open-slideout-cell">
        <Tooltip
          className="ignore-tooltip-container"
          followCursor
          duration={0}
          style={{ display: 'flex', alignItems: 'center' }}
          html={
            <div className="ignored-tooltip">
              <StrikeThroughOSVG /> Ignored Systems
            </div>
          }
        />
        <div className="open-slideout-icon-container">
          <AddCircleSVG size="20px" style={{ fill: '#BDBDBD' }} />
        </div>
      </TableCellData>
    )
  }
  return (
    <TableCellData
      onClick={onClick}
      width="50"
      className="open-slideout-cell"
      {...rest}
    >
      <div className="open-slideout-icon-container">
        <Tooltip
          title={title}
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <AddCircleSVG size="20px" />
        </Tooltip>
      </div>
    </TableCellData>
  )
}

OpenSlideoutCell.propTypes = {
  dataTip: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  title: PropTypes.string,
}

export default OpenSlideoutCell
