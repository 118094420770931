import React, { useState } from 'react'
import classnames from 'classnames'
import './IconButton.scss'

interface IconButtonProps {
  onClick?: React.MouseEventHandler<HTMLButtonElement>
  type?: 'submit' | 'reset' | 'button'
  disabled?: boolean
  color?: 'green' | 'red' | 'blue' | 'dark-blue' | 'dark'
  icon: React.ReactNode
  hoverIcon?: React.ReactNode
  className?: string
  [key: string]: any
}

/**
 * Use `IconButton` when you want button functionality with an svg icon. Use the hoverIcon prop to toggle (i.e. a 'solid' and 'outline' svg icon) on hover.
 */
const IconButton = ({
  onClick,
  type = 'button',
  disabled = false,
  color,
  icon,
  hoverIcon,
  className,
  rest,
}: IconButtonProps) => {
  const [isHovered, setIsHovered] = useState(false)
  if (!hoverIcon) {
    return (
      <button
        type={type}
        onClick={onClick}
        disabled={disabled}
        className={classnames('v3-icon-button', color, className)}
        {...rest}
      >
        {icon}
      </button>
    )
  }
  return (
    <button
      type={type}
      onClick={onClick}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      disabled={disabled}
      className={classnames('v3-icon-button', { color, className })}
      {...rest}
    >
      {hoverIcon && isHovered && !disabled ? hoverIcon : icon}
    </button>
  )
}

export default IconButton
