import { Suspense } from 'react'
import { useAppDispatch, useAppSelector } from 'hooks'
import 'i18n'
import { Loading } from 'components/common'
import PortalContainer from './utils/PortalContainer'
import { dismissToast } from 'actions/Dashboard'
import { ToastAlert } from 'components/common'
import { Route, Switch } from 'react-router-dom'
import PublicRoutes from './PublicRoutes/PublicRoutes'
import ProtectedRoutes from './ProtectedRoutes'
import './App.scss'

export const LoadingView = () => (
  <PortalContainer>
    <Loading isLoading className="global-loader" />
  </PortalContainer>
)

function App() {
  const { showToast, toastProps } = useAppSelector((state) => ({
    showToast: state.Dashboard.showToast,
    toastProps: state.Dashboard.toastProps,
  }))
  const dispatch = useAppDispatch()

  return (
    <Suspense fallback={<LoadingView />}>
      <Switch>
        <Route path={['/welcome', '/sign-up']} component={PublicRoutes} />
        <Route component={ProtectedRoutes} />
      </Switch>
      {showToast && (
        <ToastAlert {...toastProps} onClose={() => dispatch(dismissToast())} />
      )}
    </Suspense>
  )
}

export default App
