import { AnyAction } from 'redux'
import {
  FETCH_OVERVIEW_TRENDS_SUCCESS,
  FETCH_OVERVIEW_ERROR,
  FETCHING_OVERVIEW_OBJECT,
  RESET_OVERVIEW_STATE,
} from '../../actions/Report/Overview'

interface DomainScoreType {
  current_score: number
  previous_score: number
  trend: number
}

type DomainType =
  | 'data_loss'
  | 'defensibility'
  | 'dns_security'
  | 'email_security'
  | 'governance'
  | 'software_patching'
  | 'system_hosting'
  | 'threat_intell'
  | 'web_app_security'
  | 'web_encryption'
  | 'network_filtering'

export interface AnalysisType {
  analysis_id: string
  date_updated: string
  domain_scores: {
    [key in DomainType]: DomainScoreType
    // data_loss: DomainScoreType
    // defensibility: DomainScoreType
    // dns_security: DomainScoreType
    // email_security: DomainScoreType
    // governance: DomainScoreType
    // software_patching: DomainScoreType
    // system_hosting: DomainScoreType
    // threat_intell: DomainScoreType
    // web_app_security: DomainScoreType
    // web_encryption: DomainScoreType
  }
  issue_count: number
  overall_rating_numeric: string
  trend: number
}

export interface DomainRatingsType {
  domain: DomainType
  issue_count: number
  rating: number
  trend: number
}

interface OverviewState {
  overviewTrends: {
    analysis_id: string
    date_updated: string
    issue_count: number
    overall_rating_numeric: string
    _embedded: {
      'rel:analysis': AnalysisType[]
      'rel:domain_ratings': DomainRatingsType[]
    }
    _links: {
      self: {
        href: string
      }
    }
  }
  analysisTrendsWithDataLoss: AnalysisType[]
  loadingError: boolean
  fetchingSummaryInfo: boolean
  fetchingOverviewTrends: boolean
  fetchedSummaryInfo: boolean
  fetchedOverviewTrends: boolean
}

const initialState = {
  overviewTrends: {
    analysis_id: '',
    date_updated: '',
    issue_count: 0,
    overall_rating_numeric: '',
    _embedded: {
      'rel:analysis': [],
      'rel:domain_ratings': [],
    },
    _links: {
      self: {
        href: '',
      },
    },
  },
  analysisTrendsWithDataLoss: [],
  loadingError: false,
  fetchingSummaryInfo: true,
  fetchingOverviewTrends: true,
  fetchedSummaryInfo: false,
  fetchedOverviewTrends: false,
}

function Overview(
  state: OverviewState = initialState,
  action: AnyAction,
): OverviewState {
  switch (action.type) {
    case 'RESET_APP_STATE':
    case 'RESET_REPORT_STATE':
    case RESET_OVERVIEW_STATE:
      return initialState
    case FETCH_OVERVIEW_TRENDS_SUCCESS:
      return {
        ...state,
        overviewTrends: action.overviewTrends,
        analysisTrendsWithDataLoss: action.analysisTrendsWithDataLoss,
        fetchingOverviewTrends: false,
        fetchedOverviewTrends: true,
      }
    case FETCH_OVERVIEW_ERROR:
      return Object.assign({}, state, {
        loadingError: true,
      })
    case FETCHING_OVERVIEW_OBJECT:
      return Object.assign({}, state, {
        [action.fetchingVariable]: true,
      })
    default:
      return state
  }
}

export default Overview
