export const actionPlanPdf = 'actionPlanPdf'
export const actionPlanXlsx = 'actionPlanXlsx'
export const reportPdf = 'reportPdf'

export const DOWNLOADING_DOCUMENT_HEADER = 'Your Document is Being Generated'
export const DOWNLOADING_DOCUMENT_BUTTON_TEXT = 'OK'
export const ACTION_PLAN_PDF_CUSTOM_MESSAGE = 'an Action Plan PDF'
export const ACTION_PLAN_XLSX_CUSTOM_MESSAGE = 'an Action Plan Spreadsheet'
export const REPORT_DETAILED_PDF_CUSTOM_MESSAGE = 'a detailed PDF Report'
export const REPORT_SUMMARY_PDF_CUSTOM_MESSAGE = 'a summary PDF Report'
