import React from 'react'
import PropTypes from 'prop-types'

const ArrowLeftSVG = ({ size, ...rest }) => (
  <svg
    {...rest}
    width={size}
    height={size}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path fill="none" d="M0 0h24v24H0V0z" />
    <path
      transform="translate(0,8)"
      d="M3.88124 3.00393H14.8912C15.4412 3.00393 15.8912 3.45393 15.8912 4.00393C15.8912 4.55393 15.4412 5.00393 14.8912 5.00393H3.88124V6.79393C3.88124 7.24393 3.34124 7.46393 3.03124 7.14393L0.251235 4.35393C0.0612354 4.15393 0.0612354 3.84393 0.251235 3.64393L3.03124 0.85393C3.34124 0.53393 3.88124 0.76393 3.88124 1.20393V3.00393Z"
      fill="#EB5757"
    />
  </svg>
)

ArrowLeftSVG.defaultProps = {
  size: '24',
}

ArrowLeftSVG.propTypes = {
  size: PropTypes.string.isRequired,
}

export default ArrowLeftSVG
