import TopNavPublic from '../TopNav/TopNavPublic'
import email_icon from 'assets/images/email_icon.svg'
import logomark from 'assets/images/logomark.svg'
import './PublicPageContainer.scss'
import { useTranslation } from 'react-i18next'

function PublicPageContainer({ children }: { children: React.ReactNode }) {
  const { t } = useTranslation('pub', {
    keyPrefix: 'PublicRoutes.container',
  })
  return (
    <div id="portal-container">
      <TopNavPublic />
      {children}
      <div className="welcome-footer">
        <div className="footer-container">
          <img src={logomark} alt="mastercard-logo" className="mc-logo" />
          <div className="need-help">{t('needHelp')}</div>
          <a href="mailto:support@riskrecon.com" className="contact-group">
            <img src={email_icon} alt="mail" />
            <div>{t('getInTouch')}</div>
          </a>
          <hr />
          <div className="copyright">{`© 2024 Mastercard. ${t('footer')}`}</div>
          <div className="provided-by-group">
            <div>{t('providedBy')}</div>
            <img src={logomark} alt="mastercard-logo" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default PublicPageContainer
