import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import './Checkbox.scss'

const Checkbox = ({ id = '', checked, onChange, disabled }) => {
  return (
    <div className={classnames('checkbox-wrapper')}>
      <label>
        <input
          type="checkbox"
          checked={checked}
          onChange={onChange}
          id={id}
          disabled={disabled}
        />
      </label>
    </div>
  )
}

Checkbox.defaultProps = {
  // placeholderText: 'Unspecified',
}

Checkbox.propTypes = {
  checked: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  onChange: PropTypes.func.isRequired,
}

export default Checkbox
