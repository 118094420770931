import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Mercator } from '@vx/geo'
import { useTooltip, TooltipWithBounds, defaultStyles } from '@vx/tooltip'
import { localPoint } from '@vx/event'
import { scaleQuantize } from '@vx/scale'
import * as topojson from 'topojson-client'
import topology from 'world-topo.json'
import './WorldMap.scss'

const WorldMap = ({ width, data }) => {
  const height = width / 2
  const centerX = width / 2
  const centerY = height / 2
  const scale = (width / 630) * 100
  const world = topojson.feature(topology, topology.objects.units)

  const {
    tooltipData,
    tooltipLeft,
    tooltipTop,
    tooltipOpen,
    showTooltip,
    hideTooltip,
  } = useTooltip()

  const countries = useMemo(
    () => data.reduce((acc, curr) => ({ ...acc, [curr.c1]: curr.c2 }), {}),
    [data],
  )
  const highestIssueCount = useMemo(
    () => Math.max(...data.map((v) => v.c2)),
    [data],
  )
  const lowestIssueCount = useMemo(
    () => Math.min(...data.map((v) => v.c2)),
    [data],
  )

  const getColorBasedOnCount = scaleQuantize({
    domain: [lowestIssueCount, highestIssueCount],
    range: ['#D4E5F0', '#B5CDE3', '#435A70', '#173A56', '#0E2435'],
  })

  const getColor = (feature) => {
    if (!countries[feature.properties.name]) return '#E3EBF2'
    return getColorBasedOnCount(countries[feature.properties.name])
  }

  const handleMouseOver =
    ({ properties: { name } }) =>
    (e) => {
      const dataValue = countries[name]

      if (!dataValue) return

      const coords = localPoint(e.target.ownerSVGElement, e)

      showTooltip({
        tooltipLeft: coords.x,
        tooltipTop: coords.y,
        tooltipData: {
          country: name,
          value: dataValue,
        },
      })
    }

  return (
    <div style={{ width: `${width}px` }} className="v3-world-map-container">
      <svg width={width} height={height}>
        <Mercator
          data={world.features}
          scale={scale}
          translate={[centerX, centerY + 50]}
        >
          {(mercator) => {
            return (
              <g>
                {(mercator.features || []).map(({ feature: f }, i) => {
                  return (
                    <path
                      key={`map-feature-${i}`}
                      d={mercator.path(f)}
                      fill={getColor(f)}
                      stroke={'#FFF'}
                      strokeWidth={0.5}
                      onMouseOver={handleMouseOver(f)}
                      onMouseOut={hideTooltip}
                    />
                  )
                })}
              </g>
            )
          }}
        </Mercator>
      </svg>
      {tooltipOpen && (
        <TooltipWithBounds
          key={Math.random()}
          className="world-map-tooltip-container"
          top={tooltipTop}
          left={tooltipLeft}
          style={{
            padding: '5px 20px',
            boxShadow: '0px 5px 14px rgba(14, 36, 53, 0.08)',
            backgroundColor: '#fff',
            borderRadius: '4px',
            border: '1px solid rgba(87, 117, 146, 0.25)',
            ...defaultStyles,
          }}
        >
          <>
            <div className="country">{tooltipData.country}</div>
            <div className="value">{tooltipData.value} systems</div>
          </>
        </TooltipWithBounds>
      )}
    </div>
  )
}

WorldMap.defaultProps = {
  width: 750,
  data: [],
}

WorldMap.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  width: PropTypes.number.isRequired,
}

export default WorldMap
