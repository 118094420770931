import { useAppDispatch } from 'hooks'
import MyCyberRisk from './MyCyberRisk'
import { useEffect, useState } from 'react'
import { fetchCurrentUserInfo } from 'actions/CurrentUser'
import { LoadingView } from './App'

function ProtectedRoutes() {
  const dispatch = useAppDispatch()
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    const getUserInfo = async () => {
      await dispatch(fetchCurrentUserInfo())
      setLoaded(true)
    }
    getUserInfo()
  }, [dispatch])

  if (!loaded) {
    return <LoadingView />
  }

  return <MyCyberRisk />
}

export default ProtectedRoutes
