import axios from 'axios'
import {
  domainCardKeys,
  domainCardKeyMappings,
} from '../../constants/Report/ActionPlan'
import { criteriaValues } from '../../constants/Report/SecurityProfile'

export const LOAD_CRITERIA_FINDINGS_ERROR = 'LOAD_CRITERIA_FINDINGS_ERROR'
export const FETCH_DOMAIN_FINDINGS_SUCCESS = 'FETCH_DOMAIN_FINDINGS_SUCCESS'
export const FETCH_DOMAIN_FINDINGS_SUCCESS_NEW_ENDPOINT =
  'FETCH_DOMAIN_FINDINGS_SUCCESS_NEW_ENDPOINT'
export const FETCHING_DOMAIN_FINDINGS = 'FETCHING_DOMAIN_FINDINGS'
export const FETCH_CRITERIA_FINDINGS_SUCCESS_NEW_ENDPOINT =
  'FETCH_CRITERIA_FINDINGS_SUCCESS_NEW_ENDPOINT'
export const FETCHING_CRITERIA_FINDINGS = 'FETCHING_CRITERIA_FINDINGS'
export const FETCHING_AP_SUMMARY_INFO = 'FETCHING_AP_SUMMARY_INFO'
export const FETCH_SUMMARY_INFO_SUCCESS = 'FETCH_SUMMARY_INFO_SUCCESS'
export const RESET_ACTION_PLAN_STATE = 'RESET_ACTION_PLAN_STATE'

function fetchSummaryInfoSuccess(summaryInfo) {
  return { type: FETCH_SUMMARY_INFO_SUCCESS, summaryInfo }
}

function fetchingCriteriaFindings(criteria) {
  return { type: FETCHING_CRITERIA_FINDINGS, criteria }
}

function fetchingAPSummaryInfo() {
  return { type: FETCHING_AP_SUMMARY_INFO }
}

export function fetchCriteriaFindingsSuccess(
  response,
  domain,
  criteria,
  page = 1,
) {
  return {
    type: FETCH_CRITERIA_FINDINGS_SUCCESS_NEW_ENDPOINT,
    domain,
    criteria,
    page,
    findings: response.data.data,
    totalFindings: response.data.meta.total_count || 0,
  }
}

function fetchingDomainFindings(domain) {
  return { type: FETCHING_DOMAIN_FINDINGS, domain }
}

export function fetchDomainFindingsSuccess(response, domain, page = 1) {
  return {
    type: FETCH_DOMAIN_FINDINGS_SUCCESS_NEW_ENDPOINT,
    domain,
    page,
    findings: response.data.data,
    totalFindings: response.data.meta.total_count || 0,
  }
}

export function loadCriteriaFindingsError(_error, criteria) {
  return { type: LOAD_CRITERIA_FINDINGS_ERROR, criteria }
}

export const resetActionPlanState = () => (dispatch) =>
  dispatch({ type: RESET_ACTION_PLAN_STATE })

export function fetchCriteriaFindings(
  domain,
  analysisId,
  criteria,
  page = 1,
  sortColumn = 'priority',
  sortDirection = 'asc',
  itemsPerPage = 10,
  inActionPlan = true,
) {
  return async (dispatch) => {
    dispatch(fetchingCriteriaFindings(criteria))

    const expression_attributes = criteriaValues[criteria] || []
    expression_attributes.push(
      'finding_data_value',
      'finding_id',
      'analysis_id',
      'first_seen',
      'last_seen',
      'security_criteria_key',
      'security_domain_key',
      'customer_finding_status',
      'customer_due_date',
      'customer_marked_false_positive',
      'customer_marked_compensating_control',
      'is_shared',
      'security_criteria_key',
      'security_domain_key',
      'host_name',
      'ip_address',
      'finding_detail',
      'is_in_action_plan',
    )

    let success
    try {
      success = await axios.post(
        `/v2/analyses/${analysisId}/findings?per_page=${itemsPerPage}&page=${page}&sort_attribute=${sortColumn}&sort_method=${sortDirection}&action_plan=${inActionPlan}`,
        { security_criteria: [criteria] },
      )

      // append analysis ID, and security criteria to all the findings so the sidebar is still happy
      success.data.data.forEach(function (finding) {
        finding.attributes.analysis_id = analysisId
        finding.attributes.security_criteria = criteria

        if (criteria === 'email_encryption_enabled') {
          finding.attributes.domains_impacted = finding.attributes
            .additional_info
            ? finding.attributes.additional_info.length
            : null
          finding.attributes.example_domain = finding.attributes.additional_info
            ? finding.attributes.additional_info.sort()[0]
            : null
        }

        if (criteria === 'web_encryption_subject') {
          finding.attributes.finding_data_value =
            finding.attributes.finding_data_value.match(/CN=([^/]*)/)?.[1] || ''
        }
      })
      dispatch(fetchCriteriaFindingsSuccess(success, domain, criteria, page))
    } catch (error) {
      dispatch(loadCriteriaFindingsError(error, criteria))
    }
  }
}

export function fetchDomainFindings(
  domain,
  analysisID,
  page = 1,
  sortColumn = 'priority',
  sortDirection = 'asc',
  itemsPerPage = 10,
  inActionPlan = true,
) {
  // const from = (page * itemsPerPage) - itemsPerPage;
  return async (dispatch) => {
    dispatch(fetchingDomainFindings(domain))

    const expression_attributes = [
      'finding_id',
      'analysis_id',
      'first_seen',
      'last_seen',
      'days_open',
      'security_criteria_key',
      'security_domain_key',
      'customer_finding_status',
      'customer_due_date',
      'customer_marked_false_positive',
      'customer_marked_compensating_control',
      'is_shared',
      'security_criteria_key',
      'security_domain_key',
      'host_name',
      'ip_address',
      'finding_detail',
      'asset_value',
      'asset_value_numeric',
      'priority',
      'severity',
      'severity_numeric',
      'finding_data_value',
      'finding_extra_data_value',
      'finding_data_description',
      'finding_detail',
      'shared_ip',
      'criteria_issue',
      'domain_name',
      'is_in_action_plan',
    ]

    let success
    try {
      success = await axios.post(
        `/v2/analyses/${analysisID}/findings?per_page=${itemsPerPage}&page=${page}&sort_attribute=${sortColumn}&sort_method=${sortDirection}&action_plan=${inActionPlan}`,
        {
          security_domain: [domain],
          expression_attributes: expression_attributes,
        },
      )

      // append security criteria to all the findings so the sidebar is still happy
      success.data.data.forEach(function (finding) {
        finding.attributes.security_criteria =
          finding.attributes.security_criteria_key

        if (finding.attributes.security_criteria === 'web_encryption_subject') {
          finding.attributes.finding_data_value =
            finding.attributes.finding_data_value.match(/CN=([^/]*)/)?.[1] || ''
        }
      })

      dispatch(fetchDomainFindingsSuccess(success, domain, page))
    } catch (error) {
      // dispatch(fetchError(error));
    }
  }
}

export function fetchAllDomainFindingsMCR(analysisID) {
  return async (dispatch) => {
    domainCardKeys.forEach((domainCriteriaKey) => {
      const { domain, criteria } = domainCardKeyMappings[domainCriteriaKey]
      const inActionPlan = true
      if (criteria) {
        dispatch(
          fetchCriteriaFindings(
            domain,
            analysisID,
            criteria,
            undefined,
            undefined,
            undefined,
            undefined,
            inActionPlan,
          ),
        )
      } else {
        dispatch(
          fetchDomainFindings(
            domain,
            analysisID,
            undefined,
            undefined,
            undefined,
            undefined,
            inActionPlan,
          ),
        )
      }
    })
  }
}

export function fetchSummaryInfo(analysisID) {
  return async (dispatch) => {
    dispatch(fetchingAPSummaryInfo())
    try {
      const success = await axios.get(
        `/v2/action_plan/summary_info?analysis_id=${analysisID}`,
      )
      dispatch(fetchSummaryInfoSuccess(success.data))
    } catch (error) {
      console.log(error)
      // dispatch(fetchError(error));
    }
  }
}
