import React from 'react'
import { Container, Header } from 'components/common'
import { getDefinition } from 'mcr_constants.js'
import './GlossaryOfTerms.scss'
import { useTranslation } from 'react-i18next'

const GlossaryOfTerms = () => {
  const { t } = useTranslation(undefined, { keyPrefix: 'GlossaryOfTerms' })
  return (
    <div id="glossary-of-terms">
      <Container>
        <Header>{t('glossaryTitle')}</Header>
        <div>
          <p>
            <span className="term">{t('application server title')}</span>:{' '}
            {t('application server desc')}
          </p>
          <p>
            <span className="term">{t('asset value title')}</span>:{' '}
            {t('asset value desc')}
          </p>
          <p>
            <span className="term">{t('botnet title')}</span>:{' '}
            {t('botnet desc')}
          </p>
          <p>
            <span className="term">{t('certificate subjects title')}</span>:{' '}
            {t('certificate subjects desc')}
          </p>
          <p>
            <span className="term">{t('certificate valid date title')}</span>:{' '}
            {t('certificate valid date desc')}
          </p>
          <p>
            <span className="term">
              {t('command and control servers title')}
            </span>
            : {t('command and control servers desc')}
          </p>
          <p>
            <span className="term">{t('content management system title')}</span>
            : {t('content management system desc')}
          </p>
          <p>
            <span className="term">{t('cookies title')}</span>:{' '}
            {t('cookies desc')}
          </p>
          <p>
            <span className="term">{t('cross-site scripting title')}</span>:{' '}
            {t('cross-site scripting desc')}
          </p>
          <p>
            <span className="term">{t('dkim title')}</span>: {t('dkim desc')}
          </p>
          <p>
            <span className="term">{t('dmarc title')}</span>: {t('dmarc desc')}
          </p>
          <p>
            <span className="term">{t('dns title')}</span>: {t('dns desc')}
          </p>
          <p>
            <span className="term">{t('domain title')}</span>:{' '}
            {t('domain desc')}
          </p>
          <p>
            <span className="term">{t('domain hijacking title')}</span>:{' '}
            {t('domain hijacking desc')}
          </p>
          <p>
            <span className="term">{t('email provider title')}</span>:{' '}
            {t('email provider desc')}
          </p>
          <p>
            <span className="term">{t('email server title')}</span>:{' '}
            {t('email server desc')}
          </p>
          <p>
            <span className="term">{t('encryption hash algorithm title')}</span>
            : {t('encryption hash algorithm desc')}
          </p>
          <p>
            <span className="term">{t('encryption protocols title')}</span>:{' '}
            {t('encryption protocols desc')}
          </p>
          <p>
            <span className="term">{t('end-of-life software title')}</span>:{' '}
            {t('end-of-life software desc')}
          </p>
          <p>
            <span className="term">{t('expired certificates title')}</span>:{' '}
            {t('expired certificates desc')}
          </p>
          <p>
            <span className="term">{t('hosting type title')}</span>:{' '}
            {t('hosting type desc')}
          </p>
          <p>
            <span className="term">{t('hostname title')}</span>:{' '}
            {getDefinition('hostname')}
          </p>
          <p>
            <span className="term">{t('http security headers title')}</span>:{' '}
            {t('http security headers desc')}
          </p>
          <p>
            <span className="term">{t('netblocks title')}</span>:{' '}
            {t('netblocks desc')}
          </p>
          <p>
            <span className="term">{t('open-source intelligence title')}</span>:{' '}
            {t('open-source intelligence desc')}
          </p>
          <p>
            <span className="term">{t('open_ssl title')}</span>:{' '}
            {t('open_ssl desc')}
          </p>
          <p>
            <span className="term">{t('patching title')}</span>:{' '}
            {t('patching desc')}
          </p>
          <p>
            <span className="term">{t('registered domains title')}</span>:{' '}
            {t('registered domains desc')}
          </p>
          <p>
            <span className="term">{t('robots.txt file title')}</span>:{' '}
            {t('robots.txt file desc')}
          </p>
          <p>
            <span className="term">{t('spf title')}</span>: {t('spf desc')}
          </p>
          <p>
            <span className="term">{t('sql injection title')}</span>:{' '}
            {t('sql injection desc')}
          </p>
          <p>
            <span className="term">{t('ssl title')}</span>: {t('ssl desc')}
          </p>
          <p>
            <span className="term">{t('starttls title')}</span>:{' '}
            {t('starttls desc')}
          </p>
          <p>
            <span className="term">{t('tls title')}</span>: {t('tls desc')}
          </p>
          <p>
            <span className="term">{t('two-factor authentication title')}</span>
            : {t('two-factor authentication desc')}
          </p>
        </div>
      </Container>
    </div>
  )
}

export default GlossaryOfTerms
