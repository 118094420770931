import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import './Card.scss'

// Cards are similar to Containers, but with hover/onClick events.

const Card = ({ children, onClick, className, ...rest }) => {
  return (
    <button
      className={classnames('reset v3-card', className)}
      onClick={onClick}
      {...rest}
    >
      {children}
    </button>
  )
}

Card.defaultProps = {
  // No default props
}

Card.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.array,
  ]),
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired,
}

export default Card
