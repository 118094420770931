import axios from 'axios'
import { infoOnlyDomains } from 'constants/Report'
import prop from 'ramda/src/prop'
import dropRepeatsWith from 'ramda/src/dropRepeatsWith'
import eqBy from 'ramda/src/eqBy'
import pipe from 'ramda/src/pipe'
import reverse from 'ramda/src/reverse'
import { format } from 'date-fns'
import { sortBy } from 'lodash'

export const FETCH_OVERVIEW_ERROR = 'FETCH_OVERVIEW_ERROR'
export const FETCHING_OVERVIEW_OBJECT = 'FETCHING_OVERVIEW_OBJECT'
export const FETCH_OVERVIEW_TRENDS_SUCCESS = 'FETCH_OVERVIEW_TRENDS_SUCCESS'
export const RESET_OVERVIEW_STATE = 'RESET_OVERVIEW_STATE'

export const resetOverviewState = () => (dispatch) =>
  dispatch({ type: RESET_OVERVIEW_STATE })

function fetchOverviewTrendsSuccess(response) {
  try {
    const processedOverviewTrends = Object.assign({}, response.data, {
      _embedded: Object.assign({}, response.data._embedded, {
        'rel:domain_ratings': response.data._embedded['rel:domain_ratings'].map(
          (domainRating) => {
            // modify info only security domains response to a standard.
            if (infoOnlyDomains.includes(domainRating.domain)) {
              domainRating.rating = -1
            }

            return Object.assign({}, domainRating)
          },
        ),
      }),
    })

    let analysesTrends = sortBy(
      processedOverviewTrends._embedded['rel:analyses'],
      function (analysisTrend) {
        return analysisTrend.date_updated
      },
    )

    const analysesTrendsNew = analysesTrends.map(function (analysisTrend) {
      // As per tradition the data we get back from the API will sometimes change format so we must normalize it
      const dateComponents = analysisTrend.date_updated.split(' ')
      let dateString
      if (dateComponents[2] === 'UTC') {
        // Firefox will barf on new Date if you give it a hyphen (-), so we must swap them for forward slashes
        dateString = analysisTrend.date_updated.replace(/-/g, '/')
      } else {
        dateString = `${dateComponents[0]}`.replace(/-/g, '/')
      }

      return Object.assign({}, analysisTrend, {
        date_updated: new Date(dateString).toJSON(),
      })
    })

    // dedupe same week analyses
    const dateString = (d) =>
      format(new Date(prop('date_updated', d)), 'w-yyyy')
    const dedupedAnalysesTrends = pipe(
      reverse,
      dropRepeatsWith(eqBy(dateString)),
      reverse,
    )(analysesTrendsNew)

    return {
      type: FETCH_OVERVIEW_TRENDS_SUCCESS,
      overviewTrends: processedOverviewTrends,
      received_at: Date.now(),
      analysisTrendsWithDataLoss: dedupedAnalysesTrends,
    }
  } catch (error) {
    console.log(error)
    // dispatch(fetchError(error))
  }
}

function fetchError(error) {
  console.error(error)
  return { type: FETCH_OVERVIEW_ERROR }
}

function fetchingObject(fetchingVariable) {
  return {
    type: FETCHING_OVERVIEW_OBJECT,
    fetchingVariable,
  }
}

export function fetchOverviewTrends(analysisId) {
  return async (dispatch, getState) => {
    try {
      dispatch(fetchingObject('fetchingOverviewTrends'))
      const success = await axios.get(
        `/v0/analysis/overview/analyses_trend/${analysisId}`,
      )
      dispatch(fetchOverviewTrendsSuccess(success))
    } catch (error) {
      dispatch(fetchError(error))
    }
  }
}
