import React, { useState, useEffect, useRef } from 'react'
import classnames from 'classnames'
import { getRatingType, toFixedExceptTen } from '../../../utils/functions'
import './RatingGauge.scss'

interface RatingGaugeProps {
  rating: string | number
  bgColor?: string
  className?: string
  subText?: string
  width?: number
  showNAInPlaceOfZero?: boolean
}

const RatingGauge = ({
  rating,
  bgColor = '#fff',
  className,
  subText = 'Info Only',
  width = 150,
  showNAInPlaceOfZero,
}: RatingGaugeProps) => {
  const [gaugeValue, setGaugeValue] = useState(120)
  const [pointerRotation, setPointerRotation] = useState(0)
  const circleRef = useRef<SVGCircleElement>(null)
  const ratingIsNumber = rating !== -1 && typeof rating === 'number'

  useEffect(() => {
    // circumference: 120 === Math.ceil(Math.PI * 2 * 19)
    if (ratingIsNumber) {
      setGaugeValue(120 - (rating * 120) / 10)
      setPointerRotation((rating * 360) / 10)
    }
  }, [rating, ratingIsNumber])
  const ratingType = getRatingType(rating)

  useEffect(() => {
    // this is here because safari sucks
    if (circleRef.current) {
      circleRef.current.style.transform = `rotate(${
        pointerRotation === 360
          ? 354
          : pointerRotation === 0
          ? 6
          : pointerRotation
      }deg)`
    }
  }, [pointerRotation])

  const shouldHidePointer = pointerRotation === 0 && showNAInPlaceOfZero
  return (
    <div className={classnames('v3-rating-gauge', ratingType, className)}>
      <svg
        viewBox="0 0 42 42"
        width={width}
        transform="rotate(90)"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle className="base-ring" cx="21" cy="21" r="19" />
        <circle
          strokeDasharray="120 120"
          strokeDashoffset={gaugeValue}
          className="active-ring"
          cx="21"
          cy="21"
          r="19"
        />
        <g stroke={bgColor} className="tick-gaps" transform="translate(21, 21)">
          <line x1="0" y1="18" x2="0" y2="20" transform="rotate(54)"></line>
          <line x1="0" y1="18" x2="0" y2="20" transform="rotate(108)"></line>
          <line x1="0" y1="18" x2="0" y2="20" transform="rotate(162)"></line>
          <line x1="0" y1="18" x2="0" y2="20" transform="rotate(216)"></line>
          <line x1="0" y1="18" x2="0" y2="20" transform="rotate(270)"></line>
        </g>
        <g className="pointer" transform="translate(21, 21)">
          {/* <line x1="0" y1="0" x2="20" y2="0" transform='rotate(0)'></line> */}
          <circle
            ref={circleRef}
            stroke={shouldHidePointer ? 'transparent' : bgColor}
            style={{ fill: shouldHidePointer ? 'transparent' : undefined }}
            cx="19"
            cy="0"
            r="1.65"
            // safari no like dis, IE need dis
            transform={`rotate(${
              pointerRotation === 360
                ? 354
                : pointerRotation === 0
                ? 6
                : pointerRotation
            })`}
          />
        </g>
        <text
          className="rating-value"
          x="-50%"
          y={rating === 0 && showNAInPlaceOfZero ? '59%' : '53%'}
          textAnchor="middle"
          transform="rotate(-90)"
          data-testid="rating-value"
        >
          {ratingIsNumber && (rating > 0 || !showNAInPlaceOfZero)
            ? ratingType
            : 'N/A'}
        </text>
        <text
          className="rating-type"
          x="-50%"
          y="70%"
          textAnchor="middle"
          transform="rotate(-90)"
        >
          {ratingIsNumber && (rating !== 0 || !showNAInPlaceOfZero)
            ? `${toFixedExceptTen(rating)} / 10`
            : rating === 0 && showNAInPlaceOfZero
            ? ''
            : subText}
        </text>
      </svg>
    </div>
  )
}

export default RatingGauge
