import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import './RadioInput.scss'

/**
 * This is NOT fully fleshed out. I needed a basic setup for the single toe view.
 */
const RadioInput = ({
  value,
  onClick,
  className,
  disabled,
  id,
  name,
  checked,
  showHover,
  readOnly,
  ...rest
}) => {
  return (
    <label
      htmlFor={id}
      className={classnames(`v3-radio-input ${showHover ? 'hover' : ''}`)}
      // onClick={
      //   !readOnly
      //     ? () => {
      //         onClick(value)
      //       }
      //     : null
      // }
    >
      <input
        type="radio"
        disabled={disabled}
        id={id}
        value={value}
        name={name}
        checked={checked}
        onChange={onClick}
        readOnly={readOnly}
        {...rest}
      />
      <span className="visual"></span>
    </label>
  )
}

RadioInput.defaultProps = {
  value: '',
  id: '',
  className: '',
  name: '',
  showHover: false,
}

RadioInput.propTypes = {
  checked: PropTypes.bool.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  readOnly: PropTypes.any.isRequired,
  showHover: PropTypes.bool.isRequired,
  value: PropTypes.string.isRequired,
}

export default RadioInput
