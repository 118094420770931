import { stripDomain } from 'utils/TextHelpers.js'

export const criteriaTableColumns = {
  patching_app_server: [],
  patching_vuln_open_ssl: [],
  patching_web_cms: [],
  patching_web_server: [],
  web_encryption_date_expire: [],
  web_encryption_date_valid: [],
  web_encryption_hash: [],
  web_encryption_key_length: [],
  web_encryption_protocol: [],
  web_encryption_subject: [],
  threatintel_cc_server: [],
  threatintel_botnet_host: [],
  threatintel_hostile_host_hacking: [],
  threatintel_hostile_host_scanning: [],
  threatintel_phishing_site: [],
  threatintel_other: [],
  threatintel_spamming_host: [],
  config_web_cms_authentication: [],
  web_http_security_headers: [],
  malicious_code: [],
  web_threat_intel_alert_external: [],
  threat_intel_alert_external: [],
  dns_hosting_providers: [],
  dns_hijacking_protection: [],
  email_authentication: [],
  email_encryption_enabled: [],
  email_hosting_providers: [],
  attack_surface_web_hostname: [],
  attack_surface_web_ip: [],
  defensibility_hosting_providers: [],
  shared_hosting: [],
}

export const domainsRequireHostProfileInfo = [
  'software_patching',
  'web_encryption',
  'web_app_security',
]

export const domainMoreInformation = {
  software_patching: {
    description:
      'The Software Patching domain enumerates systems that are running end-of-life and vulnerable software. Because end-of-life software is not supported by the vendor, it cannot be patched against known security issues or new vulnerabilities that might be discovered, increasing likelihood of system compromise.  RiskRecon recommends addressing issues according to the assigned issue risk priority, determined based on the combination of issue severity and asset value. RiskRecon uses the CVSS rating as the severity for software patching issues.',
    control:
      'All systems are running patched, supported software to ensure that systems are not currently exposed to exploit of known vulnerability and to ensure that new vulnerabilities can be quickly addressed.',
    challenge:
      'Software vulnerabilities are frequently discovered, which malicious actors are aggressive to exploit. To help prevent compromise, software must be maintained such that it is free of known vulnerability.',
    capability:
      'RiskRecon identifies end-of-life software by examining the publicly accessible code and configurations of each system. RiskRecon measures software patching performance based on the percentage of end of life software observed relative to the total population of software discovered. RiskRecon does not do any active vulnerability probing or exploit tests.',
    action: [
      'Use software patching performance metrics and data to hold the organization accountable to strong software patching performance.',
      'Use software patching data to identify systems that require patching.',
    ],
  },
  web_app_security: {
    description:
      'The Application Security domain assesses each discovered web application for compliance with widely accepted application security practices that can be assessed using passive techniques. Consistent deployment of web application security controls appropriate for the risk context of the system is important to defend against application level attacks. It is also an indicator that the company has a robust web application security program. RiskRecon recommends addressing issues in the order of the risk priority shown for each issue.',
    control:
      'Web applications employ basic security mechanisms to help defend against unauthorized application access and provide safe user interaction.',
    challenge:
      'Bad actors target web applications and web application users to gain unauthorized access to sensitive data and application transaction capabilities.  Use of basic web application security mechanisms can help address user-targeted threats such as cross-site scripting (XSS) and phishing attacks.',
    capability:
      'RiskRecon analyzes each web server for implementation of basic web application security practices. RiskRecon operates all checks without submitting any input to the applications – no parameter tampering, no form field completion, no credential guessing, and so forth. ',
    action: [
      'Use of basic web application security mechanisms is a leading indicator of an effective web application security program. Significant failures in this area indicate a lack of consistent, effective web application security.',
    ],
  },
  web_encryption: {
    description:
      'RiskRecon used passive techniques to analyze web encryption security configurations. Correctly configured web encryption is essential to ensuring that communications are protected from eavesdropping and that people can verify the authenticity of the system. Addressing encryption issues also improves user experience, eliminating security alerts raised by the browser. RiskRecon strongly recommends first addressing all encryption issues in systems flagged as "high" value. These are systems RiskRecon observed to be collecting sensitive information.',
    control:
      'Communications are securely encrypted, enabling users to verify site authenticity and ensure communications privacy. Improperly configured web encryption can result in encryption error messages displayed to users. Improperly configured web encryption can break the encryption protocols, rending the encryption security control useless.',
    challenge:
      'Miscreants attempt to lure users to malicious sites by impersonating reputable systems. Properly configured encryption provides a strong method for users to validate site identity. Miscreants also attempt to intercept user communications. Strong encryption prevents intercepted communications from being compromised. ',
    capability:
      'For every web server discovered, RiskRecon examines the web encryption settings to assess secure configuration and proper operation. For each certificate, RiskRecon analyzes the encryption protocol strength, encryption hash strength, certificate subject validity and certificate expiration date. ',
    action: ['Identify and remediate system encryption issues. '],
  },
  threat_intell: {
    description:
      'The System Reputation domain enumerates systems owned by the company that are communicating with monitored C2 servers, sinkholes, honeypots, or are exhibiting other hostile activity. The presence of the organization’s assets in threat intelligence feeds is an indicator of lack of consistent and effective security controls deployed to all systems necessary to prevent malware infection and system abuse. Critical and high severity issues should be investigated on a priority basis. This domain shows all issues observed in the last 30 days. Caution: The system reputation domain can have false positives due, for example, to guest wireless networks.',
    control:
      'Systems are administered and controlled such that they do not emanate malicious activity such as serving malicious software, unauthorized vulnerability probing, or sending SPAM email. Properly administered and secured systems should not appear in threat intelligence alerts and blacklists.',
    challenge:
      'External actors frequently compromise systems for purposes of staging malicious activity, such as distributing malware, attacking other systems, and launching denial of service attacks.',
    capability:
      'RiskRecon reports systems that appear in public and proprietary threat intelligence alert feeds. RiskRecon collects millions of threat intelligence alerts daily from over 100 threat intelligence feeds. RiskRecon reports both current and historical threat intelligence alerts to provide visibility into system control capability over time.',
    action: [
      'Presence of systems in threat intelligence feeds, particularly occurring repeatedly over time, is a strong indicator of material gaps in the information security program. A small number of alerts over an extended time period should be expected for large organizations.',
    ],
  },
  data_loss: {
    description:
      'The Breach Event domain summarizes the breach events the organization has experienced. Recent breach events indicate gaps in the breach events protection program. Organizations with breach events occurring consistently over time very likely have ineffective breach prevention programs and material gaps in their information security program. Organizations with recent and also repeated breach events over time should be examined closely to ensure that controls are operating effectively to prevent future breach events.',
    control:
      'Data is controlled such that all access is appropriate and authorized.',
    challenge:
      "Sensitive data is subject to loss of control and unauthorized disclosure due to employee errors and also malicious internal and external actors attempting to steal the data. Malicious actors seek access to sensitive data for purposes such as harming an organization's reputation, gathering intelligence and profit. Employees may cause breach events simply due to errors such as sending email to the incorrect recipient, losing external media, or having their computer stolen.",
    capability:
      'RiskRecon reports on current and historical breach events by monitoring and researching breach alert feeds and breach event archives. ',
    action: [
      'Quickly investigate the impact of recent breach events. Scrutinize the security program where there are recent or frequent breach events. Current breach events indicate current gaps in the breach event protection program. Organizations with breach events occurring consistently over time very likely have ineffective breach event prevention and material gaps in their information security program.',
    ],
  },
  defensibility: {
    description:
      'The Defensibility domain measures the complexity of effectively protecting the organization’s public attack surface area. The two key measures of defensibility are the number of internet-facing systems and the degree of hosting consolidation. Organizations that consolidate system hosting with a few providers can concentrate security defense controls. Further, organizations with fewer internet-facing systems have a smaller footprint to defend against compromise. For organizations that have a high level of hosting fragmentation, the IT governance program should be examined to ensure that all the organization has effective control operations at each provider location.',
    control:
      'The  internet perimeter is defensible. Only necessary systems are deployed to the internet. All systems are hosted with authorized, secured networks. Use of IP addresses shared with other companies is limited to cases where it is absolutely necessary. ',
    challenge:
      'Without strong IT Governance controls, systems many unnecessarily deployed to the internet, increasing the attack surface. System hosting should be consolidated with authorized, secure providers to help ensure effective security operations.',
    capability:
      'Using proprietary deep web asset discovery algorithms, RiskRecon discovers hosts and related hosting infrastructure across the internet, regardless of hosting network, hosting provider or geography. RiskRecon does not conduct port scanning.',
    action: [
      'Measure the size of the internet presence.',
      'Measure the degree of fragmentation of system hosting – the percent of systems hosted externally and the number of hosting providers. Each hosting provider represents a unique internet point of presence that must be defended with extensive security controls such as network firewalls, application firewalls, and intrusion monitoring systems. ',
      'Identify and remediate hosts that use IP addresses shared with other organizations. Shared IP addresses should only be used where absolutely necessary.',
    ],
  },
  governance: {
    description:
      'The Security Governance domain assesses the organization’s degree of industry regulatory pressure and formal, public compliance with information security governance standards such as ISO 27001 and PCI. Information security programs subject to frequent, objective regulatory, customer, or compliance inspection at least are sufficient to pass objective review.',
    control:
      'The organization’s security program is properly governed to ensure complete, consistent implementation and operation of security controls. Organizations subject to external information security regulations and contractually enforced security requirements may implement required controls more consistently.',
    challenge:
      'Information security programs compete for scarce resources. Organizations may not properly fund information security without strong management support or external parties mandating security. ',
    capability:
      'RiskRecon assesses security governance based on publicly disclosed information security certifications based on inference of industry regulatory and customer security performance requirements. ',
    action: [
      'Use the RiskRecon governance assessment to estimate the maturity of the organization’s security governance program.',
    ],
  },
  system_hosting: {
    description:
      'The System Hosting domain provides insight into the Internet attack surface of the company, detailing the number of systems, the system hosting providers, and the system geolocations. How the organization has instantiated its internet presence is a driver of the complexity of managing IT security, privacy, and regulatory risk. In this domain, RiskRecon rates two criteria - the degree of system hosting fragmentation and the use of shared ip address hosting. All other criteria - hosting countries, hosting providers, and hostname surface, and domain name surface - are provided as information only. Detailed Internet attack surface information is available in the "IT Profile" tab.',
    control:
      'All systems are accounted for and managed within the IT governance and security program. All systems are hosted with approved providers and each hosting network has the necessary security control infrastructure and related operations. ',
    challenge:
      "Business units and individuals often bypass IT governance processes and deploy systems unknown to the security organization, creating a 'shadow IT' environment of systems that operates without organizational security governance and control.",
    capability:
      'Using proprietary deep web asset discovery algorithms, RiskRecon discovers hosts and related hosting infrastructure across the internet, regardless of hosting network, hosting provider or geography. RiskRecon does not conduct port scanning. ',
    action: [
      'Identify Shadow IT systems by identifying systems discovered by RiskRecon that are not under active IT and security management. Systems are enumerated in the Hosts data file.',
      'Identify hosting providers and ensure that each provider is authorized and has required security controls (See Hosts data file for details). ',
      'Validate that systems are hosted in authorized countries and cities using the data in the Hosts data file. ',
    ],
  },
  email_security: {
    description:
      'The Email Security domain analyzes the security configuration of email services. Email servers should be configured to encrypt email communications to protect email messages from unauthorized access. Domains should be configured to prove the authenticity of email messages to prevent spoofing.',
    control:
      'Email systems are configured and operated such that email communications are encrypted and that only authorized systems can send email messages. Email systems are operated in a manner that cannot be spoofed is authentic and communications are encrypted. Email systems are hosted in a manner that facilitates effective defense against email-based threats such as malware, phishing, spoofing, and data theft.',
    challenge:
      'Miscreants spoof email messages to exploit trusted relationships the organization has with its customers and stakeholders for purposes of perpetrating fraud and other malicious activities.  Bad actors also use email as a vector for delivering malware and conducting phishing attacks against the systems and personnel of the organization. ',
    capability:
      'RiskRecon assesses email security by discovering the organization’s email servers and analyzing the security configuration of each server. RiskRecon also determines the hosting provider of each email server.',
    action: [
      'Identify and remediate email domains that do not implement email authentication anti-spoofing controls.',
      'Identify and remediate email servers that do not implement email encryption.',
      'Identify the email hosting providers and validate that each provider has proper email security controls.',
    ],
  },
  dns_security: {
    description:
      'The DNS Security domain assesses the use of controls to prevent unauthorized modification of domain records resulting in domain hijacking. This domain also enumerates the DNS hosting providers to determine level of fragmentation. Control of DNS records is essential to keeping systems accessible. Where domain hijacking controls do not appear to be implemented, the organization should demonstrate compensating controls or implement the recommended domain protection settings.',
    control:
      'Domain name system records are secured such that the authorized owner maintains control over the records, ensuring that domain name services are properly operating and that they resolve to the authorized name services.',
    challenge:
      'Miscreants seek to modify domain registration records to cause system outage or direct systems to malicious sites where they can perpetrate schemes such as stealing sensitive information and compromising user systems. ',
    capability:
      'RiskRecon assesses domain security configuration of each domain record. RiskRecon also identifies the DNS provider of each domain. ',
    action: [
      'Use the DNS security information to assess the reliability of domain security based on domain record security configuration and concentration of DNS providers.',
    ],
  },
  network_filtering: {
    description:
      'The Network Filtering domain analyzes the company networks and systems for the presence of unsafe network services and IoT devices. Proper control of the services exposed to the Internet is a basic security practice, as unsafe network services and IoT devices are a common vector for compromising systems and networks. Enterprises should limit the systems and services exposed to the Internet to those that are safe and necessary.',
    control:
      'Internet-accessible systems and network services are strictly limited to those that are necessary and that have security controls sufficient to reasonably ensure confidentiality, integrity, and availability.',
    challenge:
      'Every Internet-facing system and its network services is constantly probed for vulnerabilities to exploit to gain unauthorized access to the system or degrade system performance. Deployment of unsafe and unnecessary network services increases the likelihood of experiencing a system compromise.',
    capability:
      'RiskRecon analyzes Internet-facing systems and networks for the following services: MS SQL Server, MySQL, PostgreSQL, MongoDB, Elastic, DB2, Redis, Memcached, CouchDB, Cassandara, Remote Desktop Protocol, VNC, Telnet, FTP, Samba, Finger, netBIOS, BGP, PPTP, X11, Oracle TNS, Apple Airport, Webmin. RiskRecon analyzes systems and networks to discover Internet of Things (IoT) devices, such as printers, elevator control systems, HVAC interfaces, cameras, and network storage devices.',
    action: [
      'Use the network filtering metrics to determine if the organization is effectively limiting access to systems and services.',
      'Use the network filtering data to identify Internet-facing systems and services that should be shutdown or filtered from public Internet access.',
    ],
  },
}

export const criteriaOrder = {
  software_patching: [
    'patching_app_server',
    'patching_openssl',
    'patching_web_cms',
    'patching_web_server',
  ],
  web_app_security: [
    'config_web_cms_authentication',
    'web_http_security_headers',
    'web_http_security_headers_old',
    'web_threat_intel_alert_external',
    'unencrypted_sensitive_systems',
    'malicious_code',
  ],
  web_encryption: [
    'web_encryption_date_expire',
    'web_encryption_date_valid',
    'web_encryption_hash',
    'web_encryption_key_length',
    'web_encryption_protocol',
    'web_encryption_subject',
  ],
  threat_intell: [
    'threatintel_cc_server',
    'threatintel_botnet_host',
    'threatintel_hostile_host_hacking',
    'threatintel_hostile_host_scanning',
    'threatintel_phishing_site',
    'threatintel_other',
    'threatintel_spamming_host',
  ],
  data_loss: [
    'data_loss_6',
    'data_loss_12',
    'data_loss_24',
    'data_loss_36',
    'data_loss_36plus',
  ],
  defensibility: [
    'attack_surface_web_hostname',
    'attack_surface_web_ip',
    'defensibility_hosting_providers',
    'shared_hosting',
  ],
  governance: [
    'governance_customer_base',
    'governance_regulatory_requirements',
    'governance_security_certifications',
  ],
  system_hosting: ['host_hosting_providers', 'hosting_countries'],
  email_security: [
    'email_authentication',
    'email_encryption_enabled',
    'email_hosting_providers',
  ],
  dns_security: ['dns_hosting_providers', 'dns_hijacking_protection'],
  network_filtering: ['unsafe_network_services', 'iot_devices'],
}

export const domainNotApplicationFields = {
  software_patching: [],
  web_app_security: ['observations', 'issue_count', 'issue_rate'],
  web_encryption: [],
  threat_intell: ['issue_rate'],
  data_loss: ['observations', 'issue_rate'],
  defensibility: ['observations', 'issue_rate'],
  governance: ['observations', 'issue_count', 'issue_rate'],
  system_hosting: ['observations', 'issue_count', 'issue_rate'],
  email_security: [],
  dns_security: [],
  network_filtering: ['observations', 'issue_rate'],
}

export const criteriaNotApplicationFields = {
  web_http_security_headers_old: [
    'issue_count',
    'issue_rate_percentage',
    'criteria_total_count',
  ],
  web_threat_intel_alert_external: [
    'issue_rate_percentage',
    'criteria_total_count',
  ],
  threat_intel_alert_external: [
    'issue_count',
    'issue_rate_percentage',
    'criteria_total_count',
  ],
  threatintel_cc_server: ['issue_rate_percentage'],
  threatintel_botnet_host: ['issue_rate_percentage'],
  threatintel_hostile_host_hacking: ['issue_rate_percentage'],
  threatintel_hostile_host_scanning: ['issue_rate_percentage'],
  threatintel_phishing_site: ['issue_rate_percentage'],
  threatintel_other: ['issue_rate_percentage'],
  threatintel_spamming_host: ['issue_rate_percentage'],
  data_loss_6: ['issue_rate_percentage'],
  data_loss_12: ['issue_rate_percentage'],
  data_loss_24: ['issue_rate_percentage'],
  data_loss_36: ['issue_rate_percentage'],
  data_loss_36plus: ['issue_rate_percentage'],
  attack_surface_web_hostname: [
    'issue_rate_percentage',
    'criteria_total_count',
  ],
  attack_surface_web_ip: ['issue_rate_percentage', 'criteria_total_count'],
  defensibility_hosting_providers: [
    'issue_rate_percentage',
    'criteria_total_count',
  ],
  email_hosting_providers: [
    'issue_count',
    'issue_rate_percentage',
    'criteria_total_count',
  ],
  dns_hosting_providers: [
    'issue_count',
    'issue_rate_percentage',
    'criteria_total_count',
  ],
  unsafe_network_services: ['issue_rate_percentage', 'criteria_total_count'],
  iot_devices: ['issue_rate_percentage', 'criteria_total_count'],
  unencrypted_sensitive_systems: ['security_criteria_rating_numeric'],
  malicious_code: ['criteria_total_count', 'issue_rate_percentage'],
}

const criteriaWithLastSeenC3 = [
  'config_web_cms_authentication',
  'email_authentication',
  'email_encryption_enabled',
  'dns_hijacking_protection',
]
const criteriaWithLastSeenC4 = [
  'web_encryption_hash',
  'web_encryption_protocol',
  'web_encryption_subject',
  'web_encryption_date_expire',
  'web_encryption_hash',
  'web_threat_intel_alert_external',
  'threat_intel_alert_external',
]
const criteriaWithLastSeenC5 = [
  'patching_app_server',
  'patching_vuln_open_ssl',
  'patching_web_cms',
  'patching_web_server',
  'shared_hosting',
]
export function getFindingHostName(finding) {
  const hostName =
    finding.host_name !== undefined
      ? finding.host_name
      : stripDomain(finding.finding_detail)
  return hostName
}

export function getFindingLastSeen(finding, criteria) {
  let lastSeen
  if (criteriaWithLastSeenC3.includes(criteria)) {
    lastSeen = finding.c3
  } else if (criteriaWithLastSeenC4.includes(criteria)) {
    lastSeen = finding.c4
  } else if (criteriaWithLastSeenC5.includes(criteria)) {
    lastSeen = finding.c5
  } else {
    lastSeen = finding.last_seen
  }

  return lastSeen
}

export const criteriaValues = {
  patching_app_server: [
    'finding_detail',
    'ip_address',
    'host_name',
    'first_seen',
    'days_open',
    'asset_value',
    'severity',
    'priority',
  ],
  patching_openssl: [
    'finding_detail',
    'ip_address',
    'host_name',
    'first_seen',
    'days_open',
    'asset_value',
    'severity',
    'priority',
  ],
  patching_web_cms: [
    'finding_detail',
    'ip_address',
    'host_name',
    'first_seen',
    'days_open',
    'asset_value',
    'severity',
    'priority',
  ],
  patching_vuln_open_ssl: [
    'finding_detail',
    'ip_address',
    'host_name',
    'first_seen',
    'days_open',
    'asset_value',
    'severity',
    'priority',
  ],
  patching_web_server: [
    'finding_detail',
    'ip_address',
    'host_name',
    'first_seen',
    'days_open',
    'asset_value',
    'severity',
    'priority',
  ],
  web_encryption_date_expire: [
    'host_name',
    'finding_data_value',
    'first_seen',
    'days_open',
    'asset_value',
    'severity',
    'priority',
  ],
  web_encryption_date_valid: [
    'host_name',
    'finding_data_value',
    'first_seen',
    'last_seen',
    'asset_value',
    'severity',
    'priority',
  ],
  web_encryption_hash: [
    'host_name',
    'finding_data_value',
    'first_seen',
    'days_open',
    'asset_value',
    'severity',
    'priority',
  ],
  web_encryption_key_length: [
    'host_name',
    'finding_data_value',
    'first_seen',
    'last_seen',
    'asset_value',
    'severity',
    'priority',
  ],
  web_encryption_protocol: [
    'host_name',
    'finding_extra_data_value',
    'finding_data_description',
    'first_seen',
    'days_open',
    'asset_value',
    'severity',
    'priority',
  ],
  web_encryption_subject: [
    'host_name',
    'finding_data_value',
    'first_seen',
    'days_open',
    'asset_value',
    'severity',
    'priority',
  ],
  threatintel_cc_server: [
    'ip_address',
    'host_name',
    'first_seen',
    'last_seen',
    'shared_ip',
    'asset_value',
    'severity',
    'priority',
  ],
  threatintel_botnet_host: [
    'ip_address',
    'host_name',
    'first_seen',
    'last_seen',
    'shared_ip',
    'asset_value',
    'severity',
    'priority',
  ],
  threatintel_hostile_host_hacking: [
    'ip_address',
    'host_name',
    'first_seen',
    'last_seen',
    'shared_ip',
    'asset_value',
    'severity',
    'priority',
  ],
  threatintel_hostile_host_scanning: [
    'ip_address',
    'host_name',
    'first_seen',
    'last_seen',
    'shared_ip',
    'asset_value',
    'severity',
    'priority',
  ],
  threatintel_phishing_site: [
    'ip_address',
    'host_name',
    'first_seen',
    'last_seen',
    'shared_ip',
    'asset_value',
    'severity',
    'priority',
  ],
  threatintel_other: [
    'ip_address',
    'host_name',
    'first_seen',
    'last_seen',
    'shared_ip',
    'asset_value',
    'severity',
    'priority',
  ],
  threatintel_spamming_host: [
    'ip_address',
    'host_name',
    'first_seen',
    'last_seen',
    'shared_ip',
    'asset_value',
    'severity',
    'priority',
  ],
  shared_hosting: [
    'ip_address',
    'host_name',
    'cotenant_count',
    'first_seen',
    'days_open',
    'asset_value',
    'severity',
    'priority',
  ],
  web_threat_intel_alert_external: [
    'ip_address',
    'host_name',
    'first_seen',
    'days_open',
  ],
  threat_intel_alert_external: [
    'ip_address',
    'host_name',
    'first_seen',
    'days_open',
  ],
  config_web_cms_authentication: [
    'finding_data_value',
    'first_seen',
    'days_open',
    'asset_value',
    'severity',
    'priority',
  ],
  email_authentication: [
    'domain_name',
    'first_seen',
    'days_open',
    'asset_value',
    'severity',
    'priority',
  ],
  email_encryption_enabled: [
    'host_name',
    'additional_info',
    'first_seen',
    'days_open',
    'asset_value',
    'severity',
    'priority',
  ],
  dns_hijacking_protection: [
    'domain_name',
    'first_seen',
    'days_open',
    'asset_value',
    'severity',
    'priority',
  ],
  unsafe_network_services: [
    'display_name',
    'ip_address',
    'host_name',
    'first_seen',
    'days_open',
    'asset_value',
    'severity',
    'priority',
  ],
  iot_devices: [
    'display_name',
    'ip_address',
    'host_name',
    'first_seen',
    'days_open',
    'asset_value',
    'severity',
    'priority',
  ],
  unencrypted_sensitive_systems: [
    'host_name',
    'days_open',
    'asset_value',
    'severity',
    'priority',
  ],
  web_http_security_headers: [
    'host_name',
    'finding_data_value',
    'first_seen',
    'days_open',
    'asset_value',
    'severity',
    'priority',
  ],
  malicious_code: [
    'finding_detail',
    'host_name',
    'first_seen',
    'last_seen',
    'asset_value',
    'severity',
    'priority',
  ],
}

export const criteriaTableValues = {
  patching_app_server: [
    'finding_detail',
    'ip_address',
    'host_name',
    'days_open',
    'asset_value',
    'severity',
    'priority',
  ],
  patching_openssl: [
    'finding_detail',
    'ip_address',
    'host_name',
    'days_open',
    'asset_value',
    'severity',
    'priority',
  ],
  patching_web_cms: [
    'finding_detail',
    'ip_address',
    'host_name',
    'days_open',
    'asset_value',
    'severity',
    'priority',
  ],
  patching_vuln_open_ssl: [
    'finding_detail',
    'ip_address',
    'host_name',
    'days_open',
    'asset_value',
    'severity',
    'priority',
  ],
  patching_web_server: [
    'finding_detail',
    'ip_address',
    'host_name',
    'days_open',
    'asset_value',
    'severity',
    'priority',
  ],
  web_encryption_date_expire: [
    'host_name',
    'finding_data_value',
    'days_open',
    'asset_value',
    'severity',
    'priority',
  ],
  web_encryption_date_valid: [
    'host_name',
    'finding_data_value',
    'last_seen',
    'asset_value',
    'severity',
    'priority',
  ],
  web_encryption_hash: [
    'host_name',
    'finding_data_value',
    'days_open',
    'asset_value',
    'severity',
    'priority',
  ],
  web_encryption_key_length: [
    'host_name',
    'finding_data_value',
    'last_seen',
    'asset_value',
    'severity',
    'priority',
  ],
  web_encryption_protocol: [
    'host_name',
    'finding_extra_data_value',
    'days_open',
    'asset_value',
    'severity',
    'priority',
  ],
  web_encryption_subject: [
    'host_name',
    'finding_data_value',
    'days_open',
    'asset_value',
    'severity',
    'priority',
  ],
  threatintel_cc_server: [
    'ip_address',
    'host_name',
    'last_seen',
    'shared_ip',
    'asset_value',
    'severity',
    'priority',
  ],
  threatintel_botnet_host: [
    'ip_address',
    'host_name',
    'last_seen',
    'shared_ip',
    'asset_value',
    'severity',
    'priority',
  ],
  threatintel_hostile_host_hacking: [
    'ip_address',
    'host_name',
    'last_seen',
    'shared_ip',
    'asset_value',
    'severity',
    'priority',
  ],
  threatintel_hostile_host_scanning: [
    'ip_address',
    'host_name',
    'last_seen',
    'shared_ip',
    'asset_value',
    'severity',
    'priority',
  ],
  threatintel_phishing_site: [
    'ip_address',
    'host_name',
    'last_seen',
    'shared_ip',
    'asset_value',
    'severity',
    'priority',
  ],
  threatintel_other: [
    'ip_address',
    'host_name',
    'last_seen',
    'shared_ip',
    'asset_value',
    'severity',
    'priority',
  ],
  threatintel_spamming_host: [
    'ip_address',
    'host_name',
    'last_seen',
    'shared_ip',
    'asset_value',
    'severity',
    'priority',
  ],
  shared_hosting: [
    'ip_address',
    'host_name',
    'cotenant_count',
    'days_open',
    'asset_value',
    'severity',
    'priority',
  ],
  web_threat_intel_alert_external: ['ip_address', 'host_name', 'days_open'],
  threat_intel_alert_external: ['ip_address', 'host_name', 'days_open'],
  config_web_cms_authentication: [
    'finding_data_value',
    'days_open',
    'asset_value',
    'severity',
    'priority',
  ],
  email_authentication: [
    'domain_name',
    'days_open',
    'asset_value',
    'severity',
    'priority',
  ],
  email_encryption_enabled: [
    'host_name',
    'domains_impacted',
    'example_domain',
    'days_open',
    'asset_value',
    'severity',
    'priority',
  ],
  dns_hijacking_protection: [
    'domain_name',
    'days_open',
    'asset_value',
    'severity',
    'priority',
  ],
  unsafe_network_services: [
    'finding_detail',
    'ip_address',
    'host_name',
    'days_open',
    'asset_value',
    'severity',
    'priority',
  ],
  iot_devices: [
    'finding_detail',
    'ip_address',
    'host_name',
    'days_open',
    'asset_value',
    'severity',
    'priority',
  ],
  unencrypted_sensitive_systems: [
    'host_name',
    'days_open',
    'asset_value',
    'severity',
    'priority',
  ],
  web_http_security_headers: [
    'host_name',
    'finding_data_value',
    'days_open',
    'asset_value',
    'severity',
    'priority',
  ],
  malicious_code: [
    'finding_detail',
    'host_name',
    'first_seen',
    'last_seen',
    'asset_value',
    'severity',
    'priority',
  ],
  malicious_code_evidence: [
    'evidence',
    'finding_extra_data_value_evidence',
    'finding_data_value_evidence',
  ],
}

export const criteriaColumns = {
  patching_app_server: [
    'software',
    'IP Address',
    'hostname',
    'days_open',
    'asset_value',
    'severity',
    'priority',
  ],
  patching_vuln_open_ssl: [
    'software',
    'IP Address',
    'hostname',
    'days_open',
    'asset_value',
    'severity',
    'priority',
  ],
  patching_openssl: [
    'software',
    'IP Address',
    'hostname',
    'days_open',
    'asset_value',
    'severity',
    'priority',
  ],
  patching_web_cms: [
    'software',
    'IP Address',
    'hostname',
    'days_open',
    'asset_value',
    'severity',
    'priority',
  ],
  patching_web_server: [
    'software',
    'IP Address',
    'hostname',
    'days_open',
    'asset_value',
    'severity',
    'priority',
  ],
  web_encryption_date_expire: [
    'hostname',
    'date_expired',
    'days_open',
    'asset_value',
    'severity',
    'priority',
  ],
  web_encryption_date_valid: [
    'hostname',
    'date',
    'last_seen',
    'asset_value',
    'severity',
    'priority',
  ],
  web_encryption_hash: [
    'hostname',
    'host_algorithm',
    'days_open',
    'asset_value',
    'severity',
    'priority',
  ],
  web_encryption_key_length: [
    'hostname',
    'date',
    'last_seen',
    'asset_value',
    'severity',
    'priority',
  ],
  web_encryption_protocol: [
    'hostname',
    'issue',
    'days_open',
    'asset_value',
    'severity',
    'priority',
  ],
  web_encryption_subject: [
    'hostname',
    'subject',
    'days_open',
    'asset_value',
    'severity',
    'priority',
  ],
  threatintel_cc_server: [
    'IP Address',
    'hostname',
    'last_seen',
    'Shared IP',
    'asset_value',
    'severity',
    'priority',
  ],
  threatintel_botnet_host: [
    'IP Address',
    'hostname',
    'last_seen',
    'Shared IP',
    'asset_value',
    'severity',
    'priority',
  ],
  threatintel_hostile_host_hacking: [
    'IP Address',
    'hostname',
    'last_seen',
    'Shared IP',
    'asset_value',
    'severity',
    'priority',
  ],
  threatintel_hostile_host_scanning: [
    'IP Address',
    'hostname',
    'last_seen',
    'Shared IP',
    'asset_value',
    'severity',
    'priority',
  ],
  threatintel_phishing_site: [
    'IP Address',
    'hostname',
    'last_seen',
    'Shared IP',
    'asset_value',
    'severity',
    'priority',
  ],
  threatintel_other: [
    'IP Address',
    'hostname',
    'last_seen',
    'Shared IP',
    'asset_value',
    'severity',
    'priority',
  ],
  threatintel_spamming_host: [
    'IP Address',
    'hostname',
    'last_seen',
    'Shared IP',
    'asset_value',
    'severity',
    'priority',
  ],
  config_web_cms_authentication: [
    'URL',
    'days_open',
    'asset_value',
    'severity',
    'priority',
  ],
  unencrypted_sensitive_systems: [
    'hostname',
    'days_open',
    'asset_value',
    'severity',
    'priority',
  ],
  web_http_security_headers: [
    'hostname',
    'Missing Headers',
    'days_open',
    'asset_value',
    'severity',
    'priority',
  ],
  malicious_code: [
    'issue',
    'hostname',
    'first_seen',
    'last_seen',
    'asset_value',
    'severity',
    'priority',
  ],
  web_http_security_headers_old: ['Settings'],
  web_threat_intel_alert_external: ['IP Address', 'hostname', 'days_open'],
  threat_intel_alert_external: ['IP Address', 'hostname', 'days_open'],
  dns_hosting_providers: ['Hosting Provider', 'Domains'],
  dns_hijacking_protection: [
    'domain',
    'days_open',
    'asset_value',
    'severity',
    'priority',
  ],
  email_authentication: [
    'email_domain',
    'days_open',
    'asset_value',
    'severity',
    'priority',
  ],
  email_encryption_enabled: [
    'email_server',
    'domains_impacted',
    'example_domain',
    'days_open',
    'asset_value',
    'severity',
    'priority',
  ],
  email_hosting_providers: ['Email Provider', 'Domains'],
  shared_hosting: [
    'IP Address',
    'hostname',
    'cotenant_count',
    'days_open',
    'asset_value',
    'severity',
    'priority',
  ],
  governance_customer_base: [],
  governance_regulatory_requirements: [],
  governance_security_certifications: [],
  attack_surface_web_ip: [],
  attack_surface_web_hostname: [],
  host_hosting_providers: ['Hosting Provider', 'Systems'],
  hosting_countries: ['Country Name', 'Hostname Count'],
  defensibility_hosting_providers: ['Hosting Provider', 'System'],
  data_loss_6: ['Description'],
  data_loss_12: ['Description'],
  data_loss_24: ['Description'],
  data_loss_36: ['Description'],
  data_loss_36plus: ['Description'],
  unsafe_network_services: [
    'Issue',
    'IP Address',
    'Hostname',
    'days_open',
    'asset_value',
    'severity',
    'priority',
  ],
  iot_devices: [
    'Issue',
    'IP Address',
    'hostname',
    'days_open',
    'asset_value',
    'severity',
    'priority',
  ],
}

export const useLegacyFindings = [
  'web_http_security_headers_old',
  'defensibility_hosting_providers',
  'host_hosting_providers',
  'hosting_countries',
  'email_hosting_providers',
  'data_loss_6',
  'data_loss_12',
  'data_loss_24',
  'data_loss_36',
  'data_loss_36plus',
  'attack_surface_web_hostname',
  'attack_surface_web_ip',
  'dns_hosting_providers',
]

export const legacySecurityHeadersDate = '2019-11-07'

export const columnsToExcludeSort = ['Missing Headers']
