export const hostSummaryContent = [
  {
    label: 'Hostname',
    valueKey: 'host_name',
    width: '50',
  },
  {
    label: 'IP Address',
    valueKey: 'ip_address',
    width: '50',
  },
  {
    label: 'Hosting Provider',
    valueKey: 'hosting_provider',
    width: '50',
  },
  {
    label: 'Hosting Type',
    valueKey: 'hosting_type',
    width: '50',
  },
]
export const hostProfileSections = [
  {
    label: 'Host Summary',
    key: 'hostSummary',
    content: hostSummaryContent,
    showMore: false,
  },
]
export const findingSidebarAttributes = {
  patching_app_server: {
    host_summary_attributes: {
      host_name: 'hostname',
      ip_address: 'IP Address',
      hosting_provider: 'hosting_provider',
    },
    issue_description_attributes: {
      finding_detail: 'issue',
      support_status: 'support_status',
      security_criteria_rating_numeric: 'vulnerability_rating',
      severity: 'severity',
      first_seen: 'date_first_seen',
      last_seen: 'date_last_seen',
      asset_value: 'asset_value',
      priority: 'risk_priority',
    },
    show_criteria_issues: false,
    show_vulnerability_info: true,
    issue_key: 'issue_short_vuln',
    issue_description: 'issue_long_vuln',
    solution: 'solution_short',
    show_issue_description: true,
  },
  patching_openssl: {
    host_summary_attributes: {
      host_name: 'hostname',
      ip_address: 'IP Address',
      hosting_provider: 'hosting_provider',
    },
    issue_description_attributes: {
      finding_detail: 'issue',
      support_status: 'support_status',
      security_criteria_rating_numeric: 'vulnerability_rating',
      severity: 'severity',
      first_seen: 'date_first_seen',
      last_seen: 'date_last_seen',
      asset_value: 'asset_value',
      priority: 'risk_priority',
    },
    show_criteria_issues: false,
    show_vulnerability_info: true,
    issue_key: 'issue_short_vuln',
    issue_description: 'issue_long_vuln',
    solution: 'solution_short',
    show_issue_description: true,
  },
  patching_vuln_open_ssl: {
    host_summary_attributes: {
      host_name: 'hostname',
      ip_address: 'IP Address',
      hosting_provider: 'hosting_provider',
    },
    issue_description_attributes: {
      finding_detail: 'issue',
      support_status: 'support_status',
      security_criteria_rating_numeric: 'vulnerability_rating',
      severity: 'severity',
      first_seen: 'date_first_seen',
      last_seen: 'date_last_seen',
      asset_value: 'asset_value',
      priority: 'risk_priority',
    },
    show_criteria_issues: false,
    show_vulnerability_info: true,
    issue_key: 'issue_short_vuln',
    issue_description: 'issue_long_vuln',
    solution: 'solution_short',
    show_issue_description: true,
  },
  patching_web_cms: {
    host_summary_attributes: {
      host_name: 'hostname',
      ip_address: 'IP Address',
      hosting_provider: 'hosting_provider',
    },
    issue_description_attributes: {
      finding_detail: 'issue',
      support_status: 'support_status',
      security_criteria_rating_numeric: 'vulnerability_rating',
      severity: 'severity',
      first_seen: 'date_first_seen',
      last_seen: 'date_last_seen',
      asset_value: 'asset_value',
      priority: 'risk_priority',
    },
    show_criteria_issues: false,
    show_vulnerability_info: true,
    issue_key: 'issue_short_eol',
    issue_description: 'issue_long_eol',
    solution: 'solution_short',
    show_issue_description: true,
  },
  patching_web_server: {
    host_summary_attributes: {
      host_name: 'hostname',
      ip_address: 'IP Address',
      hosting_provider: 'hosting_provider',
    },
    issue_description_attributes: {
      finding_detail: 'issue',
      support_status: 'support_status',
      security_criteria_rating_numeric: 'vulnerability_rating',
      severity: 'severity',
      first_seen: 'date_first_seen',
      last_seen: 'date_last_seen',
      asset_value: 'asset_value',
      priority: 'risk_priority',
    },
    show_criteria_issues: false,
    show_vulnerability_info: true,
    issue_key: 'issue_short_eol',
    issue_description: 'issue_long_eol',
    solution: 'solution_short',
    show_issue_description: true,
  },
  web_encryption_date_expire: {
    host_summary_attributes: {
      host_name: 'hostname',
      ip_address: 'IP Address',
      hosting_provider: 'hosting_provider',
    },
    issue_description_attributes: {
      finding_data_value: 'issue',
      first_seen: 'date_first_seen',
      last_seen: 'date_last_seen',
      severity: 'severity',
      asset_value: 'asset_value',
      priority: 'risk_priority',
    },
    show_criteria_issues: false,
    show_vulnerability_info: false,
    issue_key: 'issue_long_intro',
    issue_description: 'issue_long_vuln',
    solution: 'solution_long',
    show_issue_description: false,
  },
  web_encryption_date_valid: {
    host_summary_attributes: {
      host_name: 'hostname',
      ip_address: 'IP Address',
      hosting_provider: 'hosting_provider',
    },
    issue_description_attributes: {
      finding_data_value: 'issue',
      first_seen: 'date_first_seen',
      last_seen: 'date_last_seen',
      severity: 'severity',
      asset_value: 'asset_value',
      priority: 'risk_priority',
    },
    show_criteria_issues: false,
    show_vulnerability_info: false,
    issue_key: 'issue_long_intro',
    issue_description: 'issue_long_vuln',
    solution: 'solution_long',
    show_issue_description: false,
  },
  web_encryption_hash: {
    host_summary_attributes: {
      host_name: 'hostname',
      ip_address: 'IP Address',
      hosting_provider: 'hosting_provider',
    },
    issue_description_attributes: {
      finding_data_value: 'issue',
      first_seen: 'date_first_seen',
      last_seen: 'date_last_seen',
      severity: 'severity',
      asset_value: 'asset_value',
      priority: 'risk_priority',
    },
    show_criteria_issues: false,
    show_vulnerability_info: false,
    issue_key: 'issue_long_intro',
    issue_description: 'issue_long_vuln',
    solution: 'solution_long',
    show_issue_description: false,
  },
  web_encryption_key_length: {
    host_summary_attributes: {
      host_name: 'hostname',
      ip_address: 'IP Address',
      hosting_provider: 'hosting_provider',
    },
    issue_description_attributes: {
      finding_data_value: 'issue',
      first_seen: 'date_first_seen',
      last_seen: 'date_last_seen',
      severity: 'severity',
      asset_value: 'asset_value',
      priority: 'risk_priority',
    },
    show_criteria_issues: false,
    show_vulnerability_info: false,
    issue_key: 'issue_long_intro',
    issue_description: 'issue_long_vuln',
    solution: 'solution_long',
    show_issue_description: false,
  },
  web_encryption_protocol: {
    host_summary_attributes: {
      host_name: 'hostname',
      ip_address: 'IP Address',
      hosting_provider: 'hosting_provider',
    },
    issue_description_attributes: {
      finding_extra_data_value: 'issue',
      first_seen: 'date_first_seen',
      last_seen: 'date_last_seen',
      severity: 'severity',
      asset_value: 'asset_value',
      priority: 'risk_priority',
    },
    show_criteria_issues: false,
    show_vulnerability_info: false,
    issue_key: 'issue_long_intro',
    issue_description: 'issue_long_vuln',
    solution: 'solution_short',
    show_issue_description: false,
  },
  web_encryption_subject: {
    host_summary_attributes: {
      host_name: 'hostname',
      ip_address: 'IP Address',
      hosting_provider: 'hosting_provider',
    },
    issue_description_attributes: {
      finding_data_value: 'issue',
      first_seen: 'date_first_seen',
      last_seen: 'date_last_seen',
      severity: 'severity',
      asset_value: 'asset_value',
      priority: 'risk_priority',
    },
    show_criteria_issues: false,
    show_vulnerability_info: false,
    issue_key: 'issue_long_intro',
    issue_description: 'issue_long_vuln',
    solution: 'solution_short',
    show_issue_description: false,
  },
  threatintel_cc_server: {
    host_summary_attributes: {
      host_name: 'hostname',
      ip_address: 'IP Address',
      hosting_provider: 'hosting_provider',
    },
    issue_description_attributes: {
      host_name: 'hostname',
      ip_address: 'IP Address',
      asset_value: 'asset_value',
      shared_ip: 'Shared IP',
    },
    show_criteria_issues: false,
    show_vulnerability_info: false,
    issue_key: 'issue_short_vuln',
    issue_description: 'issue_long_vuln',
    solution: 'solution_short',
    show_issue_description: false,
  },
  threatintel_botnet_host: {
    host_summary_attributes: {
      host_name: 'hostname',
      ip_address: 'IP Address',
      hosting_provider: 'hosting_provider',
    },
    issue_description_attributes: {
      host_name: 'hostname',
      ip_address: 'IP Address',
      asset_value: 'asset_value',
      shared_ip: 'Shared IP',
    },
    show_criteria_issues: false,
    show_vulnerability_info: false,
    issue_key: 'issue_short_vuln',
    issue_description: 'issue_long_vuln',
    solution: 'solution_short',
    show_issue_description: false,
  },
  threatintel_hostile_host_hacking: {
    host_summary_attributes: {
      host_name: 'hostname',
      ip_address: 'IP Address',
      hosting_provider: 'hosting_provider',
    },
    issue_description_attributes: {
      host_name: 'hostname',
      ip_address: 'IP Address',
      asset_value: 'asset_value',
      shared_ip: 'Shared IP',
    },
    show_criteria_issues: false,
    show_vulnerability_info: false,
    issue_key: 'issue_short_vuln',
    issue_description: 'issue_long_vuln',
    solution: 'solution_short',
    show_issue_description: false,
  },
  threatintel_hostile_host_scanning: {
    host_summary_attributes: {
      host_name: 'hostname',
      ip_address: 'IP Address',
      hosting_provider: 'hosting_provider',
    },
    issue_description_attributes: {
      host_name: 'hostname',
      ip_address: 'IP Address',
      asset_value: 'asset_value',
      shared_ip: 'Shared IP',
    },
    show_criteria_issues: false,
    show_vulnerability_info: false,
    issue_key: 'issue_short_vuln',
    issue_description: 'issue_long_vuln',
    solution: 'solution_short',
    show_issue_description: false,
  },
  threatintel_phishing_site: {
    host_summary_attributes: {
      host_name: 'hostname',
      ip_address: 'IP Address',
      hosting_provider: 'hosting_provider',
    },
    issue_description_attributes: {
      host_name: 'hostname',
      ip_address: 'IP Address',
      asset_value: 'asset_value',
      shared_ip: 'Shared IP',
    },
    show_criteria_issues: false,
    show_vulnerability_info: false,
    issue_key: 'issue_short_vuln',
    issue_description: 'issue_long_vuln',
    solution: 'solution_short',
    show_issue_description: false,
  },
  threatintel_other: {
    host_summary_attributes: {
      host_name: 'hostname',
      ip_address: 'IP Address',
      hosting_provider: 'hosting_provider',
    },
    issue_description_attributes: {
      host_name: 'hostname',
      ip_address: 'IP Address',
      asset_value: 'asset_value',
      shared_ip: 'Shared IP',
    },
    show_criteria_issues: false,
    show_vulnerability_info: false,
    issue_key: 'issue_short_vuln',
    issue_description: 'issue_long_vuln',
    solution: 'solution_short',
    show_issue_description: false,
  },
  threatintel_spamming_host: {
    host_summary_attributes: {
      host_name: 'hostname',
      ip_address: 'IP Address',
      hosting_provider: 'hosting_provider',
    },
    issue_description_attributes: {
      host_name: 'hostname',
      ip_address: 'IP Address',
      asset_value: 'asset_value',
      shared_ip: 'Shared IP',
    },
    show_criteria_issues: false,
    show_vulnerability_info: false,
    issue_key: 'issue_short_vuln',
    issue_description: 'issue_long_vuln',
    solution: 'solution_short',
    show_issue_description: false,
  },
  shared_hosting: {
    host_summary_attributes: {
      host_name: 'hostname',
      ip_address: 'IP Address',
    },
    issue_description_attributes: {
      host_name: 'issue',
      first_seen: 'date_first_seen',
      last_seen: 'date_last_seen',
      severity: 'severity',
      asset_value: 'asset_value',
      priority: 'risk_priority',
    },
    show_criteria_issues: false,
    show_vulnerability_info: false,
    issue_key: 'issue_long_intro',
    issue_description: 'issue_long_vuln',
    solution: 'solution_long',
    show_issue_description: false,
  },
  config_web_cms_authentication: {
    host_summary_attributes: {
      host_name: 'hostname',
      ip_address: 'IP Address',
      hosting_provider: 'hosting_provider',
    },
    issue_description_attributes: {
      finding_detail: 'issue',
      first_seen: 'date_first_seen',
      last_seen: 'date_last_seen',
      severity: 'severity',
      asset_value: 'asset_value',
      priority: 'risk_priority',
    },
    show_criteria_issues: false,
    show_vulnerability_info: false,
    issue_key: 'issue_long_intro',
    issue_description: 'issue_long_vuln',
    solution: 'solution_short',
    show_issue_description: false,
  },
  web_http_security_headers: {
    host_summary_attributes: {
      host_name: 'hostname',
      ip_address: 'IP Address',
      hosting_provider: 'hosting_provider',
    },
    issue_description_attributes: {
      finding_detail: 'issue',
      first_seen: 'date_first_seen',
      last_seen: 'date_last_seen',
      severity: 'severity',
      asset_value: 'asset_value',
      priority: 'risk_priority',
    },
    show_criteria_issues: false,
    show_vulnerability_info: false,
    issue_key: 'issue_long_intro',
    issue_description: 'issue_long_vuln',
    solution: 'solution_short',
    show_issue_description: false,
  },
  malicious_code: {
    host_summary_attributes: {
      host_name: 'hostname',
      ip_address: 'IP Address',
    },
    issue_description_attributes: {
      finding_detail: 'issue',
      first_seen: 'date_first_seen',
      last_seen: 'date_last_seen',
      severity: 'severity',
      asset_value: 'asset_value',
      priority: 'risk_priority',
    },
    show_criteria_issues: false,
    show_vulnerability_info: false,
    issue_key: 'issue_long_intro',
    issue_description: 'issue_long_vuln',
    solution: 'solution_short',
    show_issue_description: false,
  },
  web_threat_intel_alert_external: {
    host_summary_attributes: {
      host_name: 'hostname',
      ip_address: 'IP Address',
    },
    issue_description_attributes: {
      host_name: 'issue',
      first_seen: 'date_first_seen',
      last_seen: 'date_last_seen',
    },
    show_criteria_issues: false,
    show_vulnerability_info: false,
    issue_key: 'issue_long_intro',
    issue_description: 'issue_long_vuln',
    solution: 'solution_long',
    show_issue_description: false,
  },
  dns_hijacking_protection: {
    host_summary_attributes: null,
    issue_description_attributes: {
      domain_name: 'issue',
      first_seen: 'date_first_seen',
      last_seen: 'date_last_seen',
      severity: 'severity',
      asset_value: 'asset_value',
      priority: 'risk_priority',
    },
    show_criteria_issues: false,
    show_vulnerability_info: false,
    issue_key: 'issue_long_intro',
    issue_description: 'issue_long_vuln',
    solution: 'solution_long',
    show_issue_description: false,
  },
  email_authentication: {
    host_summary_attributes: null,
    issue_description_attributes: {
      domain_name: 'issue',
      first_seen: 'date_first_seen',
      last_seen: 'date_last_seen',
      severity: 'severity',
      asset_value: 'asset_value',
      priority: 'risk_priority',
    },
    show_criteria_issues: false,
    show_vulnerability_info: false,
    issue_key: 'issue_long_intro',
    issue_description: 'issue_long_vuln',
    solution: 'solution_long',
    show_issue_description: false,
  },
  email_encryption_enabled: {
    host_summary_attributes: null,
    issue_description_attributes: {
      host_name: 'issue',
      first_seen: 'date_first_seen',
      last_seen: 'date_last_seen',
      severity: 'severity',
      asset_value: 'asset_value',
      priority: 'risk_priority',
    },
    show_criteria_issues: false,
    show_vulnerability_info: false,
    issue_key: 'issue_long_intro',
    issue_description: 'issue_long_vuln',
    solution: 'solution_long',
    show_issue_description: false,
  },
  domain_hijacking_protection: {
    host_summary_attributes: null,
    issue_description_attributes: {
      domain_name: 'issue',
      first_seen: 'date_first_seen',
      last_seen: 'date_last_seen',
      severity: 'severity',
      asset_value: 'asset_value',
      priority: 'risk_priority',
    },
    show_criteria_issues: false,
    show_vulnerability_info: false,
    issue_key: 'issue_long_intro',
    issue_description: 'issue_long_vuln',
    solution: 'solution_long',
    show_issue_description: false,
  },
  unsafe_network_services: {
    host_summary_attributes: {
      host_name: 'hostname',
      ip_address: 'IP Address',
    },
    issue_description_attributes: {
      finding_detail: 'issue',
      first_seen: 'date_first_seen',
      last_seen: 'date_last_seen',
      severity: 'severity',
      asset_value: 'asset_value',
      priority: 'risk_priority',
    },
    show_criteria_issues: false,
    show_vulnerability_info: false,
    issue_key: 'issue_long_intro',
    issue_description: 'issue_long_vuln',
    solution: 'solution_long',
    show_issue_description: false,
  },
  iot_devices: {
    host_summary_attributes: {
      host_name: 'hostname',
      ip_address: 'IP Address',
    },
    issue_description_attributes: {
      finding_detail: 'issue',
      first_seen: 'date_first_seen',
      last_seen: 'date_last_seen',
      severity: 'severity',
      asset_value: 'asset_value',
      priority: 'risk_priority',
    },
    show_criteria_issues: false,
    show_vulnerability_info: false,
    issue_key: 'issue_long_intro',
    issue_description: 'issue_long_vuln',
    solution: 'solution_long',
    show_issue_description: false,
  },
  unencrypted_sensitive_systems: {
    host_summary_attributes: {
      host_name: 'hostname',
      ip_address: 'IP Address',
    },
    issue_description_attributes: {
      first_seen: 'date_first_seen',
      severity: 'severity',
      asset_value: 'asset_value',
      priority: 'risk_priority',
    },
    show_criteria_issues: false,
    show_vulnerability_info: false,
    issue_key: 'issue_long_intro',
    issue_description: 'issue_long_vuln',
    solution: 'solution_long',
    show_issue_description: false,
  },
}
export const criteriumToExcludeHostInfo = [
  'web_threat_intel_alert_external',
  'email_authentication',
  'email_encryption_enabled',
  'dns_hijacking_protection',
]
export const criteriumToExcludeIssueManagement = [
  // 'iot_devices',
  // 'unsafe_network_services',
  'web_threat_intel_alert_external',
  'unencrypted_sensitive_systems',
  // 'web_http_security_headers', // http security headers added via
]
export const criteriumToExcludeEventLog = [
  // 'iot_devices',
  // 'unsafe_network_services',
  'web_threat_intel_alert_external',
  'unencrypted_sensitive_systems',
  // 'web_http_security_headers',
]
