import React from 'react'
import classnames from 'classnames'
import './Table.scss'

interface TableProps {
  children: React.ReactNode
  className?: string
  sortable?: boolean
}

const Table = ({ children, className, sortable, ...rest }: TableProps) => {
  return (
    <table
      className={classnames('v3-table', className, { sortable })}
      {...rest}
    >
      {children}
    </table>
  )
}

export default Table
