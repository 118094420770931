import * as React from 'react'
import IconButton from '../buttons/IconButton'
import { CloseSVG } from 'assets/svg'
import type { AppDispatch } from 'store'
import './ToastAlert.scss'

export interface ToastAlertReduxProps {
  dangerous?: string
  infoType?: 'primary' | 'success' | 'danger' | 'warning'
  message?: string
  // onClose: () => (dispatch: AppDispatch) => Promise<void>
  persistent?: boolean
  title: string
}
interface AllToastAlertProps extends ToastAlertReduxProps {
  onClose: () => (dispatch: AppDispatch) => void
}

const ToastAlert = ({
  infoType = 'primary',
  title,
  message,
  onClose,
  persistent = false,
  dangerous,
}: AllToastAlertProps) => {
  const autoDismiss: { current: NodeJS.Timeout | null } = React.useRef(null)

  React.useEffect(() => {
    if (!persistent) {
      autoDismiss.current = setTimeout(() => onClose(), 7000)
    }
    return () => clearTimeout(autoDismiss.current as NodeJS.Timeout)
  }, [persistent, onClose])

  return (
    <div className="toast-container toast-width-2">
      <div className={`toast-alert-v2 ${infoType}`} data-testid="v3-toast-v2">
        <IconButton
          className="toast-alert-v2-close-button"
          icon={<CloseSVG />}
          onClick={onClose}
          color="dark-blue"
        />
        <div className="toast-alert-v2-title">{title}</div>
        {dangerous ? (
          <div
            className="toast-alert-v2-message"
            dangerouslySetInnerHTML={{ __html: dangerous }}
          />
        ) : (
          <div className="toast-alert-v2-message">{message}</div>
        )}
      </div>
    </div>
  )
}

export default ToastAlert
