import React from 'react'
import classnames from 'classnames'
import './TableCellHeader.scss'
import { AngleDown } from '../../../assets/svg'

interface TableCellHeaderProps {
  children: React.ReactNode
  className?: string
  header?: boolean
  sortable?: boolean
  sorted?: boolean
  sortDirection?: 'asc' | 'desc'
  align?: string
  sticky?: boolean
}

const TableCellHeader = ({
  children,
  className,
  header = false,
  sortable = false,
  sorted = false,
  sortDirection = 'asc',
  align = 'left',
  sticky = false,
  ...rest
}: TableCellHeaderProps) => {
  if (sortable) {
    return (
      <th
        className={classnames('v3-th', className, align, {
          sortable,
          sticky,
        })}
        {...rest}
      >
        <div className="th-wrapper">
          <div
            className={classnames('th-text-svg-wrapper', sortDirection, align, {
              sorted,
            })}
          >
            {children}
            <AngleDown
              className="v3-th-angle-svg"
              fill={sorted ? '#173A56' : '#B5CDE3'}
            />
          </div>
        </div>
      </th>
    )
  }
  return (
    <th className={classnames('v3-th', className, align)} {...rest}>
      {children}
    </th>
  )
}

export default TableCellHeader
