import {
  FETCHING_LANGUAGE_INFO,
  SHOW_FINDINGS_SIDEBAR,
  FETCH_QUERY_LANGUAGE_SUCCESS,
  FETCH_LANGUAGE_SUCCESS,
  FETCH_SOFTWARE_PATCHING_DATA_SUCCESS,
  FETCH_THREAT_INTELL_ISSUES_SUCCESS,
  FETCHING_THREAT_INTELL_ISSUES,
  FETCH_HOST_PROFILE_INFO_SUCCESS,
  FETCHING_HOST_PROFILE,
  FETCH_FINDINGS_ERROR,
  // FETCHING_FINDINGS_BY_HOST,
  // FETCH_FINDINGS_BY_HOST_SUCCESS,
  // FETCH_FINDINGS_BY_HOST_FAILURE,
  RESET_SIDEBAR_FINDING_DATA,
  FETCHING_HOST_INFO,
  FETCH_HOST_INFO_FAILURE,
  FETCH_HOST_INFO_SUCCESS,
  FETCHING_SOFTWARE_PATCHING_DATA,
  ADD_FINDING_TO_ACTION_PLAN_SUCCESS,
  FETCH_ISSUE_MGMT_OPTIONS_SUCCESS,
  UPDATE_SIDEBAR_CURRENT_FINDING,
  FETCHING_EVENT_LOG,
  FETCH_EVENT_LOG_SUCCESS,
  FETCH_EVENT_LOG_ERROR,
  FETCHING_FETCH_FINDING_NEW_INFO_CALL,
  FETCH_FINDING_NEW_INFO_CALL_SUCCESS,
  FETCH_FINDING_NEW_INFO_CALL_ERROR,
  FETCH_HOSTS_ERROR,
} from '../../actions/Report/Findings'

const initialState = {
  currentFinding: {},
  loadingError: false,
  loading: false,
  fetchingLanguageInfo: true,
  fetchLanguageInfoSuccess: false,
  fetchLanguageInfoFail: false,
  fetchingHostInfo: false,
  fetchingSoftwarePatchingData: false,
  fetchingThreatIntellIssues: false,
  showFindingDetailsModal: false,
  fetchingHostProfileInfo: false,
  showHostProfileModal: false,
  fetchingFindingsByHost: false,
  fetchingFindingsByHostFailure: false,
  fetchingFindingsByHostSuccess: false,
  findings_by_host: {},
  languageFindings: {},
  queryWithLanguageFindings: {},
  threatIntellIssues: {},
  hostProfileDimension: {},
  findingDrawerOpen: false,
  selectedSidebarTopTab: 'host',
  selectedIssueTab: 'issue-detail',
  fetchHostInfoFailure: {},
  fetchHost: false,
  sideBarShowHostOnly: false,
  issueMgmtOptions: {},
  fetchingEventLog: false,
  fetchEventLogError: false,
  fetchingFindingNewInfoCall: false,
  fetchFindingNewInfoCallError: false,
  fetchHostError: false,
}

function Findings(state = initialState, action) {
  switch (action.type) {
    case 'RESET_APP_STATE':
      return initialState
    case FETCHING_LANGUAGE_INFO:
      return Object.assign({}, state, {
        fetchingLanguageInfo: true,
      })
    case FETCH_HOST_INFO_SUCCESS:
      return {
        ...state,
        currentFinding: {
          ...state.currentFinding,
          ...action.response,
        },
        fetchingHostInfo: false,
      }
    case FETCH_HOST_INFO_FAILURE:
      return { ...state, fetchingHostInfoFailure: true }
    case FETCHING_HOST_INFO:
      return { ...state, fetchingHostInfo: true }
    case SHOW_FINDINGS_SIDEBAR:
      return {
        ...state,
        currentFinding: action.finding,
        showHostProfileModal: !state.showHostProfileModal,
        findingDrawerOpen: true,
        sideBarShowHostOnly: action.hostOnly,
        selectedSidebarTopTab: action.selectedTopTab,
        fetchHost: action.fetchHost,
        fromActionPlan: action.fromActionPlan,
        selectedIssueTab: action.selectedIssueTab,
      }
    case FETCH_QUERY_LANGUAGE_SUCCESS: {
      const queryWithLanguageFindings = state.queryWithLanguageFindings
      queryWithLanguageFindings[action.query] = action.data
      return Object.assign({}, state, {
        queryWithLanguageFindings,
        fetchingLanguageInfo: false,
        fetchLanguageInfoSuccess: true,
      })
    }
    case FETCH_LANGUAGE_SUCCESS: {
      const languageFindings = state.languageFindings
      languageFindings[action.securityCriteria] = {}
      languageFindings[action.securityCriteria].language = action.data
      return Object.assign({}, state, {
        languageFindings,
        fetchingLanguageInfo: false,
        fetchLanguageInfoSuccess: true,
      })
    }
    case FETCHING_THREAT_INTELL_ISSUES:
      return Object.assign({}, state, {
        fetchingThreatIntellIssues: true,
      })
    case FETCH_THREAT_INTELL_ISSUES_SUCCESS:
      return {
        ...state,
        currentFinding: {
          ...state.currentFinding,
          threatIntellIssues: action.data,
        },
        fetchingThreatIntellIssues: false,
      }
    case FETCH_SOFTWARE_PATCHING_DATA_SUCCESS:
      return {
        ...state,
        currentFinding: {
          ...state.currentFinding,
          ...action.data,
        },
        fetchingSoftwarePatchingData: false,
      }
    case FETCHING_HOST_PROFILE:
      return Object.assign({}, state, {
        fetchingHostProfileInfo: true,
        fetchingHostInfo: true,
      })
    case FETCH_HOST_PROFILE_INFO_SUCCESS:
      return {
        ...state,
        currentFinding: {
          ...state.currentFinding,
          dimensions: action.data._embedded['rel:dimensions'],
        },
        fetchingHostProfileInfo: false,
        fetchingHostInfo: false,
        fetchHostError: false,
      }
    // case FETCHING_FINDINGS_BY_HOST:
    //   return { ...state, fetchingFindingsByHost: true }
    // case FETCH_FINDINGS_BY_HOST_SUCCESS:
    //   return Object.assign({}, state, {
    //     findings_by_host: action.response,
    //     fetchingFindingsByHost: false,
    //     fetchingFindingsByHostFailure: false,
    //     fetchingFindingsByHostSuccess: true,
    //   })
    // case FETCH_FINDINGS_BY_HOST_FAILURE:
    //   return { ...state, fetchingFindingsByHostFailure: true }
    case RESET_SIDEBAR_FINDING_DATA:
      return Object.assign({}, state, {
        findings_by_host: {},
        fetchingFindingsByHost: false,
        fetchingFindingsByHostFailure: false,
        fetchingFindingsByHostSuccess: false,
        fetchingLanguageInfo: true,
        queryWithLanguageFindings: {},
        fetchLanguageDataCharacteristicsFailure: false,
        languageFindings: {},
        findingDrawerOpen: false,
      })
    case FETCHING_SOFTWARE_PATCHING_DATA:
      return Object.assign({}, state, {
        fetchingSoftwarePatchingData: true,
      })
    case FETCH_FINDINGS_ERROR:
      return Object.assign({}, state, {
        loadingError: true,
      })
    case FETCH_HOSTS_ERROR:
      return { ...state, fetchHostError: true }
    case ADD_FINDING_TO_ACTION_PLAN_SUCCESS:
      return Object.assign({}, state, {
        findingDrawerOpen: false,
      })
    case FETCH_ISSUE_MGMT_OPTIONS_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        issueMgmtOptions: action.response,
      })
    case FETCHING_EVENT_LOG:
      return {
        ...state,
        fetchingEventLog: true,
      }
    case FETCH_EVENT_LOG_SUCCESS:
      return {
        ...state,
        currentFinding: {
          ...state.currentFinding,
          event_log: action.data,
        },
        fetchingEventLog: false,
      }
    case FETCH_EVENT_LOG_ERROR:
      return {
        ...state,
        fetchEventLogError: true,
        fetchingEventLog: false,
      }
    case FETCHING_FETCH_FINDING_NEW_INFO_CALL:
      return {
        ...state,
        fetchingFindingNewInfoCall: true,
      }
    case FETCH_FINDING_NEW_INFO_CALL_SUCCESS:
      if (!action.data.attributes.is_in_action_plan) {
        action.data.attributes.is_in_action_plan = false
      }
      return Object.assign({}, state, {
        ...state,
        currentFinding: {
          ...state.currentFinding,
          is_in_action_plan: action.data.attributes.is_in_action_plan,
          customer_due_date: action.data.attributes.customer_due_date,
          customer_finding_status:
            action.data.attributes.customer_finding_status,
          is_shared: action.data.attributes.is_shared,
        },
        fetchingFindingNewInfoCall: false,
      })
    case FETCH_FINDING_NEW_INFO_CALL_ERROR:
      return {
        ...state,
        fetchFindingNewInfoCallError: true,
        fetchingFindingNewInfoCall: false,
      }
    case UPDATE_SIDEBAR_CURRENT_FINDING:
      if (action.params.removeFromActionPlan) {
        return Object.assign({}, state, {
          ...state,
          currentFinding: {
            ...state.currentFinding,
            customer_due_date: null,
            is_in_action_plan: false,
            is_shared: false,
          },
        })
      }

      if (action.params.addToActionPlan) {
        return Object.assign({}, state, {
          ...state,
          currentFinding: {
            ...state.currentFinding,
            customer_due_date: action.params.addToActionPlanFixDate
              ? action.params.addToActionPlanFixDate
              : null,
            is_in_action_plan: true,
          },
        })
      }

      if (action.params.reportComensatingControl) {
        return Object.assign({}, state, {
          ...state,
          currentFinding: {
            ...state.currentFinding,
            customer_finding_status: 'Compensating Control',
            customer_marked_compensating_control: true,
          },
        })
      }

      if (action.params.reportFalsePositive) {
        return Object.assign({}, state, {
          ...state,
          currentFinding: {
            ...state.currentFinding,
            customer_finding_status: 'False Positive',
            customer_marked_false_positive: true,
          },
        })
      }
      return state
    default:
      return state
  }
}

export default Findings
