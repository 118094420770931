import React from 'react'
import classnames from 'classnames'
import './TextInput.scss'

type TextInputType =
  | 'button'
  | 'checkbox'
  | 'color'
  | 'date'
  | 'datetime-local'
  | 'email'
  | 'file'
  | 'hidden'
  | 'image'
  | 'month'
  | 'number'
  | 'password'
  | 'radio'
  | 'range'
  | 'reset'
  | 'search'
  | 'submit'
  | 'tel'
  | 'text'
  | 'time'
  | 'url'
  | 'week'

interface TextInputProps {
  autoFocus?: boolean
  className?: string
  disabled?: boolean
  error?: string
  fullBorder?: boolean
  icon?: JSX.Element
  id?: string
  invalidText?: string
  label?: React.ReactNode
  noLabel?: boolean
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  placeholder?: string
  textLinkHref?: string
  textLinkText?: string
  type?: TextInputType
  autocomplete?: string
  validText?: string
  value?: string
}

const TextInput = ({
  value = '',
  type = 'text',
  disabled = false,
  placeholder = '',
  id,
  label,
  fullBorder = false,
  invalidText,
  validText,
  onChange,
  icon,
  textLinkText,
  textLinkHref,
  className,
  noLabel = false,
  error = '',
  // autoFocus, // not good for a11y
  ...rest
}: TextInputProps) => {
  return (
    <div
      className={classnames('v3-text-input', className, {
        'full-border': fullBorder,
        error: !!error,
      })}
    >
      {!noLabel && (
        <label htmlFor={id} className={classnames({ disabled: disabled })}>
          {label}
        </label>
      )}
      <input
        type={type}
        disabled={disabled}
        placeholder={placeholder}
        id={id}
        value={value}
        onChange={onChange}
        // autoFocus={autoFocus}
        {...rest}
      />
      <div className="icon-wrapper">{icon}</div>
      <div className="text-link-wrapper">
        <a href={textLinkHref}>{textLinkText}</a>
      </div>
      {!fullBorder && <div className="focus-indicator"></div>}
      {!!error && <div className="error-text">{error}</div>}
      {/* {(value && validText) && <div className="valid-text">{validText}</div>} */}
    </div>
  )
}

export default TextInput
