import React from 'react'
import PropTypes from 'prop-types'
import './BetaTag.scss'

const BetaTag = ({ size, backgroundType }) => (
  <div className={`beta-tag beta-tag-${backgroundType} beta-tag-${size}`}>
    BETA
  </div>
)

BetaTag.defaultProps = {
  size: 'big',
  backgroundType: 'light',
}

BetaTag.propTypes = {
  size: PropTypes.oneOf(['big', 'small']),
  backgroundType: PropTypes.oneOf(['light', 'dark']),
}

export default BetaTag
