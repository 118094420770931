import React from 'react'
import { Container, Header } from 'components/common'
import { getDefinition } from 'mcr_constants'
import { prettyDomainName } from 'utils/functions'
import './UserGuide.scss'
import { useTranslation } from 'react-i18next'

const UserGuide = () => {
  const { t } = useTranslation(undefined, { keyPrefix: 'UserGuide' })
  return (
    <div id="user-guide">
      <Container>
        <Header>{t('userGuide')}</Header>
        <div>
          <Header as="h3" light>
            {t('overallRatingTitle')}
          </Header>
          <p>{t('overallRatingBody')}</p>
          <table className="risk-rating-table">
            <thead>
              <tr>
                <th>
                  <p>{t('securityCategory')}</p>
                </th>
                <th>
                  <p>{t('description')}</p>
                </th>
                <th>
                  <p>{t('baseWeight')}</p>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <p>{prettyDomainName('software_patching')}</p>
                </td>
                <td>
                  <p>{t('softwarePatchingDesc')}</p>
                </td>
                <td>
                  <p>30.00%</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>{prettyDomainName('web_app_security')}</p>
                </td>
                <td>
                  <p>{t('applicationSecDesc')}</p>
                </td>
                <td>
                  <p>12.50%</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>{prettyDomainName('web_encryption')}</p>
                </td>
                <td>
                  <p>{t('webEncryptionDesc')}</p>
                </td>
                <td>
                  <p>12.50%</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>{prettyDomainName('network_filtering')}</p>
                </td>
                <td>
                  <p>{t('networkFilteringDesc')}</p>
                </td>
                <td>
                  <p>10.00%</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>{prettyDomainName('data_loss')}</p>
                </td>
                <td>
                  <p>{t('breachEventsDesc')}</p>
                </td>
                <td>
                  <p>10.00%</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>{prettyDomainName('threat_intell')}</p>
                </td>
                <td>
                  <p>{t('systemRepDesc')}</p>
                </td>
                <td>
                  <p>7.50%</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>{prettyDomainName('email_security')}</p>
                </td>
                <td>
                  <p>{t('emailSecurityDesc')}</p>
                </td>
                <td>
                  <p>6.25%</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>{prettyDomainName('dns_security')}</p>
                </td>
                <td>
                  <p>{t('dnsSecurityDesc')}</p>
                </td>
                <td>
                  <p>6.25%</p>
                </td>
              </tr>
              <tr>
                <td>
                  <p>{prettyDomainName('system_hosting')}</p>
                </td>
                <td>
                  <p>{t('systemHostingDesc')}</p>
                </td>
                <td>
                  <p>5.00%</p>
                </td>
              </tr>
            </tbody>
          </table>

          <p>{t('gradesDesc')}</p>
          <table className="numeric-score-table">
            <thead>
              <tr>
                <th className="letter"></th>
                <th className="score-range"></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>A</td>
                <td>8.5 – 10</td>
              </tr>
              <tr>
                <td>B</td>
                <td>7.0 – 8.4</td>
              </tr>
              <tr>
                <td>C</td>
                <td>5.5 – 6.9</td>
              </tr>
              <tr>
                <td>D</td>
                <td>4.0 – 5.4</td>
              </tr>
              <tr>
                <td>F</td>
                <td>0.0 – 3.9</td>
              </tr>
            </tbody>
          </table>
          <Header as="h3" light>
            {t('actionPlanVAllIssuesTitle')}
          </Header>
          <p>{t('actionPlanVAllIssuesBody')}</p>
          <Header as="h3" light>
            {t('issuePriorityNavigatorTitle')}
          </Header>
          <p>{getDefinition('issuePriorityNavigator')}</p>
          <Header as="h3" light>
            {t('issueBreakdown')}
          </Header>
          <p>{getDefinition('issuePriorityBreakdown')}</p>
          <p>{t('issueTypes')}</p>
          <ul>
            {(t('issueTypesArray', { returnObjects: true }) as string[]).map(
              (t) => (
                <li key={t}>{t}</li>
              ),
            )}
          </ul>
          <Header as="h3" light>
            {t('categoryIssueListTitle')}
          </Header>
          <p>{t('categoryIssueListBody')}</p>
          <Header as="h3" light>
            {t('ITProfileTitle')}
          </Header>
          <p>{t('ITProfileBody')}</p>
          <Header as="h3" light>
            {t('dataDownloadsTitle')}
          </Header>
          <p>{t('dataDownloadsBody')}</p>
        </div>
      </Container>
    </div>
  )
}

export default UserGuide
