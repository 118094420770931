import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import './TextAreaInput.scss'

const TextAreaInput = ({
  onClick,
  disabled,
  placeholder,
  id,
  label,
  rows,
  value,
  onChange,
  ...rest
}) => {
  return (
    <div className={classnames('v3-textarea')}>
      <label htmlFor={id}>{label}</label>
      <textarea
        onClick={onClick}
        disabled={disabled}
        placeholder={placeholder}
        rows={rows}
        id={id}
        value={value}
        onChange={onChange}
        {...rest}
      />
    </div>
  )
}

TextAreaInput.defaultProps = {
  disabled: false,
  value: '',
}

TextAreaInput.propTypes = {
  disabled: PropTypes.bool,
  id: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  rows: PropTypes.number.isRequired,
  value: PropTypes.string.isRequired,
}

export default TextAreaInput
