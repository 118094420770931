import { lazy, useState } from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import { useAppSelector } from 'hooks'

import GlobalLoader from './utils/GlobalLoader'
import UserGuide from './UserGuide'
import GlossaryOfTerms from './GlossaryOfTerms'
import TopNavMCR from './TopNav/TopNav'
import ProductTourMCR from './ProductTourMCR'
import PortalContainer from './utils/PortalContainer'
import IdleTimer from './utils/IdleTimer'
import ScrollToTop from './utils/ScrollToTop'
import PublicRoutes from './PublicRoutes/PublicRoutes'
import './MyCyberRisk.scss'
import { FullStory } from '@fullstory/browser'

const UserOnboard = lazy(() => import('components/UserOnboard/UserOnboard'))
const ReportRouterMCR = lazy(() => import('./Report/ReportRouterMCR'))

const MyCyberRisk = () => {
  // const dispatch = useAppDispatch()
  const { single_toe_analysis_id, user_id, product_tour_at, customer_id } =
    useAppSelector((state) => ({
      single_toe_analysis_id: state.CurrentUser.single_toe_analysis_id,
      product_tour_at: state.CurrentUser.product_tour_at,
      user_id: state.CurrentUser.user_id,
      customer_id: state.CurrentUser.customer_id,
    }))

  const [showTour, setShowTour] = useState(false)
  const [showWelcomeModal, setShowWelcomeModal] = useState(!product_tour_at)

  const closeWelcomeModal = () => setShowWelcomeModal(false)
  const showProductTour = () => setShowTour(true)

  if (!user_id) return <PublicRoutes />

  if (process.env.NODE_ENV === 'production') {
    FullStory('setIdentity', {
      uid: String(user_id),
      properties: { customer_id },
    })
  }

  if (!single_toe_analysis_id)
    return (
      <>
        <UserOnboard />
        <IdleTimer />
      </>
    )

  return (
    <>
      <GlobalLoader showWelcomeModal={showWelcomeModal} showTour={showTour}>
        <PortalContainer>
          <ProductTourMCR
            showTour={showTour}
            setShowTour={setShowTour}
            closeWelcomeModal={closeWelcomeModal}
            showWelcomeModal={showWelcomeModal}
          />
          <TopNavMCR showProductTour={showProductTour} />
          <Switch>
            <Route
              path={`/portal/vendor/:analysisId(${single_toe_analysis_id})`}
              component={ReportRouterMCR}
            />
            <Route path="/portal/user-guide" component={UserGuide} />
            <Route
              path="/portal/glossary-of-terms"
              component={GlossaryOfTerms}
            />
            <Route path="/welcome" component={PublicRoutes} />
            <Redirect to={`/portal/vendor/${single_toe_analysis_id}`} />
          </Switch>
          <ScrollToTop />
        </PortalContainer>
      </GlobalLoader>
      <IdleTimer />
    </>
  )
}

export default MyCyberRisk
