import axios from 'axios'
import type { CurrentUserTypes } from 'reducers/CurrentUser'
import type { AppDispatch } from 'store'
// import ReactGA from 'react-ga'

export const FETCH_CURRENT_USER_INFO_SUCCESS = 'FETCH_CURRENT_USER_INFO_SUCCESS'

export function fetchCurrentUserInfoSuccess(data: CurrentUserTypes) {
  return {
    type: FETCH_CURRENT_USER_INFO_SUCCESS,
    currentUser: data,
  }
}

export function fetchCurrentUserInfo() {
  return async (dispatch: AppDispatch) => {
    try {
      const { data } = await axios.get('/mcr/user-info', {
        baseURL: process.env.REACT_APP_AUTH_URL,
      })
      dispatch(fetchCurrentUserInfoSuccess(data))
      // if (window.ga) ReactGA.set({ userId: data.user_id })
    } catch (error) {
      console.error(error)
    }
  }
}

export function logout() {
  return async (dispatch: AppDispatch) => {
    try {
      await axios.delete('/remove-jwt')
      dispatch({ type: 'RESET_APP_STATE' })
    } catch (e) {
      console.log(e)
    }
  }
}
