import React, { useEffect, useRef } from 'react'
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from 'body-scroll-lock'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import IconButton from '../buttons/IconButton'
import CloseSVG from '../../../assets/svg/CloseSVG'

import './SideDrawer.scss'

/**
 *
 */

const SideDrawer = ({ isOpen, children, className, id, close }) => {
  const modalEl = useRef(null)
  useEffect(() => {
    const handleEscapeKey = ({ code }) => {
      if (code === 'Escape') return close()
    }
    document.addEventListener('keyup', handleEscapeKey)
    return () => document.removeEventListener('keyup', handleEscapeKey)
  }, [close])

  useEffect(() => {
    if (isOpen) {
      disableBodyScroll(modalEl.current)
    }
    if (!isOpen && modalEl.current) {
      enableBodyScroll(modalEl.current)
    }
    return () => clearAllBodyScrollLocks()
  }, [isOpen])

  // if (!isOpen) return null

  return (
    <>
      {isOpen && (
        <button id="v3-side-drawer-overlay" className="reset" onClick={close} />
      )}
      <div
        ref={modalEl}
        // onClick={modalClick}
        className={classnames('reset v3-side-drawer', className, {
          open: isOpen,
        })}
        id={id}
      >
        <IconButton
          className="side-drawer-close-button"
          icon={<CloseSVG />}
          onClick={close}
        />
        {isOpen && children}
      </div>
    </>
  )
}

SideDrawer.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  close: PropTypes.func.isRequired,
  id: PropTypes.string,
  isOpen: PropTypes.bool,
}

export default SideDrawer
