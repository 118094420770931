import React from 'react'
import PropTypes from 'prop-types'

const LoadingSVG = ({ size, ...rest }) => (
  <svg
    {...rest}
    className="loading-spinner"
    viewBox="0 0 50 50"
    width={size}
    height={size}
    fill="#27AE60"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M25.111 50a2.75 2.75 0 01-2.778-2.778 2.75 2.75 0 012.778-2.778C35.778 44.39 44.444 35.667 44.444 25c0-1.556 1.223-2.833 2.778-2.833 1.556 0 2.778 1.222 2.778 2.722v.055c0 13.778-11.167 25-24.889 25.056zM2.778 27.889A2.75 2.75 0 010 25.11h2.778L0 25.056V25C0 11.222 11.167.056 24.944 0a2.75 2.75 0 012.778 2.778 2.75 2.75 0 01-2.778 2.778C14.278 5.556 5.556 14.278 5.556 25v.111c0 1.5-1.223 2.778-2.778 2.778z" />
  </svg>
)

LoadingSVG.defaultProps = {
  size: '50',
}

LoadingSVG.propTypes = {
  size: PropTypes.string.isRequired,
}

export default LoadingSVG
