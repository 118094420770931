import { AnyAction } from 'redux'
import {
  DISMISS_TOAST,
  SHOW_TOAST,
  SET_GLOBAL_IS_LOADING,
} from '../actions/Dashboard'
import { TToastAlertProps } from 'components/common'

interface DashboardTypes {
  showToast: boolean
  toastProps: TToastAlertProps
  globalIsLoading: boolean
}

const initialState: DashboardTypes = {
  showToast: false,
  toastProps: {
    infoType: undefined,
    title: '',
    message: '',
    persistent: false,
    dangerous: undefined,
  },
  globalIsLoading: false,
}

function Dashboard(
  state: DashboardTypes = initialState,
  action: AnyAction,
): DashboardTypes {
  switch (action.type) {
    case SHOW_TOAST:
      return {
        ...state,
        showToast: true,
        toastProps: {
          infoType: action.toastProps.infoType,
          title: action.toastProps.title,
          message: action.toastProps.message,
          persistent: action.toastProps.persistent,
          dangerous: action.toastProps.dangerous,
        },
      }
    case DISMISS_TOAST:
      return {
        ...state,
        showToast: false,
        toastProps: initialState.toastProps,
      }
    case SET_GLOBAL_IS_LOADING:
      return {
        ...state,
        globalIsLoading: action.globalIsLoading,
      }
    default:
      return state
  }
}

export default Dashboard
