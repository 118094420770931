import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { ArrowRightSVG } from '../../../assets/svg'
import './ScoreChange.scss'

interface ScoreChangeProps {
  scoreDelta: number
}

const ScoreChange = ({ scoreDelta }: ScoreChangeProps) => {
  const scoreDeltaObj = useMemo(() => {
    return scoreDelta < 0
      ? { type: 'negative', sign: '-' }
      : scoreDelta > 0
      ? { type: 'positive', sign: '+' }
      : { type: 'steady', sign: '' }
  }, [scoreDelta])

  return (
    <div
      className={classnames('v3-score-change', {
        [scoreDeltaObj.type]: scoreDeltaObj.type,
      })}
    >
      <span>
        {scoreDeltaObj.sign}&nbsp;{Math.abs(scoreDelta).toFixed(1)}
      </span>
      <ArrowRightSVG size="18" />
    </div>
  )
}

ScoreChange.propTypes = {
  scoreDelta: PropTypes.number.isRequired,
}

export default ScoreChange
