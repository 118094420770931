import React, { useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { NavItemDropDown } from 'components/TopNav/NavItem'
import find from 'ramda/src/find'
import pipe from 'ramda/src/pipe'
import propOr from 'ramda/src/propOr'
import propEq from 'ramda/src/propEq'
import classnames from 'classnames'
import { supportedLanguages, supportedLanguagesForPubPages } from 'i18n'
import { Loading } from './common'
import './LanguageSelector.scss'

interface LanguageType {
  name: string
  lng: string
}

const LanguageItem = ({ name, lng }: LanguageType) => {
  const [loading, setLoading] = useState(false)
  const buttonRef = useRef<HTMLButtonElement>(null)
  const { i18n } = useTranslation('pub')

  const handleLanguageChange = async () => {
    try {
      setLoading(true)
      await i18n.changeLanguage(lng)
      setLoading(false)
      buttonRef?.current?.blur()
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <li className="reset" role="none">
      <button
        className={classnames('reset sub-item pointer', {
          active: i18n.language === lng,
        })}
        role="menuitem"
        onClick={handleLanguageChange}
        ref={buttonRef}
      >
        {name}
        <span className="language-loading-container">
          <Loading isLoading={loading} size={15} loadingText={false} />
        </span>
      </button>
    </li>
  )
}

const LanguageSelector = ({ publicPages }: { publicPages?: boolean }) => {
  const { i18n } = useTranslation('pub')
  const languages = !!publicPages
    ? supportedLanguagesForPubPages
    : supportedLanguages

  const language = pipe<[LanguageType[]], LanguageType | undefined, string>(
    find<LanguageType>(propEq('lng', i18n.language)),
    propOr('', 'name'),
  )(languages)

  return (
    <NavItemDropDown title={language}>
      {languages.map((language) => (
        <LanguageItem key={language.lng} {...language} />
      ))}
    </NavItemDropDown>
  )
}

export default LanguageSelector
