import React, { useRef } from 'react'
import { NavLink, Link, useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { NavItemDropDown } from 'components/TopNav/NavItem'
import LanguageSelector from '../LanguageSelector'
import { logout } from 'actions/CurrentUser'
import { SignOutSVG } from 'assets/svg'
import { useAppSelector, useAppDispatch } from 'hooks'

import MCRLogo from 'mcr_logo.svg'

import './TopNav.scss'

const LogoutItem = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  return (
    <button
      className="reset sub-item pointer"
      onClick={() => dispatch(logout())}
    >
      <span className="sub-item-icon">
        <SignOutSVG />
      </span>
      {t('TopNavMCR.signOut')}
    </button>
  )
}

interface TopNavMCRProps {
  showProductTour?: () => void
  userOnboard?: boolean
}

const TopNavMCR = ({ showProductTour, userOnboard }: TopNavMCRProps) => {
  const { t } = useTranslation()
  const history = useHistory()
  const userGuideLink = useRef<HTMLAnchorElement>(null)
  const glossaryLink = useRef<HTMLAnchorElement>(null)
  const single_toe_analysis_id = useAppSelector(
    (state) => state.CurrentUser.single_toe_analysis_id,
  )

  const startProductTour = () => {
    history.push(`/portal/vendor/${single_toe_analysis_id}/overview`)
    showProductTour?.()
  }

  if (userOnboard) {
    return (
      <nav role="navigation" id="top-nav">
        <div className="nav-body">
          <div className="nav-left">
            <Link to="/portal" className="navbar-logo">
              <img src={MCRLogo} className="logo-svg" alt="Logo" />
            </Link>
          </div>
          <div className="nav-right">
            <div className="nav-items">
              <LanguageSelector />
              <NavItemDropDown title={t('TopNavMCR.myAccount')}>
                <LogoutItem />
              </NavItemDropDown>
            </div>
          </div>
        </div>
      </nav>
    )
  }

  return (
    <nav role="navigation" id="top-nav">
      <div className="nav-body">
        <div className="nav-left">
          <Link to="/portal" className="navbar-logo">
            <img src={MCRLogo} className="logo-svg" alt="Logo" />
          </Link>
        </div>
        <nav className="nav-right">
          <ul role="menubar" className="reset nav-items">
            <LanguageSelector />
            <NavItemDropDown title={t('TopNavMCR.help')}>
              <button
                className="reset sub-item pointer"
                onClick={startProductTour}
              >
                {t('TopNavMCR.tour')}
              </button>
              <NavLink
                onClick={() => userGuideLink.current?.blur()}
                ref={userGuideLink}
                exact
                className="sub-item"
                to="/portal/user-guide"
              >
                {t('TopNavMCR.userGuide')}
              </NavLink>
              <NavLink
                onClick={() => glossaryLink.current?.blur()}
                ref={glossaryLink}
                exact
                className="sub-item"
                to="/portal/glossary-of-terms"
              >
                {t('TopNavMCR.glossary')}
              </NavLink>
              <a
                className="sub-item"
                href="https://www.mastercard.us/en-us/business/overview/safety-and-security/trust-center.html"
                target="_blank"
                rel="noreferrer"
              >
                {t('TopNavMCR.learningPortal')}
              </a>
              <a className="sub-item" href="mailto:support@riskrecon.com">
                {t('TopNavMCR.support')}
              </a>
            </NavItemDropDown>
            <NavItemDropDown title={t('TopNavMCR.myAccount')}>
              <LogoutItem />
            </NavItemDropDown>
          </ul>
        </nav>
      </div>
    </nav>
  )
}

export default TopNavMCR
