import {
  LOAD_CRITERIA_FINDINGS_ERROR,
  FETCH_DOMAIN_FINDINGS_SUCCESS,
  FETCHING_DOMAIN_FINDINGS,
  FETCHING_CRITERIA_FINDINGS,
  FETCH_DOMAIN_FINDINGS_SUCCESS_NEW_ENDPOINT,
  FETCH_CRITERIA_FINDINGS_SUCCESS_NEW_ENDPOINT,
  FETCH_SUMMARY_INFO_SUCCESS,
  FETCHING_AP_SUMMARY_INFO,
  RESET_ACTION_PLAN_STATE,
} from '../../actions/Report/ActionPlan'
import {
  matrixCountSkeleton,
  domainCountsSkeleton,
  domainFindingsSkeleton,
  overviewPriorityFindingsSkeleton,
  overviewPriorityFindingsCountsSkeleton,
  loadingDomainFindingsSkeleton,
  loadingDomainMatrixCountsSkeleton,
  criteriaFindingsSkeleton,
  loadingCriteriaFindingsSkeleton,
} from '../../constants/Report/ActionPlan'

const initialState = {
  overviewPriorityFindings: Object.assign({}, overviewPriorityFindingsSkeleton),
  overviewPriorityFindingsCounts: Object.assign(
    {},
    overviewPriorityFindingsCountsSkeleton,
  ),
  domainFindingInfo: Object.assign({}, domainFindingsSkeleton),
  criteriaFindingInfo: Object.assign({}, criteriaFindingsSkeleton),
  overviewMatrixCounts: { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0 },
  overviewDomainCounts: Object.assign({}, domainCountsSkeleton),
  fetchingOverviewDomainCounts: false,
  fetchingOverviewMatrixCounts: false,
  fetchingPolicy: false,
  loadingOverviewPriorityFindings: false,
  software_patching_matrix_counts: Object.assign({}, matrixCountSkeleton),
  software_patching_criteria_counts: [],
  software_patching_findings: {},
  web_encryption_matrix_counts: Object.assign({}, matrixCountSkeleton),
  web_encryption_criteria_counts: [],
  web_encryption_findings: {},
  threat_intell_matrix_counts: Object.assign({}, matrixCountSkeleton),
  threat_intell_criteria_counts: [],
  threat_intell_findings: {},
  defensibility_matrix_counts: Object.assign({}, matrixCountSkeleton),
  defensibility_criteria_counts: [],
  defensibility_findings: {},
  web_app_security_matrix_counts: Object.assign({}, matrixCountSkeleton),
  web_app_security_criteria_counts: [],
  web_app_security_findings: {},
  dns_security_matrix_counts: Object.assign({}, matrixCountSkeleton),
  email_security_matrix_counts: Object.assign({}, matrixCountSkeleton),
  dns_security_criteria_counts: [],
  dns_security_findings: {},
  email_security_criteria_counts: [],
  email_security_findings: {},
  loadCriteriaFindingsErrors: {},
  policy: {},
  pdfFile: null,
  xlsxFile: null,
  pdfDownloading: false,
  xlsxDownloading: false,
  loadingDomainFindings: Object.assign({}, loadingDomainFindingsSkeleton),
  loadingCriteriaFindings: Object.assign({}, loadingCriteriaFindingsSkeleton),
  loadingDomainMatrixCounts: Object.assign(
    {},
    loadingDomainMatrixCountsSkeleton,
  ),
  loadingError: false,
  fetchingDomainCounts: true,
  summaryInfo: {
    new_issues_not_shared: 0,
    shared_issues_closed: 0,
    shared_issues_open: 0,
    total_issues_shared: 0,
  },
  fetchingAPSummaryInfo: false,
}

function ActionPlan(state = initialState, action) {
  switch (action.type) {
    case 'RESET_APP_STATE':
    case 'RESET_REPORT_STATE':
    case RESET_ACTION_PLAN_STATE:
      return initialState
    case FETCHING_AP_SUMMARY_INFO:
      return {
        ...state,
        fetchingAPSummaryInfo: true,
      }
    case FETCH_SUMMARY_INFO_SUCCESS:
      return {
        ...state,
        summaryInfo: action.summaryInfo,
        fetchingAPSummaryInfo: false,
      }
    case FETCHING_DOMAIN_FINDINGS:
      return {
        ...state,
        loadingDomainFindings: {
          ...state.loadingDomainFindings,
          [action.domain]: true,
        },
      }
    case FETCHING_CRITERIA_FINDINGS:
      return {
        ...state,
        loadingCriteriaFindings: {
          ...state.loadingCriteriaFindings,
          [action.criteria]: true,
        },
      }
    case FETCH_CRITERIA_FINDINGS_SUCCESS_NEW_ENDPOINT: {
      return {
        ...state,
        criteriaFindingInfo: {
          ...state.criteriaFindingInfo,
          [action.criteria]: {
            currentFindings: action.findings,
            currentPage: action.page,
            totalFindings: action.totalFindings,
          },
        },
        loadingCriteriaFindings: {
          ...state.loadingCriteriaFindings,
          [action.criteria]: false,
        },
      }
    }
    case LOAD_CRITERIA_FINDINGS_ERROR:
      return {
        ...state,
        loadCriteriaFindingsErrors: {
          ...state.loadCriteriaFindingsErrors,
          [`${action.criteria}`]: true,
        },
      }
    case FETCH_DOMAIN_FINDINGS_SUCCESS:
      return {
        ...state,
        domainFindingInfo: {
          ...state.domainFindingInfo,
          [`${action.domain}`]: {
            currentFindings: action.findings,
            currentPage: action.page,
            totalFindings: action.totalFindings,
          },
        },
        loadingDomainFindings: {
          ...state.loadingDomainFindings,
          [action.domain]: false,
        },
      }
    case FETCH_DOMAIN_FINDINGS_SUCCESS_NEW_ENDPOINT:
      return {
        ...state,
        domainFindingInfo: {
          ...state.domainFindingInfo,
          [`${action.domain}`]: {
            currentFindings: action.findings,
            currentPage: action.page,
            totalFindings: action.totalFindings,
          },
        },
        overviewDomainCounts: {
          ...state.overviewDomainCounts,
          [action.domain]: action.totalFindings,
        },
        loadingDomainFindings: {
          ...state.loadingDomainFindings,
          [action.domain]: false,
        },
      }
    default:
      return state
  }
}

export default ActionPlan
