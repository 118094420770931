import React from 'react'
import classnames from 'classnames'
import './TableRow.scss'

interface TableRowProps {
  children: React.ReactNode
  className?: string
  hoverable?: boolean
  accordionParent?: boolean
  accordionChild?: boolean
  expanded?: boolean
  noBorder?: boolean
  show?: boolean
  style?: React.CSSProperties
  onClick?: React.MouseEventHandler<HTMLTableRowElement>
}

const TableRow = ({
  children,
  className,
  hoverable,
  accordionParent,
  accordionChild,
  expanded,
  noBorder,
  show = true,
  onClick,
  style,
  ...rest
}: TableRowProps) => {
  if (show) {
    return (
      <tr
        className={classnames('v3-tr', className, {
          'no-border': noBorder,
          'accordion-parent': accordionParent,
          'accordion-child': accordionChild,
          expanded,
          hoverable,
        })}
        onClick={onClick}
        style={style}
        {...rest}
      >
        {children}
      </tr>
    )
  }
  return null
}

// const TableRow = forwardRef(
//   (
//     {
//       children,
//       className,
//       hoverable,
//       accordionParent,
//       accordionChild,
//       expanded,
//       noBorder,
//       show,
//       ...rest
//     },
//     ref,
//   ) => {
//     if (show) {
//       return (
//         <tr
//           className={classnames('v3-tr', className, {
//             'no-border': noBorder,
//             'accordion-parent': accordionParent,
//             'accordion-child': accordionChild,
//             expanded,
//             hoverable,
//           })}
//           ref={ref}
//           {...rest}
//         >
//           {children}
//         </tr>
//       )
//     }
//     return null
//   },
// )

export default TableRow
