import React from 'react'
import { Tooltip as Tooltippy } from 'react-tippy'
import 'react-tippy/dist/tippy.css'
import './Tooltip.scss'

/**
 * See `https://github.com/tvkhoa/react-tippy#props` for api/props. `className` is for the div container, not the tooltip itself... see 'theme' in docs
 */

interface TooltipProps {
  children: React.ReactNode
  title?: string
  className?: string
  disabled?: boolean
  html?:
    | React.ReactElement<any, string | React.JSXElementConstructor<any>>
    | undefined
  style?: React.CSSProperties | undefined
  distance?: number
  tag?: string
}

const Tooltip = ({
  children,
  title,
  className,
  disabled,
  html,
  style,
  distance = 15,
  tag,
  ...rest
}: TooltipProps) => {
  return (
    <Tooltippy
      title={!disabled ? title : undefined}
      arrow
      // @ts-ignore
      theme="v3" // not properly typed in lib
      className={className}
      arrowSize="big"
      distance={distance}
      disabled={disabled}
      html={html}
      style={style}
      tag={tag}
      {...rest}
    >
      {children}
    </Tooltippy>
  )
}

export default Tooltip
