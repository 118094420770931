import React, { useRef, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import { numberShorthandFormatter } from '../../../utils/functions.js'
import { Bar } from '@vx/shape'
import { Group } from '@vx/group'
import { AxisBottom, AxisLeft } from '@vx/axis'
import { GridRows } from '@vx/grid'
import { scaleBand, scaleLinear } from '@vx/scale'
import './BarChart.scss'

const x = (d) => d.rating
const range = (d) => d.ratingRange
const y = (d) => d.vendors

const BarValueLabels = ({ barX, barY, barWidth, d, padding, fontSize }) => {
  const [textBBox, setTextBBox] = useState({ height: 0, width: 0 })
  const textRef = useRef({})

  useEffect(() => {
    if (textRef.current) {
      setTextBBox(textRef.current.getBBox())
    }
  }, [])

  return (
    <svg
      x={barX}
      y={barY - (10 + fontSize)}
      width={barWidth}
      height={10 + fontSize}
    >
      <rect
        y={0}
        x={(barWidth - textBBox.width - padding) / 2}
        height={textBBox.height}
        width={textBBox.width + padding}
        className="bar-values-bg"
      />
      <text
        className="bar-values"
        textAnchor="middle"
        // dominantBaseline="hanging"
        fontSize={fontSize}
        x={barWidth / 2}
        y={(10 + fontSize) / 2}
        ref={textRef}
      >
        {y(d) !== undefined ? y(d).toLocaleString() : ''}
      </text>
    </svg>
  )
}

BarValueLabels.defaultProps = {
  padding: 20,
  fontSize: 12,
}

BarValueLabels.propTypes = {
  barWidth: PropTypes.number.isRequired,
  barX: PropTypes.number.isRequired,
  barY: PropTypes.number.isRequired,
  d: PropTypes.object.isRequired,
  fontSize: PropTypes.number.isRequired,
  padding: PropTypes.number.isRequired,
}

const RRBarChart = ({ data, width = 500, height = 270 }) => {
  const margin = {
    top: 30,
    left: 40,
    bottom: 20,
  }
  // bounds
  const xMax = width
  const yMax = height - margin.top - margin.bottom

  // scales
  const xScale = scaleBand({
    range: [0, xMax],
    domain: data.map(x),
    padding: 0.25,
  })
  const xSubScale = scaleBand({
    range: [0, xMax],
    domain: data.map(range),
    padding: 0.25,
  })
  const yScale = scaleLinear({
    range: [yMax, 0],
    domain: [0, Math.max(...data.map(y))],
    nice: true,
  })
  const noVendorsToDisplay =
    data.filter((d) => d.vendors === 0).length === data.length
  return (
    <div className={classnames('v3-bar-chart')}>
      {noVendorsToDisplay && (
        <div className="no-vendors-to-display-container">
          <div>Refresh your page, or change the</div>
          <div>applied Dashboard Filter to a folder</div>
          <div>where companies are found.</div>
        </div>
      )}
      <svg width={width + margin.left} height={height + margin.top}>
        <GridRows
          className="grid-rows"
          left={margin.left}
          top={margin.top}
          scale={yScale}
          width={xMax}
          numTicks={3}
        />
        <Group left={margin.left} top={margin.top}>
          {data.map((d) => {
            const letter = x(d)
            const barWidth = xScale.bandwidth()
            let barHeight = yMax - yScale(y(d))
            // if there are vendors make the bar height be at least 5 so it is visible
            if (d.vendors > 0 && barHeight < 5) {
              barHeight = 5
            }
            if (d.vendors === 0) {
              barHeight = 0
            }
            const barX = xScale(letter)
            const barY = yMax - barHeight
            return (
              <React.Fragment key={d.rating}>
                <BarValueLabels
                  barY={barY}
                  barX={barX}
                  barWidth={barWidth}
                  d={d}
                />
                <Bar
                  className={d.rating}
                  x={barX}
                  y={barY}
                  width={barWidth}
                  height={barHeight}
                />
              </React.Fragment>
            )
          })}
        </Group>
        <Group left={margin.left}>
          <AxisBottom
            scale={xScale}
            top={yMax + margin.top}
            label={''}
            axisClassName="x-axis"
            tickClassName="x-ticks"
          />
          <AxisBottom
            scale={xSubScale}
            top={yMax + margin.top + 15}
            axisClassName="x-sub-axis"
            tickClassName="x-sub-ticks"
            hideAxisLine={true}
            hideTicks={true}
          />
          <AxisLeft
            left={0}
            axisClassName="y-axis"
            scale={yScale}
            tickFormat={numberShorthandFormatter}
            numTicks={3}
            top={margin.top}
            label={''}
            tickClassName="y-ticks"
          />
        </Group>
      </svg>
    </div>
  )
}

RRBarChart.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      rating: PropTypes.string.isRequired,
      vendors: PropTypes.number,
      ratingRange: PropTypes.string.isRequired,
    }),
  ).isRequired,
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
}

export default RRBarChart
