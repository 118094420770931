import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { format } from 'date-fns'
import { AxisLeft, AxisBottom } from '@vx/axis'
import { scaleLinear, scaleTime } from '@vx/scale'
import { Area, LinePath, Bar } from '@vx/shape'
import { curveCatmullRom } from '@vx/curve'
import { Group } from '@vx/group'
import { extent, bisector } from 'd3-array'
import { GridRows } from '@vx/grid'
import { LinearGradient } from '@vx/gradient'
import { Text } from '@vx/text'
import { withTooltip } from '@vx/tooltip'
import { localPoint } from '@vx/event'
import { getRatingColorArray } from '../../../utils/functions'
import RatingPill from '../rating-items/RatingPill'
import last from 'ramda/src/last'
import compose from 'ramda/src/compose'
import './LineChart.scss'

/**
 *
 * `gradientId` prop is required in order for gradient to work. `yy` is added to bottom ticks for first value and any Januaries.
 *
 **/

const RRLineChart = ({
  gradientId,
  className,
  width,
  height,
  margin,
  marginTop,
  x,
  y,
  data,
  infoOnly,
  comingSoon,
  showHoverData,
  showTooltip,
  tooltipLeft,
  tooltipTop,
  tooltipData,
  hideTooltip,
}) => {
  const [date, setDate] = useState('')
  const [rating, setRating] = useState(0)

  const ratingColor = !infoOnly && getRatingColorArray(compose(y, last)(data))
  const xMax = width - margin.left - margin.right
  const yMax = height - margin.top - margin.bottom

  const bisectDate = bisector(x).left

  const xScale = scaleTime({
    domain: extent(data, x),
    range: [0, xMax],
  })
  const yScale = scaleLinear({
    domain: [-1, 11],
    range: [yMax, -0.5],
  })

  const getHoverDataItem = (d) => {
    const newDate = x(d)
    if (date === newDate) return
    setDate(newDate)
    setRating(y(d))
  }

  const handleTooltip = (event) => {
    const { x: xCoord } = localPoint(event)
    const x0 = xScale.invert(xCoord - margin.left)
    // get array index of mouse x-coordinate in relation to the data
    const index = bisectDate(data, x0, 1)
    const d0 = data[index - 1]
    const d1 = data[index] || d0 // the `|| d0` clause handles data arrays of 1 item.
    // return data item that is closest to the mouse
    const d = x0 - x(d0) > x(d1) - x0 ? d1 : d0

    getHoverDataItem && getHoverDataItem(d)

    showTooltip({
      tooltipData: d,
      tooltipLeft: xScale(x(d)),
      tooltipTop: yScale(y(d)),
    })
  }

  const handleMouseLeave = () => {
    setDate('')
    setRating(0)
    hideTooltip()
  }

  return (
    <div
      className={classnames('v3-line-chart', className)}
      style={{ width, marginTop }}
    >
      {showHoverData && (
        <div className="chart-header domain-detailed">
          {date && rating !== null && (
            <>
              <div className="active-item-figure">
                <div className="active-item-label">Date:</div>
                <div className="active-date">{format(date, 'MM/dd/yyyy')}</div>
              </div>
              <div className="active-item-figure">
                <div className="active-item-label">Score:</div>
                <div className="active-score">
                  {<RatingPill composite rating={rating} />}
                </div>
              </div>
            </>
          )}
        </div>
      )}
      <svg width={width} height={height}>
        <LinearGradient
          id={gradientId}
          from={`rgba(${ratingColor}, 0.25)`}
          to={`rgba(${ratingColor}, 0)`}
        />
        <AxisLeft
          top={margin.top}
          left={margin.left}
          scale={yScale}
          hideAxisLine
          numTicks={3}
          stroke="#D5DDE4"
          tickStroke="#D5DDE4"
          tickClassName="tick-label"
        />
        <AxisBottom
          top={height - margin.bottom}
          hideAxisLine
          left={margin.left}
          scale={xScale}
          numTicks={5}
          stroke="#D5DDE4"
          tickStroke="#D5DDE4"
          tickFormat={(date, index) => {
            if (!index || format(date, 'M') === '1')
              return format(date, 'MMM `yy')
            return format(date, 'MMM')
          }}
          tickClassName="tick-label"
        />
        <Group top={margin.top} left={margin.left}>
          <rect
            width={xMax}
            height={yMax}
            fill="#FFF"
            stroke="#D5DDE4"
            strokeWidth="1"
            rx="10"
          ></rect>
          <GridRows
            scale={yScale}
            width={xMax}
            tickValues={[0, 2.5, 5, 7.5, 10]}
            stroke="rgba(213, 221, 228, 0.5)"
          />
          <Area
            data={data}
            xScale={xScale}
            yScale={yScale}
            x={x}
            y1={(d) => yScale.range()[1]}
            y0={y}
            strokeWidth={2}
            stroke={'transparent'}
            fill={`url('#${gradientId}')`}
            curve={curveCatmullRom}
          />
          <LinePath
            data={data}
            xScale={xScale}
            yScale={yScale}
            x={x}
            y={y}
            curve={curveCatmullRom}
            stroke="#173A56"
            strokeWidth={2}
          />
          {data.length === 1 && (
            <circle
              cx={xScale(x(data[0]))}
              cy={yScale(y(data[0]))}
              r={3}
              fill="#173A56"
              style={{ pointerEvents: 'none' }}
            />
          )}
          {showHoverData && !infoOnly && !comingSoon && (
            <Bar
              x={0}
              y={0}
              width={xMax}
              height={yMax}
              fill="transparent"
              rx={10}
              onMouseMove={() => handleTooltip}
              onMouseLeave={() => handleMouseLeave}
            />
          )}
          {tooltipData && (
            <circle
              cx={tooltipLeft}
              cy={tooltipTop}
              r={5}
              fill="#173A56"
              style={{ pointerEvents: 'none' }}
            />
          )}
          {infoOnly && (
            <Group top={yMax / 4} left={xMax / 4}>
              <rect
                width={xMax / 2}
                height={yMax / 2}
                fill="#FFF"
                stroke="#D5DDE4"
                strokeWidth="1"
                rx="10"
              ></rect>
              <Text
                x={xMax / 4}
                y={yMax / 4 - 10}
                className="info-only-text"
                verticalAnchor="middle"
                textAnchor="middle"
              >
                Information Only
              </Text>
              <Text
                x={xMax / 4}
                y={yMax / 4 + 10}
                className="info-only-sub-text"
                verticalAnchor="middle"
                textAnchor="middle"
              >
                Does Not Impact Ratings
              </Text>
            </Group>
          )}
          {comingSoon && (
            <Group top={yMax / 4} left={xMax / 4}>
              <rect
                width={xMax / 2}
                height={yMax / 2}
                fill="#FFF"
                stroke="#D5DDE4"
                strokeWidth="1"
                rx="10"
              ></rect>
              <Text
                x={xMax / 4}
                y={yMax / 4}
                className="info-only-text"
                verticalAnchor="middle"
                textAnchor="middle"
              >
                Historical Data Coming Soon
              </Text>
            </Group>
          )}
        </Group>
      </svg>
    </div>
  )
}

RRLineChart.defaultProps = {
  width: 400,
  height: 170,
  margin: { left: 30, right: 10, top: 10, bottom: 25 },
  x: (d) => new Date(d.date_updated),
  y: (d) => d.security_domain_rating_numeric,
}

RRLineChart.propTypes = {
  bottom: PropTypes.number,
  className: PropTypes.string,
  comingSoon: PropTypes.bool.isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  getHoverDataItem: PropTypes.func,
  gradientId: PropTypes.string.isRequired,
  height: PropTypes.number.isRequired,
  hideTooltip: PropTypes.func.isRequired,
  infoOnly: PropTypes.bool,
  left: PropTypes.number,
  margin: PropTypes.shape({
    bottom: PropTypes.number,
    left: PropTypes.number,
    right: PropTypes.number,
    top: PropTypes.number,
  }).isRequired,
  marginTop: PropTypes.string,
  right: PropTypes.number,
  showHoverData: PropTypes.bool.isRequired,
  showTooltip: PropTypes.func.isRequired,
  tooltipData: PropTypes.object,
  tooltipLeft: PropTypes.number,
  tooltipTop: PropTypes.number,
  top: PropTypes.number,
  width: PropTypes.number.isRequired,
  x: PropTypes.func.isRequired,
  y: PropTypes.func.isRequired,
}

export default withTooltip(RRLineChart)
