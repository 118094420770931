import React from 'react'

const PortalContainer = ({ children }: { children: React.ReactNode }) => (
  <div id="portal-container">
    {children}
    <span className="footer">Powered by RiskRecon</span>
  </div>
)

export default PortalContainer
