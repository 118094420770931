import React from 'react'
import { NavLink } from 'react-router-dom'
import { ChevronUpSVG } from 'assets/svg'
import './NavItem.scss'

interface NavItemLinkProps {
  title: string
  to: string
}

export const NavItemLink = ({ title, to }: NavItemLinkProps) => (
  <NavLink role="menuitem" tabIndex={-1} className="nav-item" to={to}>
    {title}
  </NavLink>
)

export const NavItemDropDown = ({ title, children }: NavItemDropDownProps) => (
  <li tabIndex={0} role="menuitem" aria-haspopup="menu" className="nav-item">
    {title}
    {/* <button className="reset">{title}</button> */}
    <ChevronUpSVG className="spin" />
    <ul role="menu" aria-label={title} className="reset sub-menu">
      {children}
    </ul>
  </li>
)

interface NavItemDropDownProps {
  title: string
  children: React.ReactNode
}
