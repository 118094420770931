import React, { useEffect } from 'react'
import axios from 'axios'
import { Modal, Header, Button } from 'components/common'
import './WelcomeModal.scss'
import { useTranslation } from 'react-i18next'

interface WelcomeModalProps {
  startTour: () => void
  showWelcomeModal: boolean
  closeWelcomeModal: () => void
}

const WelcomeModal = ({
  closeWelcomeModal,
  showWelcomeModal,
  startTour,
}: WelcomeModalProps) => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'ProductTourMCR.WelcomeModal',
  })
  useEffect(() => {
    const tookProductTour = async () => {
      try {
        // No need to update redux because desired functionality will occur when users/info is loaded again
        axios.put('/v2/users/update_product_tour_at')
      } catch (e) {
        console.log(e)
      }
    }
    tookProductTour()
  }, [])

  return (
    <Modal
      isOpen={showWelcomeModal}
      close={closeWelcomeModal}
      id="mcr-tour-modal"
    >
      <Header>{t('welcomeTitle')}</Header>
      <div className="mcr-tour-modal-message">{t('welcomeMessage')}</div>
      <div className="mcr-tour-modal-button-container">
        <Button color="dark-blue" onClick={closeWelcomeModal}>
          {t('viewPortal')}
        </Button>
        <Button onClick={startTour}>{t('takeTour')}</Button>
      </div>
    </Modal>
  )
}

export default WelcomeModal
