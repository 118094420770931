import { TToastAlertProps } from 'components/common'
import type { AppDispatch } from 'store'

export const SHOW_TOAST = 'SHOW_TOAST'
export const DISMISS_TOAST = 'DISMISS_TOAST'
export const SET_GLOBAL_IS_LOADING = 'SET_GLOBAL_IS_LOADING'

export const dismissToast = () => (dispatch: any) =>
  dispatch({ type: DISMISS_TOAST })

export const displayToast =
  (toastProps: TToastAlertProps) => (dispatch: AppDispatch) =>
    dispatch({ type: SHOW_TOAST, toastProps })
