import React from 'react'
import PortalContainer from './PortalContainer'
import { Container } from '../common'
import MCRLogo from 'mcr_logo.svg'
import './ErrorBoundary.scss'

const ErrorBoundary = ({ error, componentStack }) => {
  return (
    <PortalContainer>
      <nav role="navigation" id="top-nav">
        <div className="nav-body">
          <div className="nav-left">
            <div className="navbar-logo">
              <img src={MCRLogo} className="logo-svg" alt="Logo" />
            </div>
          </div>
          <div className="nav-right"></div>
        </div>
      </nav>
      <div className="error-boundary-wrapper">
        <Container>
          <div>
            <h1>Sorry! Something went wrong.</h1>
            <div>
              There was a problem loading the content. Email{' '}
              <a href="mailto:support@riskrecon.com">support@riskrecon.com</a>{' '}
              if you continue to have issues.
            </div>
            <button
              className="reset reload-link"
              onClick={() => window.location.reload(true)}
            >
              Reload the page.
            </button>
            {process.env.NODE_ENV === 'development' && (
              <details className="error-details">
                <p>{error.toString()}</p>
                {componentStack}
              </details>
            )}
          </div>
        </Container>
      </div>
    </PortalContainer>
  )
}

export default ErrorBoundary
