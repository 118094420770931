import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import ReactSelect, { components } from 'react-select'
import './Select.scss'
import { ChevronUpSVG, CrossedCircle } from 'assets/svg'

const DisabledDropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <CrossedCircle className={'disabled-icon'} fill={'#435A70'} />
    </components.DropdownIndicator>
  )
}

const DefaultDropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <ChevronUpSVG className="default-icon" fill={'#435A70'} />
    </components.DropdownIndicator>
  )
}

const DefaultRotatedDropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <ChevronUpSVG className="default-icon rotate" fill={'#435A70'} />
    </components.DropdownIndicator>
  )
}
/**
 * Styled wrapper of [React Select](https://github.com/JedWatson/react-select).
 */
const Select = ({
  id,
  label,
  selected,
  onChange,
  options,
  isDisabled,
  isSearchable,
  className,
  error,
  showDisabledIcon,
  ...rest
}) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <div
      className={classnames(
        `v3-select ${className} ${isDisabled ? 'disabled' : ''}`,
        { error: !!error },
      )}
    >
      <label htmlFor={id}>{label}</label>
      <ReactSelect
        value={selected}
        onChange={onChange}
        options={options}
        isDisabled={isDisabled}
        isSearchable={isSearchable}
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            '&:hover': {
              borderColor: !!error
                ? '#b30015'
                : state.isFocused
                ? '#168342'
                : 'lightgray',
            }, // border style on hover
            border: !!error
              ? '1px solid #b30015'
              : state.isFocused
              ? '1px solid #168342'
              : '1px solid lightgray', // default border color
            boxShadow: 'none',
            minHeight: '47px',
          }),
          singleValue: (baseStyles, _state) => ({
            ...baseStyles,
            overflow: 'visible',
          }),
        }}
        onMenuOpen={() => setIsOpen(true)}
        onMenuClose={() => setIsOpen(false)}
        components={{
          DropdownIndicator:
            showDisabledIcon && isDisabled
              ? DisabledDropdownIndicator
              : isOpen
              ? DefaultRotatedDropdownIndicator
              : DefaultDropdownIndicator,
        }}
        {...rest}
      />
      {!!error && <div className="error-text">{error}</div>}
    </div>
  )
}

Select.defaultProps = {
  isDisabled: false,
  isSearchable: false,
  options: [{ value: '', label: 'Select...' }],
  error: '',
  showDisabledIcon: false,
}

Select.propTypes = {
  className: PropTypes.string,
  error: PropTypes.string,
  isDisabled: PropTypes.bool,
  isSearchable: PropTypes.bool,
  label: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.array,
  selected: PropTypes.object,
  showDisabledIcon: PropTypes,
  value: PropTypes.string,
}

export default Select
