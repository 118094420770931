import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import './Toggle.scss'

const Toggle = ({ id, checked, onChange }) => {
  return (
    <div className={classnames('toggle-wrapper')}>
      <label className="tgl-btn">
        <input
          type="checkbox"
          checked={checked}
          onChange={onChange}
          id={id}
          className="tgl"
        />
      </label>
      {/* <label htmlFor={id} className="tgl-btn" /> */}
    </div>
  )
}

Toggle.defaultProps = {
  // placeholderText: 'Unspecified',
}

Toggle.propTypes = {
  checked: PropTypes.bool.isRequired,
  id: PropTypes.string,
  onChange: PropTypes.func.isRequired,
}

export default Toggle
