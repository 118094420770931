import { AnyAction } from 'redux'
import {
  FETCH_ANALYSIS_INDUSTRY_TRENDS_SUCCESS,
  FETCH_COMPANY_INFO_SUCCESS,
  FETCHING_CUSTOMER_INFO,
  FETCHING_ANALYSIS_TRENDS,
  FETCH_REPORT_INFO_ERROR,
  FETCH_INTERNAL_TOE_INFO_SUCCESS,
  RESET_REPORT_STATE,
} from '../actions/Report'

import { ACTION_PLAN_PDF_CUSTOM_MESSAGE } from '../constants/PdfService'

export interface ToeTypes {
  toe_id: string
  toe_short_name: string
  description: string
  frequency: string
  relationshipID: string
  primary_hostname: string
  last_analysis_ts: string
}

interface ReportState {
  activePath: string
  customerName: string
  customer_id: string
  toe: ToeTypes
  toeLastAnalysisTs: string
  createdAtTs: string
  toeShortName: string
  industryTrends: {}
  fetchingIndustryTrends: boolean
  fetchingCustomerInfo: boolean
  fetchedIndustryTrends: boolean
  fetchCustomerInfoSuccessful: boolean
  loadingError: boolean
  internalToeID: string
  internalToeName: string
  analysisID: null | string
  showDownloadingPDFModal: boolean
  currentPDFDownloadCustomMessage: string
}

const initialState: ReportState = {
  activePath: '',
  customerName: '',
  customer_id: '',
  toe: {
    toe_id: '',
    toe_short_name: '',
    description: '',
    frequency: '',
    relationshipID: '',
    primary_hostname: '',
    last_analysis_ts: '',
  },
  toeLastAnalysisTs: '',
  createdAtTs: '',
  toeShortName: '',
  industryTrends: {},
  fetchingIndustryTrends: true,
  fetchingCustomerInfo: true,
  fetchedIndustryTrends: false,
  fetchCustomerInfoSuccessful: false,
  loadingError: false,
  internalToeID: '',
  internalToeName: '',
  analysisID: null,
  showDownloadingPDFModal: false,
  currentPDFDownloadCustomMessage: ACTION_PLAN_PDF_CUSTOM_MESSAGE,
}

function Report(
  state: ReportState = initialState,
  action: AnyAction,
): ReportState {
  switch (action.type) {
    case RESET_REPORT_STATE:
      return initialState
    case FETCH_ANALYSIS_INDUSTRY_TRENDS_SUCCESS:
      return {
        ...state,
        industryTrends: action.industryTrends,
        fetchingIndustryTrends: false,
        fetchedIndustryTrends: true,
      }
    case FETCH_COMPANY_INFO_SUCCESS:
      return {
        ...state,
        customerName: action.customerName,
        toe: { ...action.toe, relationshipID: action.toe.ctar_id }, // TODO replace relationshipID references with ctar_id
        toeLastAnalysisTs: action.toeLastAnalysisTs,
        createdAtTs: action.createdAtTs,
        fetchingCustomerInfo: false,
        toeShortName: action.toeShortName,
        customer_id: action.customer_id,
        analysisID: action.analysisID,
        fetchCustomerInfoSuccessful: true,
      }
    case FETCHING_CUSTOMER_INFO:
      return {
        ...state,
        fetchingCustomerInfo: true,
      }
    case FETCHING_ANALYSIS_TRENDS:
      return {
        ...state,
        fetchingIndustryTrends: true,
      }
    case FETCH_INTERNAL_TOE_INFO_SUCCESS:
      return {
        ...state,
        internalToeID: action.internalToeID,
        internalToeName: action.internalToeName,
      }
    case FETCH_REPORT_INFO_ERROR:
      return {
        ...state,
        loadingError: true,
      }
    default:
      return state
  }
}

export default Report
