import React from 'react'
import classnames from 'classnames'
import './TableCellData.scss'

interface TableCellDataProps {
  children: React.ReactNode
  className?: string
  color?: string
  align?: 'left' | 'center' | 'right'
  width?: string
  dataValue?: string
}

const TableCellData = ({
  children,
  className,
  color,
  align = 'left',
  width,
  dataValue,
  ...rest
}: TableCellDataProps) => {
  return (
    <td
      className={classnames('v3-td', className, color, align)}
      data-value={dataValue || (typeof children === 'string' ? children : null)} // this is a little secret sauce to prevent the tds from resizing on hover due to font-weight change. https://stackoverflow.com/questions/5687035/css-bolding-some-text-without-changing-its-containers-size
      width={width}
      align={align}
      {...rest}
    >
      {children}
    </td>
  )
}

export default TableCellData
