import React from 'react'
import { Link } from 'react-router-dom'
import { Trans, useTranslation } from 'react-i18next'
import PublicPageContainer from './PublicPageContainer'
import { Header, Button } from 'components/common'
import logomark from 'assets/images/logomark.svg'
import enhance_icon from 'assets/images/enhance_icon.svg'
import differentiate_icon from 'assets/images/differentiate_icon.svg'
import reduce_icon from 'assets/images/reduce_icon.svg'
import mcr_screenshot from 'assets/images/mcr_screenshot.png'
import one from 'assets/images/one.svg'
import two from 'assets/images/two.svg'
import three from 'assets/images/three.svg'
import connector_one from 'assets/images/connector_one.svg'
import connector_two from 'assets/images/connector_two.svg'
import './WelcomePage.scss'

function WelcomePage() {
  const { t } = useTranslation('pub', {
    keyPrefix: 'PublicRoutes.welcome',
  })

  return (
    <PublicPageContainer>
      <div className="hero-section top-hero">
        <div className="hero-content">
          <Header className="hero-super-header" as="h3">
            {t('myCyberRisk')}
          </Header>
          <Header regular className="hero-header">
            {t('heroTitle')}
          </Header>
          <Header regular className="hero-sub-header">
            {t('heroSubTitle')}
          </Header>
          <Button color="blue">
            <Link to="/sign-up">{t('signUp')}</Link>
          </Button>
          <div className="activate">{t('activate')}</div>
          <div className="provided-by">
            <div>{t('providedBy')}</div>
            <img src={logomark} alt="mastercard-logo" />
          </div>
        </div>
      </div>
      <div className="hero-2-message">
        <Header as="h3" regular className="message">
          {t('hero2')}
        </Header>
      </div>
      <div className="why-choose-block">
        <div className="subtitle">{t('whyChooseSubtitle')}</div>
        <Header as="h3" className="title">
          {t('whyChoose')}
        </Header>
        <div className="reason-container">
          <div className="reason-item reduce">
            <img src={reduce_icon} alt="reduce losses" />
            <Header as="h4">{t('reduceLosses')}</Header>
            <div className="reason-desc">{t('proactive')}</div>
          </div>
          <div className="reason-item differentiate">
            <img src={differentiate_icon} alt="differentiate business" />
            <Header as="h4">{t('differentiate')}</Header>
            <div className="reason-desc">{t('strengthen')}</div>
          </div>
          <div className="reason-item enhance">
            <img src={enhance_icon} alt="enhance cyber security" />
            <Header as="h4">{t('enhance')}</Header>
            <div className="reason-desc">{t('awareness')}</div>
          </div>
        </div>
      </div>
      <div className="comprehensive-approach-block">
        <div className="blocks-container">
          <div className="top-block">
            <div className="determining-rating">
              <div className="comprehensive-title">{t('comprehensive')}</div>
              <Header as="h4">{t('determining')}</Header>
              <div className="comprehensive-description">
                <Trans>{t('evaluate')}</Trans>
              </div>
            </div>
            <div className="screenshot-container">
              <img src={mcr_screenshot} alt="my cyber risk screenshot" />
            </div>
          </div>
          <div className="bottom-block">
            <div className="bottom-item-container security-assessments">
              <img src={one} alt="one" />
              <Header as="h5">{t('securityAssessments')}</Header>
              <div className="bottom-item-descs">{t('evaluateSecurity')}</div>
              <div className="two-col-list">
                <ul>
                  {(
                    t('securityAssessmentsArr1', {
                      returnObjects: true,
                    }) as Array<'string'>
                  ).map((i: string) => (
                    <li key={i}>{i}</li>
                  ))}
                </ul>
                <ul>
                  {(
                    t('securityAssessmentsArr2', {
                      returnObjects: true,
                    }) as Array<'string'>
                  ).map((i: string) => (
                    <li key={i}>{i}</li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="connector-one">
              <img src={connector_one} alt="connector" />
            </div>
            <div className="bottom-item-container infrastructure-assessments">
              <img src={two} alt="two" />
              <Header as="h5">{t('infraAssessments')}</Header>
              <div className="bottom-item-descs">{t('assessSoftware')}</div>
              <div className="two-col-list">
                <ul>
                  {(
                    t('infraAssessmentsArr1', {
                      returnObjects: true,
                    }) as Array<'string'>
                  ).map((i: string) => (
                    <li key={i}>{i}</li>
                  ))}
                </ul>
                <ul>
                  {(
                    t('infraAssessmentsArr2', {
                      returnObjects: true,
                    }) as Array<'string'>
                  ).map((i: string) => (
                    <li key={i}>{i}</li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="connector-two">
              <img src={connector_two} alt="connector" />
            </div>
            <div className="bottom-item-container impact-analysis">
              <img src={three} alt="three" />
              <Header as="h5">{t('impactAnalysis')}</Header>
              <div className="bottom-item-descs">{t('analyzeImpact')}</div>
            </div>
          </div>
        </div>
      </div>
      <div className="hero-section bottom-hero">
        <div className="hero-content">
          <Header className="hero-super-header" as="h3">
            {t('getStarted')}
          </Header>
          <Header regular className="hero-header">
            {t('activateAccount')}
          </Header>
          <Header regular className="hero-sub-header">
            {t('checkBusiness')}
          </Header>
          <Button color="blue">
            <Link to="/sign-up">{t('signUp')}</Link>
          </Button>
          <div className="activate">{t('activate')}</div>
        </div>
      </div>
    </PublicPageContainer>
  )
}

export default WelcomePage
