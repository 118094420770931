import React from 'react'
import IdleTimer from 'react-idle-timer'
import type { EVENTS } from 'react-idle-timer'
import axios from 'axios'
import { connect } from 'react-redux'
import { logout } from 'actions/CurrentUser'
import { useAppDispatch } from 'hooks'

const PortalIdleTimer = () => {
  const dispatch = useAppDispatch()
  const IDLE_TIMEOUT = 1000 * 60 * 20 // 20 mins
  const THROTTLE_EVENT_TIME = 1000 * 60 * 5 // 5 mins
  const CAPTURED_EVENTS: EVENTS[] = [
    'keydown',
    'wheel',
    'DOMMouseScroll',
    'mousewheel',
    'mousedown',
    'touchstart',
    'touchmove',
    'MSPointerDown',
    'MSPointerMove',
    'visibilitychange',
  ]

  const ping = async () => {
    try {
      await axios.get('/session')
    } catch (e) {
      console.log('error in ping')
      console.log(e)
    }
  }

  return (
    <IdleTimer
      element={document}
      onActive={ping}
      onAction={ping}
      onIdle={() => dispatch(logout)}
      timeout={IDLE_TIMEOUT}
      events={CAPTURED_EVENTS}
      throttle={THROTTLE_EVENT_TIME}
    />
  )
}

export default connect(null, { logout })(PortalIdleTimer)
