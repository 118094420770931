import React from 'react'
import PropTypes from 'prop-types'

const SignOutSVG = ({
  size = '16',
  className = '',
  fill = '#B5CDE3',
  ...rest
}) => (
  <svg
    className={className}
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.40833 10.9917L7.58333 12.1667L11.75 8L7.58333 3.83333L6.40833 5.00833L8.55833 7.16667H0.5V8.83333H8.55833L6.40833 10.9917ZM13.8333 0.5H2.16667C1.24167 0.5 0.5 1.25 0.5 2.16667V5.5H2.16667V2.16667H13.8333V13.8333H2.16667V10.5H0.5V13.8333C0.5 14.75 1.24167 15.5 2.16667 15.5H13.8333C14.75 15.5 15.5 14.75 15.5 13.8333V2.16667C15.5 1.25 14.75 0.5 13.8333 0.5Z"
      fill={fill}
    />
  </svg>
)

SignOutSVG.propTypes = {
  className: PropTypes.string,
  fill: PropTypes.string,
  size: PropTypes.string,
}

export default SignOutSVG
