import React, { useEffect } from 'react'
import { Switch, Redirect, Route, useLocation } from 'react-router-dom'
import SignUp from './UserSignUp/SignUp'
import WelcomePage from './WelcomePage'

const PublicRoutes = () => {
  const location = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location])

  return (
    <Switch>
      <Route path="/welcome" component={WelcomePage} />
      <Route path="/sign-up" component={SignUp} />
      <Redirect to="/welcome" />
    </Switch>
  )
}

export default PublicRoutes
