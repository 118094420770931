import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import './SubNavItem.scss'

/**
 * Hey that's some rounded border ya got there.
 */

const SubNavItem = ({ onClick, active, children }) => {
  return (
    <button
      onClick={onClick}
      data-value={typeof children === 'string' && children}
      className={classnames('reset v3-sub-nav-item', { active })}
    >
      {children}
    </button>
  )
}

SubNavItem.propTypes = {
  active: PropTypes.bool.isRequired,
  children: PropTypes.string,
  onClick: PropTypes.func,
}

export default SubNavItem
