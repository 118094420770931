import { combineReducers } from 'redux'
import Findings from './Report/Findings'
import Report from './Report'
import ActionPlan from './Report/ActionPlan'
import Overview from './Report/Overview'
import CurrentUser from './CurrentUser'
import Dashboard from './Dashboard'

export default combineReducers({
  ActionPlan,
  CurrentUser,
  Dashboard,
  Findings,
  Overview,
  Report,
})
