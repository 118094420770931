import React, { useState, useContext } from 'react'
import PropTypes from 'prop-types'
import Joyride, { ACTIONS, CallBackProps, EVENTS, STATUS } from 'react-joyride'
import WelcomeModal from './WelcomeModal'
import { GlobalLoadingContext } from './utils/GlobalLoader'
import { useTranslation } from 'react-i18next'

const styles = {
  options: {
    primaryColor: '#B30015',
    textColor: '#173A56',
  },
}

interface ProductTourMCRProps {
  setShowTour: React.Dispatch<React.SetStateAction<boolean>>
  showTour: boolean
  closeWelcomeModal: () => void
  showWelcomeModal: boolean
}

const ProductTourMCR = ({
  setShowTour,
  showTour,
  closeWelcomeModal,
  showWelcomeModal,
}: ProductTourMCRProps) => {
  const [stepIndex, setStepIndex] = useState(0)
  const { loaders } = useContext(GlobalLoadingContext)
  const { t } = useTranslation(undefined, { keyPrefix: 'ProductTourMCR' })

  const steps = [
    {
      target: '.rating-wrapper',
      content: t('overallRiskRating'),
      disableBeacon: true,
    },
    {
      target: '.v3-line-chart',
      content: t('lineChart'),
      disableBeacon: true,
    },
    {
      target: '.v3-table.domain-ratings-table',
      content: t('riskRatings'),
      disableBeacon: true,
    },
    {
      target: '.download-action-plan',
      content: t('actionPlan'),
      disableBeacon: true,
    },
    {
      target: '.download-all-issues',
      content: t('downloadIssues'),
      disableBeacon: true,
    },
    {
      target: '.issue-priority-matrix-mcr',
      content: t('issuePriorityMatrix'),
      disableBeacon: true,
    },
    {
      target: '.matrix-square.fix_now',
      content: t('fixNowIssues'),
      disableBeacon: true,
    },
    {
      target: '.issue-priority-matrix-mcr-table',
      content: t('issueMatrixTable'),
      disableBeacon: true,
    },
    {
      target: '#software_patching',
      content: t('issueList'),
      disableBeacon: true,
    },
    {
      target: '.report-link-it-profile',
      content: t('iTProfile'),
      disableBeacon: true,
    },
    {
      target: '.report-link-download-report',
      content: t('dataDownloads'),
      disableBeacon: true,
    },
  ]

  const startTour = () => {
    closeWelcomeModal()
    setShowTour(true)
  }

  // casting weirdness due to https://github.com/microsoft/TypeScript/issues/26255 which links to this open issue https://github.com/microsoft/TypeScript/issues/14520
  const handleJoyrideCallback = (data: CallBackProps) => {
    if (
      ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND] as string[]).includes(
        data.type,
      )
    ) {
      setStepIndex((state) => state + (data.action === ACTIONS.PREV ? -1 : 1))
    }
    if (
      ([STATUS.FINISHED, STATUS.SKIPPED] as string[]).includes(data.status) ||
      ([ACTIONS.CLOSE] as string[]).includes(data.action)
    ) {
      setShowTour(false)
      setStepIndex(0)
    }
  }

  return (
    <>
      {showTour && !loaders.length && (
        <Joyride
          steps={steps}
          locale={{
            last: t('JoyRide.close'),
            back: t('JoyRide.back'),
            next: t('JoyRide.next'),
          }}
          continuous={true}
          stepIndex={stepIndex}
          run={showTour}
          callback={handleJoyrideCallback}
          scrollOffset={300}
          styles={styles}
          floaterProps={{ disableAnimation: true }}
        />
      )}
      {showWelcomeModal && (
        <WelcomeModal
          startTour={startTour}
          showWelcomeModal={showWelcomeModal}
          closeWelcomeModal={closeWelcomeModal}
        />
      )}
    </>
  )
}

ProductTourMCR.propTypes = {
  closeWelcomeModal: PropTypes.func.isRequired,
  setShowTour: PropTypes.func.isRequired,
  showTour: PropTypes.bool.isRequired,
  showWelcomeModal: PropTypes.bool.isRequired,
}

export default ProductTourMCR
