import React from 'react'
import PropTypes from 'prop-types'
import { Tooltip } from 'components/common'
import { InfoSVG } from 'assets/svg'
import './InfoTooltip.scss'

const InfoTooltip = ({ title }) => (
  <Tooltip className="tooltip-mcr" title={title}>
    <InfoSVG size="20px" />
  </Tooltip>
)

InfoTooltip.propTypes = {
  title: PropTypes.string,
}

export default InfoTooltip
