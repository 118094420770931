import React from 'react'
import classnames from 'classnames'
import './TableBody.scss'

interface TableBodyProps {
  children: React.ReactNode
  className?: string
}

const TableBody = ({ children, className, ...rest }: TableBodyProps) => {
  return (
    <tbody className={classnames('v3-tbody', className)} {...rest}>
      {children}
    </tbody>
  )
}

export default TableBody
