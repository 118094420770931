import React from 'react'
import i18n from '../i18n'
import axios from 'axios'

export const getRatingType = (rating) => {
  if (typeof rating !== 'number' || rating < 0) return 'info'
  if (rating < 4) return 'f'
  if (rating < 5.5) return 'd'
  if (rating < 7) return 'c'
  if (rating < 8.5) return 'b'
  return 'a'
}

export const toFixedExceptTen = (value) => {
  const newValue = parseFloat(value)
  return newValue === 10 ? '10' : newValue.toFixed(1)
}

export const numberShorthandFormatter = (number) => {
  const round = (n) => Math.round(n * 1e1) / 1e1

  if (number > 1e12) return round(number / 1e12).toString() + 't'
  if (number > 1e9) return round(number / 1e9).toString() + 'B'
  if (number > 1e6) return round(number / 1e6).toString() + 'M'
  if (number > 1e3) return round(number / 1e3).toString() + 'K'
  return number.toString()
}

export const determineIndustryPercentileColor = (score) => {
  if (score < 0.33) {
    return 'v3-red'
  } else if (score < 0.66) {
    return 'v3-blue'
  } else {
    return 'v3-green'
  }
}

export const ratingColorArrays = {
  info: [255, 255, 255],
  f: [179, 0, 21],
  d: [251, 155, 11],
  c: [242, 201, 76],
  b: [47, 128, 237],
  a: [39, 174, 96],
}

export const getRatingColorArray = (rating) =>
  ratingColorArrays[getRatingType(rating)]

export const hostDimensionTranslation = (key) => {
  const values = {
    'Shared IP Hosting': 'Cotenant IP Hosting',
    Defensibility: 'System Hosting',
    'Threat Intelligence': 'System Reputation',
    'Web Applications': 'Application Security',
    'Data Loss': 'Breach Events',
  }
  return values[key] !== undefined ? values[key] : key
}

export const translateLevel1SecurityDomains = (key) => {
  const values = {
    'Shared IP Hosting': 'Cotenant IP Hosting',
    Defensibility: 'System Hosting',
    'Threat Intelligence': 'System Reputation',
    'Web Applications': 'Application Security',
    'Data Loss': 'Breach Events',
  }
  return values[key] !== undefined ? values[key] : key
}

export const prettyDomainName = (key) => {
  return i18n.t(`universal.domains.${key}`) || key
}

export function prettyCriteriaName(key) {
  return i18n.t(`universal.criteria.${key}`) || key
}

export const ordinalSuffixOf = (i) => {
  const j = i % 10
  const k = i % 100
  let suffix = 'th'
  if (j === 1 && k !== 11) {
    suffix = 'st'
  }
  if (j === 2 && k !== 12) {
    suffix = 'nd'
  }
  if (j === 3 && k !== 13) {
    suffix = 'rd'
  }
  return (
    <div data-testid="ordinal-suffix-format">
      {i}
      <sup>{suffix}</sup>
    </div>
  )
}

export const decimalToPercentage = (decimal) => {
  if (decimal === undefined) return undefined
  const percent = (decimal * 100).toFixed(0)
  if (Number.isNaN(Number(percent))) return false
  return percent + '%'
}

export const formatPriority = (priority) => {
  switch (priority) {
    case 0.1:
      return 'Key 1'
    case 0.2:
      return 'Key 2'
    case 0.3:
      return 'Key 3'
    case 0.4:
      return 'Key 4'
    default:
      return priority
  }
}

export function setClassColorByConcern(key, value = '') {
  let colorClass = ''
  const ratingClasses = ['red', 'red', 'red', 'blue', 'green', 'green', 'green']
  switch (key) {
    case 'asset_value':
      if (value.toUpperCase() === 'HIGH') {
        colorClass = 'v3-red'
      } else if (value.toUpperCase() === 'MEDIUM') {
        colorClass = 'v3-blue'
      } else if (value.toUpperCase() === 'KEY') {
        colorClass = 'v3-default'
      } else {
        colorClass = 'v3-green'
      }
      break
    case 'severity':
      if (
        value.toUpperCase() === 'CRITICAL' ||
        value.toUpperCase() === 'HIGH'
      ) {
        colorClass = 'v3-red'
      } else if (value.toUpperCase() === 'MEDIUM') {
        colorClass = 'v3-blue'
      } else {
        colorClass = 'v3-green'
      }
      break
    case 'priority':
      if (value < 1) {
        colorClass = `v3-${ratingClasses[1]}`
      } else {
        colorClass = `v3-${ratingClasses[value]}`
      }
      break
    case 'cvss_rating':
      if (
        value.toUpperCase() === 'CRITICAL' ||
        value.toUpperCase() === 'HIGH'
      ) {
        colorClass = 'v3-red'
      } else if (value.toUpperCase() === 'MEDIUM') {
        colorClass = 'v3-yellow'
      } else {
        colorClass = 'v3-blue'
      }
      break
    case 'cvss_score':
      if (value > 7) {
        colorClass = 'v3-red'
      } else if (value > 4) {
        colorClass = 'v3-yellow'
      } else {
        colorClass = 'v3-blue'
      }
      break
    case 'security_criteria_rating_numeric':
      if (value > 7.5) {
        colorClass = 'v3-green'
      } else if (value > 5.0) {
        colorClass = 'v3-blue'
      } else if (value > 2.5) {
        colorClass = 'v3-yellow'
      } else {
        colorClass = 'v3-red'
      }
      break
    default:
      colorClass = ''
  }
  return colorClass
}

export const truncateString = (str = '', maxChar = 100) => {
  const string = String(str)
  return string.length <= maxChar
    ? string
    : string.substr(0, maxChar - 3) + '...'
}

export function isIpAddress(ip_address) {
  return /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(
    ip_address,
  )
}

export const removeUnderscores = (string = '') => string?.replace(/_/g, ' ')

export const addUnderscores = (string = '') => string.replace(/\s/g, '_')

export function cveScoreToRating(score) {
  const rating = parseFloat(score)
  let prettyName = ''
  if (rating < 4) {
    prettyName = 'Low'
  } else if (rating >= 4 && rating < 7) {
    prettyName = 'Medium'
  } else if (rating >= 7 && rating < 9) {
    prettyName = 'High'
  } else if (rating >= 9) {
    prettyName = 'Critical'
  }
  return prettyName
}

export function formatNumber(num) {
  if (!num) {
    return num
  }
  return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

export function parameterize(string) {
  return string
    .toString()
    .toLowerCase()
    .replace(/ /g, '-')
    .replace(/_/g, '-')
    .replace(/\./g, '-')
}

export function isSystemAdmin(userRoles) {
  const adminRoles = [
    'UserAdministration',
    'RiskOfficer',
    'EnterpriseAlertingConfig',
  ]
  return adminRoles.some((role) => userRoles.includes(role))
}

export const defangUrl = (str = '') => {
  const urlString = String(str)
  return urlString.replace(/\./g, '[.]')
}

export const center = (scale) => {
  let offset = scale.bandwidth ? scale.bandwidth() / 2 : 0
  if (scale.round && scale.round()) offset = Math.round(offset)
  return function (d) {
    const scaledValue = scale(d)
    if (typeof scaledValue === 'number') return scaledValue + offset // quantize scales return an array of values

    if (Array.isArray(scaledValue)) return Number(scaledValue[0]) + offset
    return scaledValue
  }
}

export const identity = (x) => x

export const getLabelTransform = (_ref) => {
  const labelOffset = _ref.labelOffset
  const labelProps = _ref.labelProps
  const orientation = _ref.orientation
  const range = _ref.range
  const tickLabelFontSize = _ref.tickLabelFontSize
  const tickLength = _ref.tickLength
  const sign = orientation === 'left' || orientation === 'top' ? -1 : 1
  let x
  let y
  let transform

  if (orientation === 'top' || orientation === 'bottom') {
    const yBottomOffset =
      orientation === 'bottom' && typeof labelProps.fontSize === 'number'
        ? labelProps.fontSize
        : 0
    x = (Number(range[0]) + Number(range[range.length - 1])) / 2
    y = sign * (tickLength + labelOffset + tickLabelFontSize + yBottomOffset)
  } else {
    x = sign * ((Number(range[0]) + Number(range[range.length - 1])) / 2)
    y = -(tickLength + labelOffset)
    transform = 'rotate(' + sign * 90 + ')'
  }

  return {
    x: x,
    y: y,
    transform: transform,
  }
}

// Trims out spaces from anywhere in the string, used in UniversalSearch input
export const trim = (str) => {
  str = str.replace(/^\s+/, '')
  for (var i = str.length - 1; i >= 0; i--) {
    if (/\S/.test(str.charAt(i))) {
      str = str.substring(0, i + 1)
      break
    }
  }
  return str
}

export const stringLimit = (str = '', limit = 100) => {
  if (typeof str === 'string' && typeof limit === 'number') {
    return str.substring(0, limit)
  }
  return ''
}

export const axiosBase = axios.create({
  baseURL: process.env.REACT_APP_AUTH_URL,
  withCredentials: true
})
