import React, { createContext, useReducer, useMemo } from 'react'
import { Loading } from 'components/common'

interface GlobalLoadingContextProps {
  dispatch: React.Dispatch<Action>
  loaders: LoaderType[]
}

type LoaderType = 'OVERVIEW_LOADER'

type Action =
  | { type: 'global-loader/add'; loader: LoaderType }
  | { type: 'global-loader/remove'; loader: LoaderType }

export const GlobalLoadingContext = createContext<GlobalLoadingContextProps>(
  {} as GlobalLoadingContextProps,
)

export const OVERVIEW_LOADER: LoaderType = 'OVERVIEW_LOADER'

export const initialState = [OVERVIEW_LOADER]

export const reducer = (state: LoaderType[], action: Action): LoaderType[] => {
  switch (action.type) {
    case 'global-loader/add':
      return [...state, action.loader]
    case 'global-loader/remove':
      return state.filter((l) => l !== action.loader)
    default:
      throw new Error()
  }
}

/******
  this global loader is to prevent the user from taking the product tour before the data has loaded.
  It runs when there is data loading and either the welcome modal or tour is active
******/

interface GlobalLoaderProps {
  children: React.ReactNode
  showWelcomeModal: boolean
  showTour: boolean
}

const GlobalLoader = ({
  children,
  showWelcomeModal,
  showTour,
}: GlobalLoaderProps) => {
  const [loaders, dispatch] = useReducer(reducer, initialState)

  const value = useMemo(() => ({ loaders, dispatch }), [loaders, dispatch])

  return (
    <GlobalLoadingContext.Provider value={value}>
      {children}
      <Loading
        isLoading={!!loaders.length && (showWelcomeModal || showTour)}
        overlay
        className="global-loader"
      />
    </GlobalLoadingContext.Provider>
  )
}

export default GlobalLoader
