import axios from 'axios'

export const FETCHING_CUSTOMER_INFO = 'FETCHING_CUSTOMER_INFO'
export const FETCH_COMPANY_INFO_SUCCESS = 'FETCH_COMPANY_INFO_SUCCESS'
export const FETCH_REPORT_INFO_ERROR = 'FETCH_REPORT_INFO_ERROR'

export const FETCHING_INTERNAL_TOE_INFO = 'FETCHING_INTERNAL_TOE_INFO '
export const FETCH_INTERNAL_TOE_INFO_SUCCESS =
  'FETCH_INTERNAL_TOE_INFO_SUCCESS '

export const FETCHING_ANALYSIS_TRENDS = 'FETCHING_ANALYSIS_TRENDS'
export const FETCH_ANALYSIS_INDUSTRY_TRENDS_SUCCESS =
  'FETCH_ANALYSIS_INDUSTRY_TRENDS_SUCCESS'

export const RESET_REPORT_STATE = 'RESET_REPORT_STATE'

export const resetReportState = () => (dispatch) =>
  dispatch({ type: RESET_REPORT_STATE })

export function fetchingCustomerInfo() {
  return {
    type: FETCHING_CUSTOMER_INFO,
  }
}

export function fetchCustomerInfoSuccess(
  response,
  lastAnalysisTs,
  createdAtTs,
) {
  return {
    type: FETCH_COMPANY_INFO_SUCCESS,
    toe: response.data._embedded['rel:toes'][0],
    toeLastAnalysisTs: lastAnalysisTs,
    createdAtTs: createdAtTs,
    toeShortName: response.data._embedded['rel:toes'][0].toe_short_name,
    analysisID: response.data._embedded['rel:toes'][0].analysis_id,
    customerName: response.data.customer_name,
    customer_id: response.data.customer_id,
  }
}

export function fetchReportInfoError() {
  return {
    type: FETCH_REPORT_INFO_ERROR,
  }
}

export function fetchingInternalToeInfo() {
  return {
    type: FETCHING_INTERNAL_TOE_INFO,
  }
}

export function fetchInternalToeInfoSuccess(data) {
  return {
    type: FETCH_INTERNAL_TOE_INFO_SUCCESS,
    internalToeID: (data.customer_toe_profile || {})
      .internal_company_identifier,
    internalToeName: (data.customer_toe_profile || {}).internal_company_name,
  }
}

export function fetchingAnalysisTrends() {
  return {
    type: FETCHING_ANALYSIS_TRENDS,
  }
}

export function fetchAnalysisIndustryTrendsSuccess(
  response,
  toeId,
  industryTrends,
) {
  return {
    type: FETCH_ANALYSIS_INDUSTRY_TRENDS_SUCCESS,
    toeId,
    industryTrends,
    received_at: Date.now(),
  }
}

export function fetchCustomerInfo(analysisId) {
  return async (dispatch) => {
    try {
      dispatch(fetchingCustomerInfo())
      // const success = await axios.get(
      //   `/mcr/customer-info?analysis_id=${analysisId}`,
      // )
      const success = await axios.get(`/v0/portfolio/member/${analysisId}.json`)
      dispatch(
        fetchCustomerInfoSuccess(
          success,
          success.data._embedded['rel:toes'][0].last_analysis_ts,
          success.data._embedded['rel:toes'][0].created_at,
        ),
      )
      return Promise.resolve()
    } catch (error) {
      dispatch(fetchReportInfoError(error))
    }
  }
}

// ***
// This action creator and accompanying endpoint is used by the MyCyberRisk application. If you intend to change the functionality please let the MyCyberRisk team know -- ideally before any code is committed!
// ***
export function fetchInternalToeInfo(toeID) {
  return async (dispatch) => {
    dispatch(fetchingInternalToeInfo())
    try {
      const success = await axios.get(`/v0/customer/vendor/${toeID}`)
      dispatch(fetchInternalToeInfoSuccess(success.data))
    } catch (error) {
      dispatch(fetchReportInfoError(error))
    }
  }
}
