import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import Checkbox from './Checkbox'
import './CheckboxSelect.scss'
import { AngleDown, CrossedCircle } from '../../../assets/svg'

const CheckboxSelect = ({
  id,
  options,
  className,
  onCheckboxChange,
  defaultText,
  disabled,
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const node = useRef()

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick)
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick)
    }
  }, [])

  const handleOutsideClick = (e) => {
    if (!node.current.contains(e.target)) {
      setDropdownOpen(false)
    }
  }

  const handleSelectChange = (e) => {
    dropdownOpen ? setDropdownOpen(false) : setDropdownOpen(true)
  }

  const Checkboxes = ({ options }) => (
    <>
      {options.map((record, idx) => (
        <div className="checkbox-container" key={idx}>
          <Checkbox
            id={`${record.value}-checkbox`}
            checked={record.checked}
            onChange={() => onCheckboxChange(idx)}
            disabled={record.disabled}
          />
          <label
            htmlFor={`${record.value}-checkbox`}
            className={`checkbox-label ${record.checked ? 'checked' : ''} ${
              record.disabled ? 'disabled' : ''
            } `}
          >
            {record.label}
          </label>
        </div>
      ))}
    </>
  )

  const DisplayText = () => {
    const labels = []
    options.forEach((record) => {
      if (record.checked) {
        labels.push(record.label)
      }
    })
    if (labels.length > 0) {
      return <>{labels.join(', ')}</>
    } else {
      return <>{defaultText}</>
    }
  }

  return (
    <div
      id={id}
      className={`${className} checkbox-select-container`}
      ref={node}
    >
      <div className="checkbox-select-button-wrapper">
        <button
          className={`${disabled ? 'disabled' : ''} checkbox-select-button`}
          disabled={disabled}
          onClick={handleSelectChange}
        >
          <div className="display-text">
            <DisplayText />
          </div>
          {disabled ? (
            <CrossedCircle className={'filter-icon'} fill={'#435A70'} />
          ) : (
            <AngleDown
              className={`filter-icon ${dropdownOpen ? '' : 'rotate'}`}
              height={'8px'}
              width={'12px'}
              fill={'#435A70'}
            />
          )}
        </button>
      </div>
      {dropdownOpen && (
        <div className="checkboxes">
          <Checkboxes options={options} />
        </div>
      )}
    </div>
  )
}

CheckboxSelect.defaultProps = {}

CheckboxSelect.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      checked: PropTypes.bool,
      disabled: PropTypes.bool,
      value: PropTypes.any,
    }),
  ).isRequired,
  defaultText: PropTypes.string.isRequired,
  onCheckboxChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  id: PropTypes.string,
}

export default CheckboxSelect
