import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { capitalize } from 'utils/TextHelpers.js'
import { getRatingType } from 'utils/functions'
import './RatingGauge.scss'
const RatingGauge = ({ rating, bgColor, svgRef, width }) => {
  const [gaugeValue] = useState(120 - (rating * 120) / 10)
  const [pointerRotation] = useState((rating * 360) / 10)
  const circleRef = useRef(null)
  const ratingType = getRatingType(rating)
  let activeRingStroke = '#B30015'
  switch (ratingType) {
    case 'fix':
      activeRingStroke = '#F2C94C'
      break
    case 'limit':
      activeRingStroke = '#F2C94C'
      break
    case 'proceed':
      activeRingStroke = '#168342'
      break
    default:
      break
  }

  return (
    <div
      className={classnames('v3-rating-gauge', { [ratingType]: ratingType })}
    >
      <svg
        ref={svgRef}
        width={width}
        height="150"
        id="rating-gauge-svg"
        viewBox="0 0 42 42"
      >
        <g>
          <circle
            fill="transparent"
            stroke="rgba(181, 205, 227, 0.25)"
            strokeWidth="1.5"
            className="base-ring"
            cx="21"
            cy="21"
            r="19"
          />
          <circle
            strokeDasharray="120 120"
            className="active-ring"
            strokeDashoffset={gaugeValue}
            strokeWidth="1.5"
            cx="21"
            cy="21"
            r="19"
            fill="transparent"
            stroke={activeRingStroke}
          />
          <g
            stroke={bgColor}
            className="tick-gaps"
            transform="translate(21, 21)"
          >
            <line x1="0" y1="18" x2="0" y2="20" strokeWidth="1.5"></line>
            <line
              x1="0"
              y1="18"
              x2="0"
              y2="20"
              strokeWidth="1.5"
              transform="rotate(90)"
            ></line>
            <line
              x1="0"
              y1="18"
              x2="0"
              y2="20"
              strokeWidth="1.5"
              transform="rotate(180)"
            ></line>
            <line
              x1="0"
              y1="18"
              x2="0"
              y2="20"
              strokeWidth="1.5"
              transform="rotate(270)"
            ></line>
          </g>
          <g className="pointer" transform="translate(21, 21)">
            <circle
              ref={circleRef}
              transform={`rotate(${
                pointerRotation === 360
                  ? 354
                  : pointerRotation === 0
                  ? 6
                  : pointerRotation
              })`}
              stroke={bgColor}
              strokeWidth="1.5"
              cx="19"
              cy="0"
              r="1.65"
              fill="#0E2435"
            />
          </g>
          <svg>
            <text
              fontFamily="Open Sans"
              fontSize="13px"
              fontWeight="700"
              transform="rotate(-90)"
              fill="#0E2435"
              className="rating-value"
              dx="-20.5"
              dy="22"
              textAnchor="middle"
              tranform="rotate(90)"
            >
              <tspan fontWeight="700" dx="-20.5" dy="22">
                {rating === 10 ? 10 : rating.toFixed(1)}
              </tspan>
            </text>
            <text
              fontFamily="Open Sans"
              fontSize="4.3px"
              fontWeight="400"
              text-transform="capitalize"
              transform="rotate(-90)"
              fill="#0E2435"
              className="rating-type"
              dx="-20.5"
              dy="29"
              textAnchor="middle"
            >
              <tspan fontWeight="400" dx="-20.5" dy="29">
                {capitalize(ratingType)}
              </tspan>
            </text>
          </svg>
        </g>
      </svg>
    </div>
  )
}
RatingGauge.defaultProps = {
  bgColor: '#fff',
  svgRef: {},
  width: '150',
}
RatingGauge.propTypes = {
  bgColor: PropTypes.string.isRequired,
  rating: PropTypes.number.isRequired,
  svgRef: PropTypes.object.isRequired,
  width: PropTypes.string.isRequired,
}
export default RatingGauge
