import React from 'react'
import classnames from 'classnames'
import './Header.scss'

/**
  This is a single component to handle all header instances. The default is an H1, bold, $NileBlue. So you only need to specify `as` if the header IS NOT an H1 (font-size: 18px), `light` if the color IS $FiordBlue, `semibold` if the font-weight IS 600, and/or `regular` if the font-weight IS normal.
 */

interface HeaderProps {
  children: React.ReactNode
  className?: string
  light?: boolean
  semibold?: boolean
  regular?: boolean
  as?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
}

const Header = ({
  children,
  className,
  light = false,
  semibold = false,
  regular = false,
  as = 'h1',
}: HeaderProps) => {
  const TagName = as

  return (
    <TagName
      className={classnames('v3-header', className, {
        light,
        semibold,
        regular,
      })}
    >
      {children}
    </TagName>
  )
}

export default Header
