import React, { useEffect, useRef } from 'react'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import IconButton from '../buttons/IconButton'
import CloseSVG from '../../../assets/svg/CloseSVG'

import './Modal.scss'

/**
 * `Modal` is a basic modal. State is managed outside the component. Body scroll is managed by `body-scroll-lock` library. `Esc` key triggers close prop function. Close icon is absolutely positioned so content will need to be given appropriate margin/padding to give it space. `className` is placed on modal container so height, width, and other styles can be modified with a className.
 */

const ModalContainer = ({ isOpen, children, className = '', id, close }) => {
  const modalEl = useRef(null)

  useEffect(() => {
    if (modalEl.current) {
      modalEl.current.focus() // this makes sure that the tabbing starts within the modal element
      // there are many more focusable elements, add to this list as necessary
      const focusableEls = modalEl.current.querySelectorAll(
        'a[href]:not([disabled]), button:not([disabled]), textarea:not([disabled]), input[type="text"]:not([disabled]), input[type="radio"]:not([disabled]), input[type="checkbox"]:not([disabled]), select:not([disabled])',
      )
      const firstFocusEl = focusableEls[0]
      const lastFocusEl = focusableEls[focusableEls.length - 1]

      const handleTabDown = (e) => {
        if (e.key !== 'Tab') return
        if (e.shiftKey) {
          if (document.activeElement === firstFocusEl) {
            lastFocusEl.focus()
            e.preventDefault()
          }
        } else {
          if (document.activeElement === lastFocusEl) {
            firstFocusEl.focus()
            e.preventDefault()
          }
        }
      }

      window.addEventListener('keydown', handleTabDown)
      return () => window.removeEventListener('keydown', handleTabDown)
    }
  }, [])

  useEffect(() => {
    const handleEscapeKey = ({ key }) => {
      if (key === 'Escape') return close()
    }
    document.addEventListener('keydown', handleEscapeKey)
    return () => document.removeEventListener('keydown', handleEscapeKey)
  }, [close])

  useEffect(() => {
    const modalElement = modalEl.current
    if (isOpen) {
      disableBodyScroll(modalEl.current)
    }
    return () => {
      if (modalElement) {
        enableBodyScroll(modalElement)
      }
    }
  }, [isOpen])

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      close()
    }
  }

  if (!isOpen) return null
  return (
    /* eslint-disable */
    <div
      className="v3-modal-overlay"
      onClick={handleOverlayClick}
      role="dialog"
    >
      {/* eslint-enable */}
      <div
        tabIndex={-1} // this is to enable focus on modal
        ref={modalEl}
        className={classnames('v3-modal', className)}
        id={id}
        data-modal
      >
        <IconButton
          className="modal-close-button"
          icon={<CloseSVG />}
          onClick={close}
        />
        {children}
      </div>
    </div>
  )
}

ModalContainer.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  close: PropTypes.func.isRequired,
  id: PropTypes.string,
  isOpen: PropTypes.bool,
}

export default ModalContainer
